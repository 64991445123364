import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { useDispatch, useSelector } from "react-redux";
import { userDetails } from "../Redux/AuthReducer/actionType";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Formik } from "formik";
import * as yup from "yup";

let dummyResponse = {
  "id": "49df6921-b74d-4981-a821-21ec9c17f89a",
  "name": "Ankush",
  "jwt": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjQ5ZGY2OTIxLWI3NGQtNDk4MS1hODIxLTIxZWM5YzE3Zjg5YSIsIm9yZ19pZCI6IjdlMTA2MTg3LWMyYzEtNDlhMy04NzI4LTZkMzczNjY5NGJhZCIsImV4dGVybmFsX29yZ19pZCI6bnVsbCwiaWF0IjoxNzQyMzY3MjQ3LCJleHAiOjE3NDI0NTM2NDcsImlzcyI6IldhbGtpbiJ9.sO1BAwenMYQtUqc-Ca3BsAodPpXYxDNYOfnPwbGi1WhrRmDlm4xLEVG1ZcbF7CLRXekIS2IKsHZPqZoX96S50HC63QiZVdi2CKNzQAR9HxaJLWF-NzgQqTvvQsfyUAoCo7RJqeFLwz_CzxLHwlxwCngwsgVGRgGaqV63emN4a50-9h2gpw1jnPKNkp8xehbc7DlKXG84DXJOIbycKRPcDBBaANS0nBkqHAInhFNcAy88kc-0KlC7BZaz1eegcnAUX4h2JMGtgEI1sh_U4DT8RQeEIcmZ4zryvitmla30rm9cUdWg3-w8JTtniI9pAMmNNlV6IiHbXhJjj92YjTFzZQ",
  "permissions": {
      "maker": true,
      "checker": true
  },
  "user_role_permission": {
      "user_role_name": "ADMIN",
      "user_role_description": null,
      "dashboard_data": {
          "route": "/view-offers",
          "allowed_permissions": {
              "/limits": {
                  "CREATE": true,
                  "DELETE": true,
                  "UPDATE": true,
                  "DOWNLOAD": true,
                  "SUB_SECTIONS": null
              },
              "/analytics": {
                  "CREATE": true,
                  "DELETE": true,
                  "UPDATE": true,
                  "DOWNLOAD": true,
                  "SUB_SECTIONS": [
                      "Overview",
                      "Product Level",
                      "Offer Level",
                      "User Level"
                  ]
              },
              "/view-rules": {
                  "CREATE": true,
                  "DELETE": true,
                  "UPDATE": true,
                  "DOWNLOAD": true,
                  "SUB_SECTIONS": null
              },
              "/view-offers": {
                  "CREATE": true,
                  "DELETE": true,
                  "UPDATE": true,
                  "DOWNLOAD": true,
                  "SUB_SECTIONS": null
              },
              "/create-rules": {
                  "CREATE": true,
                  "DELETE": true,
                  "UPDATE": true,
                  "DOWNLOAD": true,
                  "SUB_SECTIONS": null
              },
              "/transactions": {
                  "CREATE": true,
                  "DELETE": true,
                  "UPDATE": true,
                  "DOWNLOAD": true,
                  "SUB_SECTIONS": null
              },
              "/view-cohorts": {
                  "CREATE": true,
                  "DELETE": true,
                  "UPDATE": true,
                  "DOWNLOAD": true,
                  "SUB_SECTIONS": null
              },
              "/create-offers": {
                  "CREATE": true,
                  "DELETE": true,
                  "UPDATE": true,
                  "DOWNLOAD": true,
                  "SUB_SECTIONS": null
              },
              "/create-cohorts": {
                  "CREATE": true,
                  "DELETE": true,
                  "UPDATE": true,
                  "DOWNLOAD": true,
                  "SUB_SECTIONS": null
              }
          },
          "allowed_routes_and_sections": [
              {
                  "icon": "analytics",
                  "path": "/analytics",
                  "title": "Analytics",
                  "subMenu": null,
                  "disabled": "false"
              },
              {
                  "icon": "offers",
                  "path": "/view-offers",
                  "title": "Offers/Campaigns",
                  "subMenu": [
                      {
                          "path": "/offers/create-offers/offer-details",
                          "title": "Create Offers",
                          "subMenu": [
                              {
                                  "path": "/offers/create-offers/offer-details",
                                  "title": "Offer Details",
                                  "disabled": "false"
                              },
                              {
                                  "path": "/offers/create-offers/set-qualifying-criteria",
                                  "title": "Set Qualifying Criteria",
                                  "disabled": "false"
                              },
                              {
                                  "path": "/offers/create-offers/create-game",
                                  "title": "Create Game",
                                  "disabled": "false"
                              }
                          ],
                          "disabled": "false"
                      },
                      {
                          "path": "/view-offers",
                          "title": "View Offers",
                          "disabled": "false"
                      }
                  ],
                  "disabled": "false"
              },
              {
                  "icon": "rules",
                  "path": "/view-rules",
                  "title": "Rules",
                  "subMenu": [
                      {
                          "path": "/create-rules",
                          "title": "Create Rules",
                          "disabled": "false"
                      },
                      {
                          "path": "/view-rules",
                          "title": "View Rules",
                          "disabled": "false"
                      }
                  ],
                  "disabled": "false"
              },
              {
                  "icon": "limits",
                  "path": "/limits",
                  "title": "Limits",
                  "subMenu": null,
                  "disabled": "false"
              },
              {
                  "icon": "cohorts",
                  "path": "/view-cohorts",
                  "title": "User Cohorts",
                  "subMenu": [
                      {
                          "path": "/create-cohorts",
                          "title": "Create",
                          "disabled": "false"
                      },
                      {
                          "path": "/view-cohorts",
                          "title": "View",
                          "disabled": "false"
                      }
                  ],
                  "disabled": "false"
              },
              {
                  "icon": "transactions",
                  "path": "/transactions",
                  "title": "Transactions",
                  "subMenu": null,
                  "disabled": "false"
              }
          ]
      }
  }
}
const Login = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const isAuth = useSelector((store) => store.AuthReducer.token);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [loginMethod, setLoginMethod] = useState("traditional"); // "microsoft" or "traditional"

  // Password visibility toggle functions
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();

  // Function to toggle between login methods
  const toggleLoginMethod = () => {
    setLoginMethod(loginMethod === "microsoft" ? "traditional" : "microsoft");
  };

  // Function to handle Microsoft login
  const handleMsLogin = async () => {
    try {
      const res = await instance.loginPopup(loginRequest);
      console.log("Login successful", res);
      console.log(res.idTokenClaims?.preferred_username , "preferred_username");

      if(res.idToken){
          // Make API call to validate Azure login
          const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/validate-azure-login`, res, {
            headers: { Authorization: `Bearer ${res.idToken}` }
          });

          // const response = {data:dummyResponse};
          console.log("Validation response:", response.data);
          
          // Proceed with your existing logic
          let allowedRouters = response.data?.user_role_permission?.dashboard_data?.allowed_routes_and_sections;
          let allowedPermissions = response.data?.user_role_permission?.dashboard_data?.allowed_permissions;
          const initialRoute = response.data?.user_role_permission?.dashboard_data?.route;
          const accessToken = response?.data?.jwt ?? "";
          const userName = response?.data?.name ?? "";
          
          localStorage.setItem("Auth", JSON.stringify({accessToken, userName}));
          localStorage.setItem("allowedRouters", JSON.stringify({allowedRouters}));
          localStorage.setItem("allowedPermissions", JSON.stringify({allowedPermissions}));
          localStorage.setItem("userId", JSON.stringify({userId: response?.data?.id}));
          localStorage.setItem("makerCheckerPermission", JSON.stringify({permission: response?.data?.permissions}));
          localStorage.setItem("isMicrosoftLogin", true);
          let AuthDetails = {
            token: response?.data?.jwt ?? "",
            userName: response?.data?.name ?? "",
            allowedRoutes: allowedRouters,
            allowedPermissions: allowedPermissions,
            initialRoute: initialRoute,
          };
          
          dispatch({type: userDetails, payload: AuthDetails});
          navigate("/", { replace: true });
      }
      // You can handle the successful login response here if needed
    } catch (error) {
      console.error("Login failed:", error);
      const errorMessage = error.response?.data?.message || "Login failed. Please try again.";
      toast.error(errorMessage);
  }
  };
  
  // Validation schema for traditional login
  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string()
      .min(6, "Password should be of minimum 6 characters length")
      .required("Password is required"),
  });

  // Function to handle traditional login (for backward compatibility)
  const handleTraditionalLogin = async (email, password) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/login`, {
        email,
        password
      });
      
      let allowedRouters = response.data?.user_role_permission?.dashboard_data?.allowed_routes_and_sections;
      let allowedPermissions = response.data?.user_role_permission?.dashboard_data?.allowed_permissions;
      const initialRoute = response.data?.user_role_permission?.dashboard_data?.route;
      const accessToken = response?.data?.jwt ?? "";
      const userName = response?.data?.name ?? "";
      
      localStorage.setItem("Auth", JSON.stringify({accessToken, userName}));
      localStorage.setItem("allowedRouters", JSON.stringify({allowedRouters}));
      localStorage.setItem("allowedPermissions", JSON.stringify({allowedPermissions}));
      localStorage.setItem("userId", JSON.stringify({userId: response?.data?.id}));
      localStorage.setItem("makerCheckerPermission", JSON.stringify({permission: response?.data?.permissions}));
      
      let AuthDetails = {
        token: response?.data?.jwt ?? "",
        userName: response?.data?.name ?? "",
        allowedRoutes: allowedRouters,
        allowedPermissions: allowedPermissions,
        initialRoute: initialRoute,
      };
      
      dispatch({type: userDetails, payload: AuthDetails});
      navigate("/", { replace: true });
    } catch (error) {
      console.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.errorMessage || error?.response?.data?.message || "Something went wrong");
    }
  };

  // Redirect if already authenticated
  useEffect(() => {
    if (isAuth) {
      navigate("/");
    }
  }, [isAuth, navigate]);


  //css
  const titleStyle = {
    fontFamily: "ABeeZee",
    fontSize: "42px",
    fontStyle: "italic",
    fontWeight: 400,
    lineHeight: "50px",
    letterSpacing: "0em",
    textAlign: "left",
  };

  const subtitle = {
    fontSize: "20px",
    fontStyle: "italic",
    fontWeight: 500,
    lineHeight: "25px",
    letterSpacing: "0em",
    color: "#000000B2",
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#F7F9FC",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ToastContainer/>
      <Box
        sx={{
          width: "570px",
          backgroundColor: "#fff",
          boxShadow: "0px 0px 16px 0px #0000000A",
          boxSizing: "border-box",
          padding: "30px 40px",
          borderRadius: "16px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundColor="white"
          gap="5px"
          mt="1.5%"
        >
          <Typography variant="h3" sx={titleStyle}>
            Gratify
          </Typography>
        </Box>
        <Typography
          variant="h4"
          sx={subtitle}
          textAlign="center"
          m="15px"
        >
          Welcome to Gratify Admin Panel
        </Typography>
        
        <Box sx={{ mt: 2, width: "100%" }}>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleTraditionalLogin(values.email, values.password);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                    {touched.password && errors.password && (
                      <FormHelperText error>{errors.password}</FormHelperText>
                    )}
                  </FormControl>
                  
                  {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Typography 
                      variant="body2" 
                      color="primary" 
                      sx={{ cursor: "pointer" }}
                      onClick={() => navigate("/forgot-password")}
                    >
                      Forgot Password?
                    </Typography>
                  </Box> */}
                  
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isSubmitting}
                    sx={{
                      py: 1.5,
                      backgroundColor: "black",
                      '&:hover': {
                        backgroundColor: "#191919"
                      }
                    }}
                  >
                    Login
                  </Button>
                  
                  <Box sx={{ width: "100%", display: "flex", alignItems: "center", my: 1 }}>
                    <Divider sx={{ flexGrow: 1 }} />
                    <Typography variant="body2" sx={{ mx: 2, color: "#666" }}>OR</Typography>
                    <Divider sx={{ flexGrow: 1 }} />
                  </Box>
                  
                  <Button 
                    variant="contained" 
                    color="primary"
                    fullWidth
                    onClick={handleMsLogin}
                    sx={{ 
                      py: 1.5, 
                      backgroundColor: "#0078d4",
                      '&:hover': {
                        backgroundColor: "#106ebe"
                      }
                    }}
                  >
                    Sign in with Microsoft
                  </Button>
                </Stack>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
