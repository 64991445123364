import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AddColumnIcon from "../assets/AddColumnIcon.svg";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MuiButton from "./MuiButton";
import { Box, Typography, Button } from "@mui/material";

const ToggleColumn = ({
  tableFields = [],
  handleToggleColumn,
  disabledIndex = 0,
  onReset, // New prop for reset functionality
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    // Call the onReset prop if provided
    if (onReset) {
      onReset();
    }
    handleClose(); // Close the menu after reset
  };

  return (
    <div>
      <MuiButton
        text=" Add Column"
        variant="outlined"
        style={{
          padding: "0px",
          border: "1px solid rgba(0, 0, 0, 0.30)",
          marginBottom: "10px",
          height: "30px",
          width: "85px",
          fontFamily: "Lato",
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "18px",
          color: "#667085",
        }}
        onClick={handleClick}
        icon={AddColumnIcon}
        bg="white"
        color="black"
      >
        Column
      </MuiButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <FormGroup>
          {tableFields?.map((options, index) => {
            return (
              <Box
                key={options.name} // Added key prop for list rendering
                sx={{
                  borderBottom: "1px solid #020d20",
                  margin: "0",
                  padding: "10px 10px 10px 0px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if(index < disabledIndex) return;
                  handleToggleColumn(index);
                }}
              >
                <Checkbox
                  name={options.name}
                  sx={{
                    color: "#003D86",
                    "&.Mui-checked": {
                      color: "#003D86",
                    },
                    "&.Mui-disabled": {
                      color: "gray",
                    },
                    height: "15px",
                    width: "15px",
                    margin: "0px 10px",
                  }}
                  checked={options?.flag}
                  disabled={index < disabledIndex}
                />
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "18px",
                  }}
                >
                  {options?.displayName}
                </Typography>
              </Box>
            );
          })}
          
          {/* New Reset Button */}
          {/* <Box
            sx={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              borderTop: "1px solid #020d20",
            }}
          >
            <Button
              variant="contained"
              onClick={handleReset}
              sx={{
                backgroundColor: "#003D86",
                color: "white",
                "&:hover": {
                  backgroundColor: "#002D66",
                },
                fontFamily: "Lato",
                fontSize: "12px",
                textTransform: "none",
              }}
            >
              Reset Columns
            </Button>
          </Box> */}
        </FormGroup>
      </Menu>
    </div>
  );
};

export default ToggleColumn;