import { Box, Button, Drawer, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { DeviceFrameset } from "react-device-frameset";
import NewImageUpload from "../../../../UIComponents/NewImageUpload";
import { useDispatch, useSelector } from "react-redux";
import cross from "../../../../assets/close.svg";
import clicked from "../../../../assets/clicked.svg";
import { setAfterPlayChange } from "../../../../Redux/OfferReducer/actionType";
import { AndroidMockup } from "react-device-mockup";
import transparent_background from "../../../../assets/transparent_background.svg";
import { useLocation } from "react-router-dom";
import BoldLabel from "../../../../UIComponents/BoldLevel";
import UrlUpload from "../../../../UIComponents/UrlUpload";
import { toast } from "react-toastify";

export default function AfterPlayDrawer({
  drawerOpen,
  setDrawerOpen,
  handleDrawerOpen,
  handleDrawerClose,
  handleSelectImage,
  value,
  handleChange,
  handleDeleteImage,
  loading,
  openRewardIndex,
}) {
  const [url, setUrl] = React.useState("");
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const data = useSelector(
    (store) => store?.OfferReducer?.offerData?.createGame
  );
  const image_option = useSelector(
    (store) =>
      store?.OfferReducer?.offerData?.createGame?.drawerData?.image_option
  );

  const backend_label = useSelector(
    (store) =>
      store?.OfferReducer?.offerData?.createGame?.drawerData?.backend_label
  );

  const gameDataName = useSelector(
    (store) =>
      store?.OfferReducer?.offerData?.createGame?.drawerData?.gameDataName
  );

  const game_type = useSelector(
    (store) => store?.OfferReducer?.offerData?.createGame?.drawerData?.game_type
  );

  const action_type = useSelector(
    (store) =>
      store?.OfferReducer?.offerData?.createGame?.drawerData?.action_type
  );

  const rewardIndex = useSelector(
    (store) =>
      store?.OfferReducer?.offerData?.createGame?.drawerData?.rewardIndex
  );

  // if we need need to remove image uploader in that case make isMultipleImage true
  const isMultipleImage =false;
    // data?.[game_type]?.[gameDataName]?.[rewardIndex]?.["reward_type"]?.id ===
    // "promo_neu_coin";

  return (
    <>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        sx={{
          width: "28%",
          maxWidth: "none",
          // overflow: "visible",
          overflowX: "hidden",
          "& .MuiDrawer-paper": {
            width: "28%",
            // overflow: "visible",
          },
        }}
      >
        <Box sx={{ position: "relative", padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",

              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "16px",
                fontWeight: "600",
                color: "#1D1F2C",
                lineHeight: "19.2px",
              }}
            >
              Select Image
            </Typography>
            <Button onClick={handleDrawerClose} sx={{ zIndex: "1000" }}>
              <img
                style={{ height: "24px", width: "24px" }}
                src={cross}
                alt=""
              />
            </Button>
          </Box>
          {/* <Box>
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "14px",
                fontWeight: "600",
                color: "#222222",
                lineHeight: "16.8px",
                marginTop: "20px",
              }}
            >
              Preview
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              // top: "-200px",
              left: "50%",
              transform: "translateX(-50%)",
              //   backgroundColor:"#F9F9FC",
              zIndex: "-1000",
            }}
          >
            <AndroidMockup
              screenWidth={110}
              frameColor="#0D0D0F"
              statusbarColor="#0D0D0F"
            >
              <Box
                sx={{
                  height: "100%",
                  backgroundImage: `url(${transparent_background})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "100%",
                }}
              >
                <Box mt="60px">
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{ width: "80px", height: "80px" }}
                      src={
                        data?.afterPlay?.[gameDataName]?.[
                          openRewardIndex ? openRewardIndex : 0
                        ]?.scratch_card_image
                      }
                      alt=""
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: "60%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <img
                        style={{
                          width: "25px",
                          height: "25px",
                          marginLeft: "12px",
                        }}
                        src={
                          data?.afterPlay?.[gameDataName]?.[
                            openRewardIndex ? openRewardIndex : 0
                          ]?.logo_image
                        }
                        alt=""
                      />
                      <Typography
                        sx={{
                          color:
                            data?.afterPlay?.[gameDataName]?.[
                              openRewardIndex ? openRewardIndex : 0
                            ]?.title_colour,
                          fontFamily: "Proxima Nova A",
                          fontSize: "3.5px",
                          fontWeight: "600",
                          lineHeight: "4.5px",
                          letterSpacing: "0.5px",
                          height: "4.5px",
                        }}
                      >
                        {
                          data?.afterPlay?.[gameDataName]?.[
                            openRewardIndex ? openRewardIndex : 0
                          ]?.title
                        }
                      </Typography>
                      <Typography
                        sx={{
                          color:
                            data?.afterPlay?.[gameDataName]?.[
                              openRewardIndex ? openRewardIndex : 0
                            ]?.reward_value_color,
                          fontFamily: "Proxima Nova A",
                          fontSize: "7.8px",
                          fontWeight: "700",
                          lineHeight: "9.5px",
                          mt: "3.5px",
                        }}
                      >
                        100 Neucoins
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      height: "13.5px",
                      marginTop: "7.5px",
                      paddingX: "22.5px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Proxima Nova A",
                        fontSize: "4.5px",
                        fontWeight: "400",
                        lineHeight: "6.7px",
                        color: "#FFFFFF",
                        textAlign: "center",
                      }}
                    >
                      Scratch the card to win an assured reward
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    margin: "4.5px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "2.5px",
                    mt: "30px",
                    marginLeft: "6px",
                  }}
                >
                  <Box
                    sx={{
                      border: "0.25px solid #B2B2B2",
                      width: "37.5px",
                    }}
                  ></Box>
                  <Typography
                    sx={{
                      fontFamily: "Proxima Nova A",
                      fontSize: "3.4px",
                      lineHeight: "4.5px",
                      color: "#E5E5E5",
                    }}
                  >
                    Earned via
                  </Typography>
                  <Box
                    sx={{
                      border: "0.25px solid #B2B2B2",
                      width: "37.5px",
                    }}
                  ></Box>
                </Box>

                <Box
                  sx={{
                    margin: "2.5px 4.5px",
                    height: "17.5px",
                    backgroundColor: "#FFFFFF1F",
                    borderRadius: "3.5px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Proxima Nova A",
                      fontSize: "4.5px",
                      fontWeight: "600",
                      lineHeight: "6.7px",
                      color: "#FFFFFF",
                      textAlign: "center",
                    }}
                  >
                    Air India Express Flight Booking
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Proxima Nova A",
                      fontSize: "3.4px",
                      fontWeight: "400",
                      lineHeight: "4.5px",
                      textAlign: "center",
                      color: "#E5E5E5",
                    }}
                  >
                    11 Oct 2023 · Tata Pay Later
                  </Typography>
                </Box>
              </Box>
            </AndroidMockup>
          </Box> */}
          <Box
            sx={{
              height: "250px",

              // marginTop: "270px",
            }}
          >
            <Box>
              {isMultipleImage ? (
                <> </>
              ) : (
                <NewImageUpload
                  hideChangeText={pathname.includes("preview")}
                  loader={loading}
                  index={rewardIndex}
                  compareLink={
                    data?.[game_type]?.[gameDataName]?.[rewardIndex]?.[
                      backend_label
                    ]
                  }
                  backend_label={backend_label}
                  handleSelectImage={handleSelectImage}
                  value={
                    data?.[game_type]?.[gameDataName]?.[rewardIndex]?.[
                      `${backend_label}_upload_url`
                    ]
                  }
                  onChange={(value) =>
                    handleSelectImage(
                      `${backend_label}_upload`,
                      value,
                      rewardIndex,
                      {}
                    )
                  }
                  maxSize="2MB"
                  ratio="1:1"
                  //   errorMessage={errorMessage?.coverPhoto}
                  //   image={coverPhoto}
                  handleImage={(value) => {
                    // dispatch({
                    //   type: action_type,
                    //   payload: {
                    //     additionalFields: {
                    //       // [`${backend_label}`]: value,
                    //       [`${backend_label}_upload`]: value,
                    //       [`${backend_label}_upload_id`]: value,
                    //       [`${backend_label}_upload_url`]: value,
                    //     },
                    //     gameDataName: gameDataName,
                    //   },
                    // });
                    const payload = {
                      gameDataName,
                      backend_label,
                      value,
                      additionalFields: {
                        [`${backend_label}_upload`]: value,
                        [`${backend_label}_upload_id`]: value,
                        [`${backend_label}_upload_url`]: value,
                      },
                      rewardIndex,
                    };
                    dispatch({
                      type: action_type,
                      payload,
                    });
                  }}
                />
              )}

              <BoldLabel label="Or Upload from URL" mb="8px" />
              <UrlUpload
                placeholder="Add file URL"
                handleUpload={() => {
                  const payload = {
                    gameDataName,
                    backend_label,
                    value,
                    additionalFields: {
                      [`${backend_label}_upload`]: url,
                      [`${backend_label}_upload_id`]: url,
                      [`${backend_label}_upload_url`]: url,
                    },
                    rewardIndex,
                  };
                  dispatch({
                    type: action_type,
                    payload,
                  });
                  setUrl("");
                  // toast.success("Url uploaded successfully");
                }}
                value={url}
                onChange={(value) => setUrl(value)}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  fontFamily: "Lato",
                  fontSize: "12px",
                  fontWeight: "700",
                  color: "#1D1F2C",
                  lineHeight: "16px",
                  marginTop: "20px",
                  marginBottom: "8px",
                }}
              >
                Colour presets
              </Typography>
              <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                {image_option?.length > 0 &&
                  image_option?.map((item, index) => {
                    const selectedValue =
                      data?.[game_type]?.[gameDataName]?.[rewardIndex]?.[
                        backend_label
                      ];

                    // const isSelected = isMultipleImage
                    //   ? selectedValue.includes(item?.url)
                    //   : selectedValue === item?.url;
                    const isSelected =  selectedValue === item?.url;

                    return (
                      <Box
                        key={index}
                        onClick={() =>
                          handleSelectImage(
                            backend_label,
                            item?.url,
                            rewardIndex,
                            {
                              // [`${backend_label}_upload_id`]: item?.id,
                            },
                            // isMultipleImage
                          )
                        }
                        sx={{
                          border: isSelected
                            ? "1px solid #003D86"
                            : "1px solid transparent",
                          height: "65px",
                          width: "65px",
                          borderRadius: "6px",
                          position: "relative",
                        }}
                      >
                        <Box>
                          <img
                            src={item?.url}
                            alt=""
                            style={{
                              height: "65px",
                              width: "65px",
                              borderRadius: "6px",
                            }}
                          />
                        </Box>
                        {isSelected && (
                          <Box
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              backgroundColor: "rgba(0, 61, 134, 0.5)",
                            }}
                          >
                            <img
                              style={{ height: "22px", width: "22px" }}
                              src={clicked}
                              alt=""
                            />
                          </Box>
                        )}
                      </Box>
                    );
                  })}
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
