import React, { useEffect, useRef, useState } from "react";
import { getToken } from "../../../utils/token";
import axios from "axios";
import { Box, Button, Typography } from "@mui/material";
import { BootstrapTooltip } from "../../../utils/tooltip";
import usePermission from "../../../utils/permissions";
import copyIcon from "../../../assets/copy.svg";
import UiTable from "../../UiTable";
import { toast, ToastContainer } from "react-toastify";
import exportImg from "../../../assets/export.svg";
import plus from "../../../assets/Plus.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import deleteIcon from "../../../assets/delete.png";
import { formatDateNew } from "../../../utils/formateDate";
import FilterAndSearch from "../../Filter/FilterAndSearch";
import dayjs from "dayjs";

const TData = () => {
  return [
    { key: "name", displayName: "Cohorts Name", flag: true },
    { key: "description", displayName: "Cohorts Description", flag: true },
    { key: "created_date", displayName: "Created Date", flag: true },
    { key: "type", displayName: "Type", flag: true },
    { key: "status", displayName: "Status", flag: true },
    { key: "copyBtn", displayName: "Action", flag: true },
  ];
};

const dateFilterLayout = {
  select: "ALL_TIME",
  from_time: "",
  to_time: "",
};

const filterSelectOptions = [
  { label: "Custom", value: "CUSTOM" },
  { label: "Today", value: "TODAY" },
  { label: "Yesterday", value: "YESTERDAY" },
  { label: "This Week", value: "THIS_WEEK" },
  { label: "This Month", value: "THIS_MONTH" },
  { label: "All Time", value: "ALL_TIME" },
];

export default function ViewCohortPage() {
  const [fieldsName, setFieldName] = useState(TData);
  const [columnFields, setColumnFields] = useState([]);

  const [dateFilter, setDateFilter] = useState(dateFilterLayout);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterDone, setFilterDone] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState([]);
  const [filterRemoveFlag, setFilterRemoveFlag] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(() => {
    const initialFilters = {};
    filters.forEach((filter) => {
      const paramValue = searchParams.get(filter.name);
      if (paramValue) {
        initialFilters[filter.name] = paramValue.split(",");
      } else {
        initialFilters[filter.name] = [];
      }
    });

    // Remove keys with empty arrays
    return Object.fromEntries(
      Object.entries(initialFilters).filter(([_, value]) => value.length > 0)
    );
  });
  const filterCount = Array.from(searchParams.keys()).length;

  const [cohorts, setCohorts] = useState({
    data: [],
    paginationModel: {
      pageSize: 15,
      page: 0,
    },
    totalCount: 0,
    allOfferCount: 0,
    pendingOfferCount: 0,
    denyOfferCount: 0,
  });
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(true);
  const [offerStatus, setOfferStatus] = useState(false);
  const [editData, setEditData] = useState(false);
  const [message, setMessage] = useState("");
  const [search, setSearch] = useState("");
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const token = getToken();
  const permission = usePermission("/view-cohorts");
  const IdRef = useRef("");
  const navigate = useNavigate();

  console.log(permission);

  const getAllCohorts = async () => {
    setLoading(true);
    const body = {
      page: cohorts?.paginationModel.page + 1,
      pageSize: cohorts?.paginationModel.pageSize,
      ...(search && { search }),
      ...selectedFilters,
    };

    if (dateFilter?.select) {
      body["day"] = dateFilter?.select;
    }

    if (dateFilter?.select === "CUSTOM") {
      let startDate = formatDateNew(dateFilter?.from_time ?? "");
      let endDate = formatDateNew(dateFilter?.to_time ?? "");
      body["dateStart"] = startDate;
      body["dateEnd"] = endDate;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(`${baseUrl}/api/collections`, body, {
        headers,
      });
      console.log(response.data);
      let formateData = response?.data?.data?.map((e) => {
        return {
          id: e.id,
          name: e.name,
          description: e.description,
          created_date: e.created_date,
          type: e.type,
          status: e.status,
          copyBtn: copyIcon,
          deleteBtn: deleteIcon,
        };
      });
      setCohorts((prev) => ({
        ...prev,
        data: formateData ?? [],
        totalCount: response?.data?.paginationInfo.totalItems ?? 0,
      }));
      setStartDate(response.data.day_start_date ?? "");
      setEndDate(response.data.day_end_date ?? "");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const exportToCSV = () => {
    if (cohorts.data.length === 0) {
      toast.error("No data to export");
      return;
    }

    // Generate headers dynamically, excluding action column and button columns
    const headers = Object.keys(cohorts.data[0]).filter(
      (key) =>
        key !== "action" &&
        key !== "copyBtn" &&
        key !== "deleteBtn" &&
        !key.includes("Btn") &&
        !key.includes("button")
    );

    // Create a mapping of field names to header names from columns definition
    const headerMapping = {};
    columns.forEach((column) => {
      headerMapping[column.field] = column.headerName;
    });

    // Use the exact header names from the table
    const displayHeaders = headers.map(
      (header) =>
        headerMapping[header] ||
        header
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
    );

    // Process data for CSV, properly handling commas and quotes
    const processValue = (value) => {
      if (value === null || value === undefined) return "NA";
      const stringValue = String(value);
      // If value contains comma, quote, or newline, wrap in quotes and escape existing quotes
      if (
        stringValue.includes(",") ||
        stringValue.includes('"') ||
        stringValue.includes("\n")
      ) {
        return `"${stringValue.replace(/"/g, '""')}"`;
      }
      return stringValue;
    };

    const csvData = cohorts.data.map((row) =>
      headers.map((header) => {
        if (header === "visibility") {
          return row[header] ? "Visible" : "Hidden";
        }
        return processValue(row[header]);
      })
    );

    const csvContent = [
      displayHeaders.map(processValue).join(","),
      ...csvData.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `cohort_data_${new Date().toISOString().split("T")[0]}.csv`
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url); // Clean up to avoid memory leaks
    }
  };

  const handlePaginationModel = (value) => {
    if (!value) return;
    setCohorts((prev) => ({ ...prev, paginationModel: value }));
  };
  function handleCopy(e, params) {
    e.stopPropagation();
    const data = cohorts.data.find((e) => e.id === params.id);
    console.log(params?.row?.name, "data");
    if (params?.row?.name) {
      const name = params?.row?.name;
      navigator.clipboard
        .writeText(name)
        .then(() => {
          console.log(`Copied: ${name}`);
          toast.success("Cohorts name copied successfully");
        })
        .catch((error) => {
          console.error("Copy failed: ", error);
          toast.error("Failed to copy offer name");
        });
    }
  }

  const fetchFilters = async () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    // const token = getToken();

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Adjust content type as needed
    };
    try {
      const response = await axios.get(
        `${baseUrl}/api/filters?page=viewCohorts`,
        {
          headers,
        }
      );
      if (response) {
        const config = response.data;
        let obj = {};
        const data = config.map((res) => {
          if (!obj.hasOwnProperty(res?.name)) {
            obj[res?.name] = [];
          }
          return { ...res, state: [] };
        });
        setFilters(response?.data);
        console.log(config, data, "filter");
        // setFilterOptions(obj);
        // setFilterConfig(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateFilter = (name, value) => {
    setDateFilter((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (value === "ALL_TIME") {
      setDateFilter((prev) => ({
        ...prev,
        from_time: { unset: true },
        to_time: { unset: true },
      }));
    }

    if (value === "CUSTOM") {
      setDateFilter((prev) => ({
        ...prev,
        from_time: { unset: true },
        to_time: { unset: true },
      }));
    }
  };

  const handleToggleColumn = (i) => {
    console.log(i, "index");
    const data = fieldsName.map((e, index) => {
      if (index === i) {
        e.flag = !e.flag;
      }
      return e;
    });
    console.log(data, "inside handleToggleColumn");
    setFieldName(data);
  };

  const columns = [
    //  {
    //   field: "id",
    //   headerName: "Cohorts ID",
    //   // flex:1.5,
    //   minWidth: 350,
    //   renderCell: (params) => {
    //     if (!params?.value) {
    //       return "NA";
    //     }
    //     return (
    //       <BootstrapTooltip title={params.value}>
    //         <Box
    //           paddingRight="auto"
    //           fontFamily="ABeeZee"
    //           fontWeight={400}
    //           fontSize="13px"
    //           lineHeight="15.37px"

    //           letterSpacing="2%"
    //                 overflow="hidden"
    //           textOverflow="ellipsis"
    //           whiteSpace={"nowrap"}
    //         >
    //           {params?.value}
    //         </Box>
    //       </BootstrapTooltip>
    //     );
    //   },
    // },
    {
      field: "name",
      headerName: "Cohorts Name",
      // flex:1.5,
      minWidth: 250,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Typography
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
            >
              NA
            </Typography>
          );
        }

        return (
          <BootstrapTooltip title={params.value}>
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },

    {
      field: "description",
      headerName: "Cohorts Description",
      minWidth: 350,
      // flex:2.5,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Typography
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
            >
              NA
            </Typography>
          );
        }

        return (
          <BootstrapTooltip title={params.value}>
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },

    {
      field: "created_date",
      headerName: "Created Date",
      minWidth: 150,
      // flex:2.5,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Typography
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
            >
              NA
            </Typography>
          );
        }

        return (
          <BootstrapTooltip title={params.value}>
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 100,
      // flex:2.5,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Typography
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
            >
              NA
            </Typography>
          );
        }

        return (
          <BootstrapTooltip title={params.value}>
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      // flex:2.5,
      renderCell: (params) => {
        if (!params?.value) {
          return (
            <Typography
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
            >
              NA
            </Typography>
          );
        }

        return (
          <BootstrapTooltip title={params.value}>
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    // {
    //   field: "csv_file",
    //   headerName: "CSV File",
    //   minWidth: 250,
    //   renderCell: (params) => {
    //     if (!params?.value) {
    //       return "NA";
    //     }

    //     return <Box>{params?.value}</Box>;
    //   },
    // },

    {
      field: "copyBtn",
      headerName: "Action",
      width: 150,
      // flex: 1,
      renderCell: (params) => {
        //   if (!params?.value) {
        //     return "NA";
        //   }

        return (
          <Box
            // sx={{
            //   width: "100%",
            //   height: "100%",
            //   display: "grid",
            //   placeItems: "center",
            // }}
            marginLeft="5px"
            onClick={(e) => handleCopy(e, params)}
          >
            <BootstrapTooltip title="Copy Name">
              <img
                style={{ height: "18px", width: "18px" }}
                src={params?.value}
                alt="copy"
              />
            </BootstrapTooltip>
          </Box>
        );
      },
    },
    // {
    //   field: "deleteBtn",
    //   headerName: "",
    //   width: 150,
    //   flex: 1,
    //   renderCell: (params) => {
    //     //   if (!params?.value) {
    //     //     return "NA";
    //     //   }

    //     return (
    //       <Box
    //         sx={{
    //           width: "100%",
    //           height: "100%",
    //           display: "grid",
    //           placeItems: "center",
    //         }}
    //         onClick={(e) => handleDelete(e, params)}
    //       >
    //         {/* {params?.value} */}
    //         <img
    //           style={{ height: "24px", width: "24px" }}
    //           src={params?.value}
    //           alt="delete"
    //         />
    //       </Box>
    //     );
    //   },
    // },
  ];

  console.log(cohorts, "cohorts");

  useEffect(() => {
    let debounceTimer;
    // if (filters?.length > 0) {
    if (flag) {
      setFlag(false);
    } else {
      debounceTimer = setTimeout(() => {
        getAllCohorts();
      }, 800);
    }
    // }

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [search]);

  useEffect(() => {
    // let debounceTimer;
    if (dateFilter.select !== "CUSTOM") {
      // if (flag) {
      //   setFlag(false);
      // } else {
      // debounceTimer = setTimeout(() => {
      getAllCohorts();
      // }, 0);
      // }
    }

    // return () => {
    //   clearTimeout(debounceTimer);
    // };
  }, [
    offerStatus,
    cohorts?.paginationModel?.page,
    cohorts?.paginationModel?.pageSize,
    dateFilter?.select,
    // activeTab,
    filterDone,
    filterRemoveFlag,
    // filters,
    // search
  ]);

  useEffect(() => {
    if (dateFilter.select === "CUSTOM") {
      if (
        typeof dateFilter?.from_time === "string" &&
        typeof dateFilter?.to_time === "string" &&
        dateFilter.from_time &&
        dateFilter.to_time
      ) {
        console.log(
          "inside custom date filter",
          dateFilter?.to_time,
          dateFilter?.from_time
        );
        getAllCohorts();
      }
    }
  }, [
    dateFilter,
    filterDone,
    filterRemoveFlag,
    cohorts?.paginationModel?.page,
    cohorts?.paginationModel?.pageSize,
  ]);

  useEffect(() => {
    if (startDate && endDate) {
      setDateFilter((prev) => ({
        ...prev,
        from_time: dayjs(new Date(startDate)),
        to_time: dayjs(new Date(endDate)),
      }));
    }
  }, [startDate, endDate]);

  useEffect(() => {
    // Step 1: Filter TData columns where flag is true
    let filteredTData = fieldsName?.filter((column) => column.flag === true);

    // Step 2: Filter columns where field matches key from filteredTData
    const filteredColumns = columns.filter((column) =>
      filteredTData.some(
        (filteredColumn) => filteredColumn.key === column.field
      )
    );

    // Step 3: Add the action object at the end
    // const actionColumn = columns.find((column) => column.field === "action");
    // if (actionColumn) {
    //   filteredColumns.push(actionColumn);
    // } else {
    //   filteredColumns.push({ field: "action", headerName: "Action" });
    // }

    setColumnFields(filteredColumns);
  }, [fieldsName]);

  useEffect(() => {
    const initialFilters = {};
    filters.forEach((filter) => {
      const paramValue = searchParams.get(filter.name);
      if (paramValue) {
        initialFilters[filter.name] = paramValue.split(",");
      } else {
        initialFilters[filter.name] = [];
      }
    });

    // Remove keys with empty arrays
    const filterData = Object.fromEntries(
      Object.entries(initialFilters).filter(([_, value]) => value.length > 0)
    );
    setSelectedFilters(filterData);
  }, [searchParams, filterRemoveFlag]);

  useEffect(() => {
    fetchFilters();
    // to remove the search params from the url on page load
    setSearchParams({});
  }, []);

  return (
    <Box sx={{ padding: "20px" }}>
      <ToastContainer />
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: "16px" }}>
        <Box>
          <Button
            sx={{
              height: "30px",
              width: "106px",
              backgroundColor: "#E8F0F9",
            }}
            onClick={exportToCSV}
          >
            <img
              style={{ height: "16px", width: "16px" }}
              src={exportImg}
              alt=""
            />
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "14.4px",
                marginLeft: "6px",
                textTransform: "capitalize",
              }}
            >
              Export Data
            </Typography>
          </Button>

          {permission?.CREATE && (
            <Button
              sx={{
                width: "143px",
                height: "30px",
                backgroundColor: "#003D86",
                borderRadius: "6px",
                marginLeft: "12px",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#003D86",
                },
              }}
              onClick={() => navigate("/create-cohorts")}
            >
              <img
                style={{ height: "16px", width: "16px" }}
                src={plus}
                alt=""
              />
              <Typography
                sx={{
                  fontFamily: "Lato",
                  fontSize: "12px",
                  lineHeight: "14.4px",
                  fontWeight: 500,
                  marginLeft: "6px",
                  textTransform: "capitalize",
                }}
              >
                Create New Cohort
              </Typography>
            </Button>
          )}
        </Box>
      </Box>
      {/* Table */}
      <Box>
        <FilterAndSearch
          search={search}
          setSearch={setSearch}
          tableFields={fieldsName}
          handleToggleColumn={handleToggleColumn}
          columnDisableIndex={1}
          // filterCount={3}
          filterCount={filterCount}
          dateFilter={dateFilter}
          filterSelectOptions={filterSelectOptions}
          handleDateFilter={handleDateFilter}
          filterData={filters}
          setFilterDone={setFilterDone}
          setFilterRemoveFlag={setFilterRemoveFlag}
          hideSearchFromFilter={true}
        />
        <UiTable
          row={cohorts?.data || []}
          columns={columnFields || []}
          totalCount={cohorts?.totalCount}
          paginationModel={cohorts?.paginationModel}
          setPaginationModel={handlePaginationModel}
          //   handleRowClick={handleRowClick}
          loading={loading}
        />
        {/* <CustomConfirmationDialog
          open={editData}
          setOpen={setEditData}
          deleteById={handleStatusChangeByID}
          prop={IdRef.current}
          status="delete"
          message={message}
        /> */}
      </Box>
    </Box>
  );
}
