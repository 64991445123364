import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { AndroidMockup } from "react-device-mockup";
import { useSelector } from "react-redux";
import { getGameDataName } from "../../../../../../utils/gameDataName";
import transparent_background from "../../../../../../assets/transparent_background.svg";
import { FaChevronRight } from "react-icons/fa6";
import { IoIosInformationCircleOutline } from "react-icons/io";

export default function BeforePlayPreviewSTW() {
  const data = useSelector((store) => store.OfferReducer.offerData);
  const gameDataName = getGameDataName(data?.createGame?.game_type_code);

  // Define gradients for the wheel spokes
  const gradientColors = [
    { name: "Yellow Gradient", colors: ["#FFF1BE80", "#FFFEDF80"] }, // 50% opacity in HEX
    { name: "Purple Gradient", colors: ["#E9CEFC80", "#F4F2FF80"] },
    { name: "Blue Gradient", colors: ["#C2E6FF80", "#FFFFFF80"] },
    { name: "Purple Gradient", colors: ["#E9CEFC80", "#F4F2FF80"] },
    { name: "Blue Gradient", colors: ["#C2E6FF80", "#FFFFFF80"] },
    { name: "Purple Gradient", colors: ["#E9CEFC80", "#F4F2FF80"] },
    { name: "Yellow Gradient", colors: ["#FFF1BE80", "#FFFEDF80"] },
    { name: "Purple Gradient", colors: ["#E9CEFC80", "#F4F2FF80"] },
  ];

  const rewards = data?.createGame?.game?.[gameDataName];
  const images = data?.createGame?.beforePlay2by2?.[gameDataName];

  return (
    <Box
      sx={{
        width: "33%",
        background: "#E8F0F9",
        minHeight: "calc(100vh - 100px)",
        borderRadius: "0px 6px 0px 0px",
        position: "absolute",
        top: "0px",
        right: "0px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "15px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            color: "#1D1F2C",
            mb: "20px",
          }}
        >
          Preview
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <Box>
            {/* Android device mockup for preview */}
            <AndroidMockup
              screenWidth={220}
              frameColor="#0D0D0F"
              statusbarColor="#0D0D0F"
            >
              <Box
                sx={{
                  height: "100%",
                  backgroundImage: `url(${transparent_background})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "100%",
                }}
              >
                {/* Main content area with rounded top corners */}
                <Box
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "12px 12px 0px 0px",
                    marginTop: "75px",
                    paddingTop: "8px",
                    paddingX: "8px",
                    height: "calc(100% - 80px)",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {/* Header with decorative sparkles and gradient text */}
                  <Box
                    sx={{
                      padding: "10px 4px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    {/* Title container with horizontal lines */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        marginBottom: "5px",
                      }}
                    >
                      {/* Left horizontal line */}
                      <Box
                        sx={{
                          height: "1px",
                          width: "60px",
                          background:
                            "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(200,200,200,1) 100%)",
                          marginRight: "10px",
                        }}
                      />

                      {/* Gradient title - now on a single line */}
                      <Typography
                        sx={{
                          fontFamily: "Proxima Nova, sans-serif",
                          fontSize: "15px",
                          fontWeight: 700,
                          background:
                            "linear-gradient(90deg, #FF9C6B 0%, #FF6B8B 50%, #FF6BC5 100%)",
                          backgroundClip: "text",
                          WebkitBackgroundClip: "text",
                          color: "transparent",
                          letterSpacing: "0.5px",
                          textAlign: "center",
                          whiteSpace: "nowrap", // Prevents text wrapping
                        }}
                      >
                        SPIN THE WHEEL
                      </Typography>

                      {/* Right horizontal line */}
                      <Box
                        sx={{
                          height: "1px",
                          width: "60px",
                          background:
                            "linear-gradient(90deg, rgba(200,200,200,1) 0%, rgba(255,255,255,0) 100%)",
                          marginLeft: "10px",
                        }}
                      />
                    </Box>

                    {/* Decorative elements around the header for visual appeal */}
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 20,
                        fontSize: "12px",
                        color: "#F06C94",
                      }}
                    >
                      ✦
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        top: 35,
                        left: 15,
                        fontSize: "12px",
                        color: "#9B8EE3",
                      }}
                    >
                      ♦
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 5,
                        right: 40,
                        fontSize: "10px",
                        color: "#F06C94",
                      }}
                    >
                      •
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: -5,
                        left: 40,
                        fontSize: "10px",
                        color: "#95e8d2",
                      }}
                    >
                      ✧
                    </Box>
                  </Box>

                  {/* Main Wheel Container */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "5px",
                      flex: 1,
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        width: "190px",
                        height: "190px",
                        borderRadius: "50%",
                        background:
                          "linear-gradient(135deg, #FF6BC5 0%, #FF6B8B 50%, #FF9C6B 100%)",
                        position: "relative",
                        overflow: "hidden",
                        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.08)",
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: "10px",
                          left: "10px",
                          width: "calc(100% - 20px)",
                          height: "calc(100% - 20px)",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          overflow: "hidden",
                        }}
                      >
                        {/* Wheel segments/spokes */}
                        {rewards && rewards.map((reward, i) => {
                          const segmentAngle = 360 / rewards.length;
                          const startAngle = i * segmentAngle;
                          const endAngle = (i + 1) * segmentAngle;
                          const arcPoints = [];
                          const steps = 10; // Number of points to create the curved outer edge

                          for (let step = 0; step <= steps; step++) {
                            const angle =
                              startAngle + (step / steps) * segmentAngle;
                            const radians = (angle * Math.PI) / 180;
                            // 40% is the new radius for the content area with three border layers
                            const x = 50 + 40 * Math.cos(radians);
                            const y = 50 + 40 * Math.sin(radians);
                            arcPoints.push(`${x}% ${y}%`);
                          }

                          // Get gradient colors for this spoke from the gradientColors array
                          // Use modulo to handle cases where there are more spokes than gradients
                          const gradientColor =
                            gradientColors[i % gradientColors.length];
                          const gradientStart = gradientColor.colors[0];
                          const gradientEnd = gradientColor.colors[1];

                          // Create the segment with curved outer edge that aligns with the circular border
                          return (
                            <Box
                              key={i}
                              sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                clipPath: `polygon(50% 50%, ${arcPoints.join(
                                  ", "
                                )})`,
                                background: `linear-gradient(135deg, ${gradientStart} 0%, ${gradientEnd} 100%)`,
                              }}
                            />
                          );
                        })}

                        {/* Third border layer */}
                        {rewards && rewards.map((reward, i) => {
                          const segmentAngle = 360 / rewards.length;
                          const startAngle = i * segmentAngle;

                          // Create points for the new third border
                          const outerArcPoints = [];
                          const innerArcPoints = [];
                          const steps = 10;

                          // Create points for outer and inner edges of the new gradient border
                          for (let step = 0; step <= steps; step++) {
                            const angle =
                              startAngle + (step / steps) * segmentAngle;
                            const radians = (angle * Math.PI) / 180;

                            // Outer points at 47% radius (connecting with second border)
                            const outerX = 50 + 49 * Math.cos(radians);
                            const outerY = 50 + 49 * Math.sin(radians);
                            outerArcPoints.push(`${outerX}% ${outerY}%`);

                            // Inner points at 41% radius (6px thickness)
                            const innerX = 50 + 41 * Math.cos(radians);
                            const innerY = 50 + 41 * Math.sin(radians);
                            innerArcPoints.push(`${innerX}% ${innerY}%`);
                          }

                          // Combine points to create a curved path for the new gradient border
                          const borderPoints = [
                            ...outerArcPoints,
                            ...innerArcPoints.reverse(),
                          ];

                          // Create the new gradient border segment
                          return (
                            <Box
                              key={`third-border-${i}`}
                              sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                clipPath: `polygon(${borderPoints.join(", ")})`,
                                background:
                                  "linear-gradient(135deg, #E9CEFC 0%, #F4F2FF 100%)", // New gradient colors
                                zIndex: 3,
                                overflow: "hidden" // Between segments and second border
                              }}
                            />
                          );
                        })}

                        {/* Second border layer */}
                        {rewards && rewards.map((reward, i) => {
                          const segmentAngle = 360 / rewards.length;
                          const startAngle = i * segmentAngle;

                          // Create outer arc points for the second border (outer edge)
                          const outerArcPoints = [];
                          const innerArcPoints = [];
                          const steps = 10;

                          // Create points for outer and inner edges of the second border
                          for (let step = 0; step <= steps; step++) {
                            const angle =
                              startAngle + (step / steps) * segmentAngle;
                            const radians = (angle * Math.PI) / 180;

                            // Outer points at 49% radius (from center)
                            const outerX = 50 + 49 * Math.cos(radians);
                            const outerY = 50 + 49 * Math.sin(radians);
                            outerArcPoints.push(`${outerX}% ${outerY}%`);

                            // Inner points at 47% radius (2px width for middle border)
                            const innerX = 50 + 48 * Math.cos(radians);
                            const innerY = 50 + 48 * Math.sin(radians);
                            innerArcPoints.push(`${innerX}% ${innerY}%`);
                          }

                          // Combine points to create a curved path for the second border
                          const borderPoints = [
                            ...outerArcPoints,
                            ...innerArcPoints.reverse(),
                          ];

                          // Create the second gradient border segment
                          return (
                            <Box
                              key={`second-border-${i}`}
                              sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                clipPath: `polygon(${borderPoints.join(", ")})`,
                                background:
                                  "linear-gradient(135deg, #FF6BC5 0%, #F4F4F4 100%)", // Updated to pink gradient
                                zIndex: 36, // Above third border and segments
                              }}
                            />
                          );
                        })}

                        {/* Updated reward text and icon labels - positioned to match Figma */}
                        {rewards && rewards.map((reward, i) => {
                          const segmentAngle = 360 / rewards.length;
                          const angle = i * segmentAngle + segmentAngle / 2;
                          const radians = (angle * Math.PI) / 180;

                          // Position text labels at 30% radius from center
                          const radius = 30;
                          const x = 50 + radius * Math.cos(radians);
                          const y = 50 + radius * Math.sin(radians);

                          // Determine if text should be rotated based on position
                          // For top half of wheel (135° to 315°), rotate text 180°
                          const shouldRotateText = angle > 90 && angle < 270;

                          // Sample reward data for preview - replace with actual data in production
                          const sampleRewards = [
                            { name: "Get 100 Neucoins", icon: "N" },
                            { name: "Get 50 Voucher", icon: "V" },
                            { name: "Get 100 Neucoins", icon: "N" },
                            { name: "Food 100 Off", icon: "F" },
                            { name: "Get 100 Voucher", icon: "G" },
                            { name: "Casino Welcome", icon: "C" }
                          ];
                          
                          // Use sample reward data or actual data if available
                          const rewardName = reward?.reward_type?.name || 
                                            (sampleRewards[i % sampleRewards.length]?.name || "Reward");

                          return (
                            <Box
                              key={`text-${i}`}
                              sx={{
                                position: "absolute",
                                top: `${y}%`,
                                left: `${x}%`,
                                transform: `translate(-50%, -50%) rotate(${angle}deg)`,
                                zIndex: 1000,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center"
                              }}
                            >
                              <Box 
                                sx={{ 
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  background: "transparent",
                                  padding: "1px",
                                  // gap: "0.5px",
                                  flexDirection: "row" // Explicitly set to row to ensure consistent layout
                                }}
                              >
                                {/* Logo/Icon Container - Always First */}
                                <Box
                                  sx={{
                                    width: "16px",
                                    height: "16px",
                                    borderRadius: "50%",
                                    background: "#F06C94",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    overflow: "hidden",
                                    order: 1 // Ensure icon is always first in the flex layout
                                  }}
                                >
                                  {/* If there's an actual image, use it, otherwise show sample icon */}
                                  {(images && images[i]) ? (
                                    <img
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover"
                                      }}
                                      src={
                                        images[i]?.before_play_merchant_logo ||
                                        images[i]?.before_play_logo
                                      }
                                      alt=""
                                    />
                                  ) : (
                                    <Typography
                                      sx={{
                                        fontSize: "8px",
                                        fontWeight: 700,
                                        color: "white",
                                        textAlign: "center"
                                      }}
                                    >
                                      {sampleRewards[i % sampleRewards.length]?.icon || "R"}
                                    </Typography>
                                  )}
                                </Box>
                                
                                {/* Reward Text - Always Second */}
                                <Typography
                                  sx={{
                                    fontSize: "7px",
                                    fontWeight: 600,
                                    color: "#333",
                                    textAlign: "center",
                                    width: "50px", // Fixed width for text
                                    overflow: "hidden",
                                    order: 2 // Ensure text is always second in the flex layout
                                  }}
                                >
                                  {rewardName}
                                </Typography>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>

                      {/* Outer border dots */}
                      {[...Array(20)].map((_, i) => {
                        const angle = (i * 18 * Math.PI) / 180;
                        const x = 50 + 48 * Math.cos(angle);
                        const y = 50 + 48 * Math.sin(angle);
                        const dotSize = i % 2 === 0 ? 6 : 4;

                        return (
                          <Box
                            key={`dot-${i}`}
                            sx={{
                              position: "absolute",
                              top: `${y}%`,
                              left: `${x}%`,
                              width: `${dotSize}px`,
                              height: `${dotSize}px`,
                              borderRadius: "50%",
                              backgroundColor: "white",
                              transform: "translate(-50%, -50%)",
                              zIndex: 10,
                            }}
                          />
                        );
                      })}

                      {/* Inner border dots */}
                      {[...Array(15)].map((_, i) => {
                        const angle = (i * 24 * Math.PI) / 180;
                        const x = 50 + 44 * Math.cos(angle);
                        const y = 50 + 43 * Math.sin(angle);
                        const dotSize = 3;

                        return (
                          <Box
                            key={`inner-dot-${i}`}
                            sx={{
                              position: "absolute",
                              top: `${y}%`,
                              left: `${x}%`,
                              width: `${dotSize}px`,
                              height: `${dotSize}px`,
                              borderRadius: "50%",
                              backgroundColor: "white",
                              transform: "translate(-50%, -50%)",
                              zIndex: 4,
                            }}
                          />
                        );
                      })}

                      {/* Center wheel button - pink spinner button like in the figma */}
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "25px",
                          height: "25px",
                          borderRadius: "50%",
                          backgroundColor: "#F06C94",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          zIndex: 10,
                          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        {/* White small circle in center */}
                        <Box
                          sx={{
                            width: "16px",
                            height: "16px",
                            borderRadius: "50%",
                            backgroundColor: "#fff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        />
                      </Box>
                    </Box>

                    {/* Decorative elements around the wheel */}
                    <Box
                      sx={{
                        position: "absolute",
                        top: -5,
                        right: 20,
                        fontSize: "12px",
                        color: "#F06C94",
                      }}
                    >
                      ✦
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        top: 95,
                        right: 5,
                        fontSize: "8px",
                        color: "#F06C94",
                      }}
                    >
                      •
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 5,
                        right: 30,
                        fontSize: "10px",
                        color: "#9B8EE3",
                      }}
                    >
                      ♦
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 20,
                        left: 15,
                        fontSize: "10px",
                        color: "#95e8d2",
                      }}
                    >
                      ✧
                    </Box>
                  </Box>
                  
                  {/* "Spin the Wheel" Button */}
                  <Box sx={{ mt: "15px" }}>
                    <Button
                      fullWidth
                      sx={{
                        backgroundColor: "#8800EC",
                        color: "#FFF",
                        borderRadius: "4px",
                        height: "21px",
                        textTransform: "none",
                        fontWeight: 600,
                        fontSize: "8.76px",
                        fontStyle: "normal",
                        fontFamily: `"Proxima Nova A", sans-serif`,
                        lineHeight: "13.14px",
                        textAlign: "center",
                        fontFeatureSettings: "'liga' off, 'clig' off",
                        "&:hover": {
                          backgroundColor: "#8800EC",
                        },
                      }}
                    >
                      Spin the Wheel
                    </Button>
                  </Box>

                  {/* "Read the game instructions" section */}
                  <Box
                    sx={{
                      paddingX: "16px",
                      height: "32px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "20px",
                      mt: "11px",
                      background: "#F5F5F5",
                      borderRadius: "6.5px",
                      mb: "11px",
                    }}
                  >
                    <Typography
                      sx={{
                        overflow: "hidden",
                        color: "#4D4D4D",
                        fontFeatureSettings: "'liga' off, 'clig' off",
                        textOverflow: "ellipsis",
                        fontFamily: `"Proxima Nova A", sans-serif`,
                        fontSize: "6.57px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "8.76px",
                        textAlign: "left",
                      }}
                    >
                      Read the game instructions and stand better chances to win
                    </Typography>
                    <FaChevronRight style={{ height:"6.6px",width:"6.5px" }} />
                  </Box>

                  {/* Footer area with terms and conditions text */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderTop: "1px solid #c2c0c4",
                      height: "40px",
                      overflow: "hidden",
                      color: "#4D4D4D",
                      fontFeatureSettings: "'liga' off, 'clig' off",
                      textOverflow: "ellipsis",
                      fontFamily: `"Proxima Nova A", sans-serif`,
                      fontSize: "6.57px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "8.76px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <IoIosInformationCircleOutline
                      style={{ marginRight: "4px" }}
                    />
                    Terms & Conditions
                  </Box>
                </Box>
              </Box>
            </AndroidMockup>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}