import { Box, Button, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MuiSelectAndSearch from "../../../UIComponents/MuiSelectAndSearch";
import MuiMultiSelectWithSearch from "../../../UIComponents/MuiMultiSelectWithSearch";
import BoldLabel from "../../../UIComponents/BoldLevel";
import { useDispatch, useSelector } from "react-redux";
import {
  getCohortsSetFunction,
  getRuleSetFunction,
} from "../../../Redux/OfferReducer/action";
import { FaPlus } from "react-icons/fa6";
import { setQualifyingCriteriaDataChanges } from "../../../Redux/OfferReducer/actionType";
import cohortDeleteBtn from "../../../assets/redDeleteBtn.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import editLogo from "../../../assets/Pen.png";
import SearchableMultiSelect from "../../../UIComponents/SearchableMultiSelect";
import { CiCircleInfo } from "react-icons/ci";

const validationObject = {
  linkToRule: {
    mandatory: true,
    value_type: "object",
  },
  linkToCohort: {
    mandatory: true,
    value_type: "array",
  },
};

export default function SetQualifyingCriteria({ showEdit, disableEditButton }) {
  // const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({});
  const data = useSelector(
    (store) => store.OfferReducer?.offerData?.setQualifyingCriteria
  );
  const allData = useSelector((store) => store.OfferReducer?.offerData);
  const { id } = useParams();
  console.log(allData, "data");
  const step = useSelector(
    (store) => store.OfferReducer?.offerData?.setQualifyingCriteria
  )?.step;
  const ruleData = useSelector((store) => store.OfferReducer.ruleOption);
  const ruleOption = ruleData?.map((element) => {
    return { name: element?.name, id: element?.id };
  });

  const cohortsData = useSelector((store) => store.OfferReducer.cohortsOption);
  const cohortsOption = cohortsData?.map((element) => {
    return { name: element?.name, id: element?.id, value: "INCLUDE" };
  });
  const changedData = useSelector(
    (store) => store.OfferReducer?.changedOfferData
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // const validateForm = () => {
  //   let isValid = true;
  //   const newErrors = {};

  //   if (step === 1) {
  //     if (!data?.linkToRule) {
  //       newErrors.linkToRule = "Please select a rule";
  //       isValid = false;
  //     }
  //   } else {
  //     if (!data?.linkToCohort || data.linkToCohort.length === 0) {
  //       newErrors.linkToCohort = "Please select at least one cohort";
  //       isValid = false;
  //     }
  //   }

  //   setErrors(newErrors);
  //   return isValid;
  // };

  const validateForm = () => {
    const newErrors = {};

    Object.entries(validationObject).forEach(([key, validation]) => {
      if (validation.mandatory) {
        const value = data[key];

        if (step === 1 && key === "linkToRule") {
          // Step 1 validation for 'linkToRule'
          if (!value?.id || Object.keys(value).length === 0) {
            newErrors[key] = `Please select a rule`;
          }
        } else if (step !== 1 && key === "linkToCohort") {
          // Step 2+ validation for 'linkToCohort'
          if (!value || value.length === 0) {
            newErrors[key] = `Please select at least one cohort`;
          }
        }
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSaveAndNext = () => {
    if (validateForm()) {
      if (step === 1) {
        handleData("step", 2);
        handleData("skipRule", false);
        handleData("offerDiscovery", false);
      } else {
        if (pathname.includes("edit-offers")) {
          // navigate("/offers/edit-offers/create-game");
          navigate(`/offers/edit-offers/create-game/${id}`);
        } else {
          navigate("/offers/create-offers/create-game");
        }
      }
    }
  };

  const handleSkipRule = () => {
    handleData("offerDiscovery", false);
    handleData("skipRule", true);
    handleData("linkToRule", null);
    handleData("step", 2);
  };

  const handleOfferDiscovery = () => {
    handleData("offerDiscovery", true);
    handleData("skipRule", false);
    handleData("linkToRule", null);
    handleData("step", 2);
  };

  const handlePrevious = () => {
    if (step === 2) {
      handleData("step", 1);
    } else {
      if (pathname.includes("edit-offers")) {
        navigate(`/offers/edit-offers/offer-details/${id}`);
      } else {
        navigate("/offers/create-offers/offer-details");
      }
    }
  };
  const handleData = (key, value) => {
    dispatch({
      type: setQualifyingCriteriaDataChanges,
      payload: { [key]: value },
    });
  };
  const handleCohortValueChange = (cohortId, newValue) => {
    const updatedLinkToCohort = data?.linkToCohort?.map((cohort) =>
      cohort.id === cohortId
        ? { ...cohort, value: newValue ? "INCLUDE" : "EXCLUDE" }
        : cohort
    );
    handleData("linkToCohort", updatedLinkToCohort);
  };
  const handleCohortDelete = (cohortId) => {
    const updatedLinkToCohort = data?.linkToCohort.filter(
      (cohort) => cohort.id !== cohortId
    );
    handleData("linkToCohort", updatedLinkToCohort);
  };
  console.log(data, "setQualifyingCriteriaDataChanges");
  useEffect(() => {
    dispatch(getRuleSetFunction());
    dispatch(getCohortsSetFunction());

    if (
      pathname.includes("preview") &&
      (data?.skipRule || data?.offerDiscovery)
    ) {
      handleData("step", 2);
    }
  }, []);
  return (
    <Box>
      <Box sx={{ display: showEdit ? "block" : "none" }}>
        <Box
          sx={{
            display: showEdit ? "flex" : "none",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #E4E4F0",
            background: "#FFFFFF",
            // my: "16px",
            mx: "20px",
            py: "8px",
            px: "20px",
            borderRadius: "8px 8px 0px 0px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Lato",
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "20px",
              color: "#0B1B32",
            }}
          >
            Set Qualifying Criteria{" "}
            <span style={{ color: "#858D98" }}>({step}/2)</span>
          </Typography>
          <Button
            sx={{
              display: disableEditButton ? "none" : "flex",
              gap: "6px",
              // mr: "10px",
              px: "10px",
            }}
            onClick={() => {
              if (pathname.includes("edit-offers")) {
                navigate(`/offers/edit-offers/set-qualifying-criteria/${id}`);
              } else {
                navigate("/offers/create-offers/set-qualifying-criteria");
              }
            }}
          >
            <img
              style={{ height: "16px", width: "16px" }}
              src={editLogo}
              alt=""
            />
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "16.8px",
                color: "#003D86",
                textTransform: "capitalize",
              }}
            >
              Edit
            </Typography>
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            borderBottom: "1px solid #E4E4F0",
            background: "#FFFFFF",
            // my: "16px",
            mx: "20px",
            // py: "8px",
            px: "20px",
          }}
        >
          {!data?.skipRule && !data?.offerDiscovery && (
            <Button
              sx={{
                borderBottom:
                  step === 1 ? "2px solid #0C51A1" : "2px solid transparent",
                padding: "10px",
                borderRadius: "0px",
                fontFamily: "Lato",
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "16.8px",
                color: step === 1 ? "#003D86" : "#858D98",
                textTransform: "capitalize",
              }}
              onClick={() => handleData("step", 1)}
            >
              Rule
            </Button>
          )}
          <Button
            sx={{
              borderBottom:
                step === 2 ? "2px solid #0C51A1" : "2px solid transparent",
              padding: "10px",
              borderRadius: "0px",
              fontFamily: "Lato",
              fontSize: "14px",
              fontWeight: "700",
              lineHeight: "16.8px",
              color: step === 2 ? "#003D86" : "#858D98",
              textTransform: "capitalize",
            }}
            onClick={() => handleData("step", 2)}
          >
            Cohort
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          margin: showEdit ? "0px 20px 20px 20px" : "20px",
          padding: "20px",
          background: "#FFFFFF",
          borderRadius: showEdit ? "0px 0px 8px 8px" : "8px",
          minHeight: showEdit ? "fit-content" : "calc(100vh - 90px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          {step === 1 ? (
            <Box>
              <Typography
                sx={{
                  textAlign: "left",
                  color: "#0B1B32",
                  fontFamily: "Lato",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "20px",
                  mb: showEdit ? "0px" : "26px",
                  display: showEdit ? "none" : "block",
                }}
              >
                Set Qualifying Criteria{" "}
                <span style={{ color: "#858D98" }}>({step}/2)</span>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "end",
                  flexWrap: "wrap",
                  mt: "-20px",
                }}
              >
                <Box
                  sx={{ width: "393px", display: showEdit ? "none" : "block" }}
                >
                  <BoldLabel
                    label="Link To Rule"
                    isRequired={validationObject?.linkToRule?.mandatory}
                  />
                  <MuiSelectAndSearch
                    value={data?.linkToRule}
                    onChange={(value) => handleData("linkToRule", value)}
                    options={ruleOption}
                    placeholder="Link To Rule"
                    isDisable={showEdit}
                    errorMessage={errors?.linkToRule}
                    editBoolean={changedData?.earnRuleSetId?.name ?? false}
                  />
                </Box>

                {data?.linkToRule?.name && (
                  <Box sx={{ display: showEdit ? "block" : "none" }}>
                    <BoldLabel label="Linked Rule" />
                    <Typography
                      sx={{
                        height: "26px",
                        width: "767px",
                        backgroundColor: "#F9F9FC",
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "16.8px",
                        textAlign: "left",
                        display: showEdit ? "flex" : "none",
                        alignItems: "center",
                        borderRadius: "8px",
                        mt: "8px",
                        padding: "1.6px 16px",
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                        border: changedData?.earnRuleSetId?.name
                          ? "1px solid orange"
                          : "0px solid transparent",
                      }}
                    >
                      {data?.linkToRule?.name}
                    </Typography>
                  </Box>
                )}

                {/* <Typography
                  sx={{
                    pb: "14px",
                    color: "#000",
                    fontFamily: "Lato",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "16px",
                    mx: "100px",
                    display: showEdit ? "none" : "block",
                  }}
                >
                  OR
                </Typography> */}

                <Box sx={{ pb: "4px", display: showEdit ? "none" : "block" }}>
                  <Button
                    sx={{
                      width: "177px",
                      height: "32px",
                      backgroundColor: "#003D86",
                      borderRadius: "6px",
                      color: "#FFFFFF",
                      marginBottom:errors?.linkToRule ? "20px" : "0px",
                      "&:hover": {
                        backgroundColor: "#003D86",
                      },
                    }}
                    onClick={() => {
                      if (pathname.includes("edit-offers")) {
                        navigate(
                          "/offers/edit-offers/set-qualifying-criteria/create-new-rule",
                          { state: { id: "offer" } }
                        );
                      } else {
                        navigate(
                          "/offers/create-offers/set-qualifying-criteria/create-new-rule",
                          { state: { id: "offer" } }
                        );
                      }
                    }}
                  >
                    <FaPlus />
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: "14px",
                        lineHeight: "16.8px",
                        fontWeight: 500,
                        marginLeft: "6px",
                        textTransform: "capitalize",
                      }}
                    >
                      Create New Rule
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  display: showEdit ? "none" : "flex",
                  alignItems: "center",
                  color: "#858D98",
                  mt: "4px",
                }}
              >
                <CiCircleInfo
                  style={{ height: "14px", width: "14px", marginRight: "6px" }}
                />
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "10px",
                    lineHeight: "16px",
                    fontWeight: 400,
                    color: "#858D98",
                    textAlign: "left",
                    // display: showEdit ? "none" : "block",
                  }}
                >
                  Link to Rule will allow users to qualify for the game
                </Typography>
              </Box>
              {/* <Box
                sx={{
                  display: showEdit ? "none" : "flex",
                  alignItems: "center",
                  gap: "20px",
                  marginY: "28px",
                }}
              >
                <Box
                  sx={{
                    borderTop: "1px solid #9CBFEB",
                    width: "266px",
                  }}
                >
                  {" "}
                </Box>
                <Typography
                  sx={{
                    // pb: "10px",
                    color: "#000",
                    fontFamily: "Lato",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "16px",
                    // mx: "100px",
                    // display: showEdit ? "none" : "block",
                  }}
                >
                  OR
                </Typography>
                <Box
                  sx={{
                    borderTop: "1px solid #9CBFEB",
                    width: "266px",
                  }}
                >
                  {" "}
                </Box>
              </Box>

              <Box
                sx={{ width: "590px", display: showEdit ? "none" : "block" }}
              >
                <Button
                  sx={{
                    width: "177px",
                    height: "30px",
                    backgroundColor: "#E8F0F9",
                    padding: "7px 12px",
                    borderRadius: "6px",
                    fontFamily: "Lato",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "16.8px",
                    color: "#003D86",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "#E8F0F9",
                    },
                  }}
                  onClick={handleOfferDiscovery}
                >
                  Offer discovery
                </Button>
              </Box> */}
            </Box>
          ) : (
            <Box>
              <Typography
                sx={{
                  textAlign: "left",
                  color: "#0B1B32",
                  fontFamily: "Lato",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "20px",
                  mb: "26px",
                  display: showEdit ? "none" : "block",
                }}
              >
                Set Qualifying Criteria{" "}
                <span style={{ color: "#858D98" }}>({step}/2)</span>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "end",
                  flexWrap: "wrap",
                  mt: showEdit ? "0px" : "-20px",
                }}
              >
                <Box
                  sx={{ width: "393px", display: showEdit ? "none" : "block" }}
                >
                  <BoldLabel
                    label="Link To Cohort"
                    isRequired={validationObject?.linkToCohort?.mandatory}
                  />
                  {/* <MuiMultiSelectWithSearch
                    value={data?.linkToCohort ?? []}
                    onChange={(value) => handleData("linkToCohort", value)}
                    options={cohortsOption}
                    placeholder="Link To Cohort"
                    isDisable={showEdit}
                    errorMessage={errors?.linkToCohort}
                    editBoolean={
                      data?.previous_linkToCohort?.length > 0 ?? false
                    }
                  /> */}
                  <SearchableMultiSelect
                    value={data?.linkToCohort ?? []}
                    onChange={(value) => handleData("linkToCohort", value)}
                    options={cohortsOption}
                    placeholder="Link To Cohort"
                    // hideSelectedOptions={true}
                    isDisable={showEdit}
                    errorMessage={errors?.linkToCohort}
                    hideRemoveIcon={true}
                    // editBoolean={
                    //   changedData?.collectionsIds?.length > 0 ?? false
                    // }

                    // showSelectAll={true}
                  />
                </Box>

                <Box sx={{ pb: "0px", display: showEdit ? "none" : "block" }}>
                  <Button
                    sx={{
                      width: "177px",
                      height: "32px",
                      backgroundColor: "#003D86",
                      borderRadius: "6px",
                      color: "#FFFFFF",
                      marginBottom:errors?.linkToCohort ? "24px" : "0px",
                      "&:hover": {
                        backgroundColor: "#003D86",
                      },
                    }}
                    onClick={() => {
                      if (pathname.includes("edit-offers")) {
                        navigate(
                          "/offers/edit-offers/set-qualifying-criteria/create-new-cohort",
                          { state: { id: "offer" } }
                        );
                      } else {
                        navigate(
                          "/offers/create-offers/set-qualifying-criteria/create-new-cohort",
                          { state: { id: "offer" } }
                        );
                      }
                    }}
                  >
                    <FaPlus />
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: "14px",
                        lineHeight: "16.8px",
                        fontWeight: 500,
                        marginLeft: "6px",
                        textTransform: "capitalize",
                        // display: showEdit ? "none" : "block",
                      }}
                    >
                      Create New Cohort
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  display: showEdit ? "none" : "flex",
                  alignItems: "center",
                  color: "#858D98",
                  mt: "8px",
                }}
              >
                <CiCircleInfo
                  style={{ height: "14px", width: "14px", marginRight: "6px" }}
                />
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "10px",
                    lineHeight: "16px",
                    fontWeight: 400,
                    color: "#858D98",
                    textAlign: "left",
                    // display: showEdit ? "none" : "block",
                  }}
                >
                  Link to Cohort will allow users to qualify for the game
                </Typography>
              </Box>
              <Box
                mt={showEdit ? "-20px" : "26px"}
                sx={{
                  display: data?.linkToCohort?.length > 0 ? "block" : "none",
                }}
              >
                {/* <Label label="Added Cohort" /> */}
                <BoldLabel
                  label={`Linked Cohorts${
                    data?.linkToCohort?.length > 0
                      ? ` ( ${data.linkToCohort.length} Selected )`
                      : ""
                  }`}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    // mt:"8px",
                  }}
                >
                  {data?.linkToCohort &&
                    data?.linkToCohort?.map((e, i) => (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            backgroundColor: "#F9F9FC",
                            padding: showEdit ? "0px 16px" : " 3px 16px",
                            height: showEdit ? "29px" : "30px",
                            borderRadius: "8px",
                            margin: "8px 0 0 0",
                            width: "767px",
                            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                            border:
                              changedData?.collectionsIds?.length > 0
                                ? "1px solid orange"
                                : "0px solid transparent",
                          }}
                          key={i}
                        >
                          <span
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Lato",
                                fontWeight: 400,
                                fontSize: "13px",
                                lineHeight: "15.6px",
                                color: "#000000",
                              }}
                            >
                              {i + 1} .
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily: "Lato",
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "16.8px",
                                color: "#000000",
                              }}
                            >
                              {e.name}
                            </Typography>
                          </span>
                          <Box
                            paddingBottom="5px"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              mt: showEdit ? "3px" : "0px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Lato",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "14.4px",
                                color: "#000000",
                                // display: showEdit ? "none" : "block",
                              }}
                            >
                              Exclude
                            </Typography>
                            <Switch
                              disabled={showEdit ? true : false}
                              sx={{
                                "& .MuiSwitch-switchBase.Mui-checked": {
                                  color: "#ffffff",
                                },
                                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                  {
                                    backgroundColor: "#29CC6A",
                                  },
                                // display: showEdit ? "none" : "",
                              }}
                              checked={e?.value === "INCLUDE"}
                              onChange={(event) =>
                                handleCohortValueChange(
                                  e.id,
                                  event.target.checked
                                )
                              }
                            />
                            <Typography
                              sx={{
                                fontFamily: "Lato",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "14.4px",
                                color: "#000000",
                                // display: showEdit ? "none" : "block",
                              }}
                            >
                              Include
                            </Typography>

                            <Button
                              disabled={showEdit ? true : false}
                              sx={
                                {
                                  // display: showEdit ? "none" : "",
                                }
                              }
                              onClick={() => handleCohortDelete(e.id)}
                            >
                              <img src={cohortDeleteBtn} alt="" />
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: showEdit ? "none" : "flex",
            justifyContent: "flex-start",
            gap: "16px",
            marginTop: "41px",
            float: "bottom",
          }}
        >
          <Button
            sx={{
              width: "136px",
              height: "30px",
              backgroundColor: "#E8F0F9",
              padding: "7px 12px",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#E8F0F9",
              },
            }}
            onClick={handlePrevious}
          >
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "16.8px",
                color: "#003D86",
                textTransform: "capitalize",
              }}
            >
              Previous
            </Typography>
          </Button>
          <Button
            sx={{
              width: "136px",
              height: "30px",
              backgroundColor: "#003D86",
              padding: "7px 12px",
              borderRadius: "6px",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#003D86",
              },
            }}
            // onClick={() => {
            //   if (step === 1) {
            //     // setStep(2);
            //     handleData("step", 2);
            //   } else {
            //     navigate("/offers/create-offers/create-game");
            //   }
            // }}
            onClick={handleSaveAndNext}
          >
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "16.8px",
                color: "#FFFFFF",
              }}
            >
              Save & Next
            </Typography>
          </Button>
          {/* {step === 1 && (
            <Button
              sx={{
                width: "136px",
                height: "30px",
                backgroundColor: "#FFFFFF",
                padding: "7px 12px",
                borderRadius: "6px",
                border: "1px solid #003D86",
                fontFamily: "Lato",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "16.8px",
                color: "#003D86",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                },
              }}
              onClick={handleSkipRule}
            >
              Skip
            </Button>
          )} */}
        </Box>
      </Box>
    </Box>
  );
}
