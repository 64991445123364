import React from "react";
import { getGameDataName } from "../../../../../../utils/gameDataName";
import { useSelector } from "react-redux";
import AfterPlayPreviewPromoNeuCoinsSTW from "./AfterPlayPreviewPromoNeuCoinsSTW";
import AfterPlayPreviewDigitalGoldSTW from "./AfterPlayPreviewDigitalGoldSTW";
import AfterPlayPreviewNeuCoinsSTW from "./AfterPlayPreviewNeuCoinsSTW";
import AfterPlayPreviewVoucherSTW from "./AfterPlayPreviewVoucherSTW";
import AfterPlayPreviewBLNT_STW from "./AfterPlayPreviewBLNT_STW";


export default function AfterPlayPreviewSTW({ openRewardIndex }) {
  const data = useSelector((store) => store.OfferReducer.offerData);
  const gameDataName = getGameDataName(data?.createGame?.game_type_code);
  const isDigitalGold =
    data?.createGame?.afterPlay?.[gameDataName]?.[openRewardIndex]?.reward_type
      ?.id === "digital_gold";

  const isNeuCoins =
    data?.createGame?.afterPlay?.[gameDataName]?.[openRewardIndex]?.reward_type
      ?.id === "neu_coin";

  const isCashback =
    data?.createGame?.afterPlay?.[gameDataName]?.[openRewardIndex]?.reward_type
      ?.id === "cashback";

  const isPromoNeuCoins =
    data?.createGame?.afterPlay?.[gameDataName]?.[openRewardIndex]?.reward_type
      ?.id === "promo_neu_coin";

  const isVoucher =
    data?.createGame?.afterPlay?.[gameDataName]?.[openRewardIndex]?.reward_type
      ?.id === "voucher";

  const isBetterLuckNextTime =
    data?.createGame?.afterPlay?.[gameDataName]?.[openRewardIndex]?.reward_type
      ?.id === "bl_next_time";

  return (
    <div>
      {isDigitalGold && (
        <AfterPlayPreviewDigitalGoldSTW openRewardIndex={openRewardIndex} />
      )}
      {isNeuCoins && (
        <AfterPlayPreviewNeuCoinsSTW openRewardIndex={openRewardIndex} />
      )}
      {isPromoNeuCoins && (
        <AfterPlayPreviewPromoNeuCoinsSTW openRewardIndex={openRewardIndex} />
      )}
      {isVoucher && (
        <AfterPlayPreviewVoucherSTW openRewardIndex={openRewardIndex} />
      )}
      {isBetterLuckNextTime && (
        <AfterPlayPreviewBLNT_STW openRewardIndex={openRewardIndex} />
      )}
    </div>
  );
}
