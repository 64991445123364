import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import BoldLabel from "../../../../UIComponents/BoldLevel";
import { selectGameType } from "../../../../Redux/OfferReducer/actionType";
import { Search, GamepadOutlined } from "@mui/icons-material";
import { getAllGameTypes } from "../../../../Redux/OfferReducer/action";

const NoGamesFound = ({ onRefresh }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        // backgroundColor: '#F9F9FC',
        borderRadius: "16px",
        padding: "24px",
        textAlign: "center",
        // boxShadow: '0 4px 6px rgba(0,0,0,0.05)'
      }}
    >
      <GamepadOutlined
        sx={{
          fontSize: 80,
          color: "#9CBFEB",
          marginBottom: "16px",
        }}
      />

      <Typography
        variant="h5"
        sx={{
          fontFamily: "Mulish",
          fontWeight: 700,
          color: "#272728",
          marginBottom: "12px",
        }}
      >
        No Games Found
      </Typography>

      <Typography
        variant="body1"
        sx={{
          fontFamily: "Lato",
          color: "#6B7280",
          marginBottom: "24px",
          maxWidth: "400px",
        }}
      >
        It seems like there are no games available at the moment. Please check
        back later or contact support if you believe this is an error.
      </Typography>

      {onRefresh && (
        <Box
          component="button"
          onClick={onRefresh}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            padding: "10px 20px",
            backgroundColor: "#9CBFEB",
            color: "white",
            border: "none",
            borderRadius: "8px",
            cursor: "pointer",
            transition: "background-color 0.3s ease",
            "&:hover": {
              backgroundColor: "#7AAAE6",
            },
          }}
        >
          <Search sx={{ fontSize: 20 }} />
          <Typography
            variant="button"
            sx={{
              fontFamily: "Mulish",
              fontWeight: 600,
            }}
          >
            Refresh Games
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default function AllGames() {
  const [gameType, setGameType] = useState("");
  const gameData = useSelector((store) => store?.OfferReducer?.allGames);
  const data = useSelector(
    (store) => store?.OfferReducer?.offerData?.createGame
  );
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const handleSelectGame = (game, id, game_type_code) => {
    dispatch({
      type: selectGameType,
      payload: { gameName: game, game_type_id: id, gameTypeName: game , game_type_code },
    });
    setGameType(game);
  };
  return (
    <Box>
      <Box>
        <BoldLabel label="Choose a Game type" isRequired={true} />
        <Box sx={{ display: "flex", gap: "20px", height: "40vh", mt: "8px" }}>
          {gameData?.map((game, index) => {
            const isSelected = data?.game_type_id === game?.id;
            const isEditable = pathname.startsWith("/offers/edit-offers");
            const isComingSoon = game?.game_type_code !== "spin_the_wheel" && game?.game_type_code !== "scratch_card";

            const cursorStyle = isComingSoon ? "not-allowed" : ((isEditable && isSelected) || !isEditable ? "pointer" : "not-allowed");

            return (
              <Box
                key={index}
                sx={{
                  height: "160px",
                  width: "23%",
                  border: isSelected
                    ? "1.5px solid #9CBFEB"
                    : "1.5px solid transparent",
                  borderRadius: "12px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: cursorStyle,
                  gap: "8px",
                  backgroundColor: isSelected ? "#E8F0F9" : "#F9F9FC",
                }}
                onClick={() => {
                  if (!isEditable && !isComingSoon) {
                    handleSelectGame(game?.name, game?.id , game?.game_type_code);
                  }
                }}
              >
                <img
                  src={game?.icon}
                  style={{ 
                    height: "58px", 
                    width: "58px",
                    filter: isComingSoon ? "blur(2px) grayscale(50%)" : "none",
                    opacity: isComingSoon ? "0.6" : "1"
                  }}
                  alt={`${game?.name} Image`}
                />
                <Typography
                  sx={{
                    fontFamily: "Mulish",
                    fontWeight: isComingSoon ? 700 : 600,
                    fontSize: "16px",
                    lineHeight: isComingSoon ? "normal" : "20.08px",
                    color: isComingSoon ? "#003D86" : "#272728",
                    textAlign: isComingSoon ? "center" : "inherit",
                    fontStyle: "normal",
                  }}
                >
                  {game?.name} {isComingSoon && <span>Coming Soon</span>}
                </Typography>
                {/* {isComingSoon && (
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "18px",
                      color: "#FF0000",
                    }}
                  >
                    Coming Soon
                  </Typography>
                )} */}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}
