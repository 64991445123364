import React, { useRef, useState, useEffect, memo, useMemo } from "react";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
  RichUtils,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const CustomRichEditor = ({
  placeholder = "",
  onChange,
  value,
  errorMessage = "",
  editBoolean,
  isDisable,
  minHeight = "145px",
  maxHeight = "145px",
}) => {
  const editorRef = useRef(null);
  
  // Track if the editor is focused by the user
  const [isFocused, setIsFocused] = useState(false);
  
  // Track if we're updating the editor programmatically
  const isUpdatingRef = useRef(false);

  const getInitialEditorState = () => {
    if (!value) {
      return EditorState.createEmpty();
    }

    const blocksFromHTML = convertFromHTML(value);

    if (
      !blocksFromHTML.contentBlocks ||
      blocksFromHTML.contentBlocks.length === 0
    ) {
      return EditorState.createEmpty();
    }

    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    return EditorState.createWithContent(contentState);
  };

  const [editorState, setEditorState] = useState(getInitialEditorState);
  
  // Use this function to update editor state while preventing focus issues
  const updateEditorState = (newState) => {
    isUpdatingRef.current = true;
    setEditorState(newState);
    setTimeout(() => {
      isUpdatingRef.current = false;
    }, 0);
  };

  // Only update from props when value changes and we're not actively editing
  useEffect(() => {
    if (isFocused) return;
    
    if (!value && editorState.getCurrentContent().hasText()) {
      updateEditorState(EditorState.createEmpty());
    } else if (value) {
      try {
        const currentContent = draftToHtml(
          convertToRaw(editorState.getCurrentContent())
        );

        // Only update if content is different and we're not focused
        if (currentContent !== value) {
          const blocksFromHTML = convertFromHTML(value);

          if (
            !blocksFromHTML.contentBlocks ||
            blocksFromHTML.contentBlocks.length === 0
          ) {
            updateEditorState(EditorState.createEmpty());
            return;
          }

          const contentState = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );

          const newEditorState = EditorState.createWithContent(contentState);
          updateEditorState(newEditorState);
        }
      } catch (error) {
        console.error("Error updating editor state:", error);
      }
    }
  }, [value, isFocused]);

  const handleEditorChange = (newState) => {
    // Only handle changes when not being updated programmatically
    if (!isUpdatingRef.current) {
      setEditorState(newState);

      if (!isDisable) {
        const contentState = newState.getCurrentContent();
        if (!contentState.hasText() && contentState.getBlockMap().size <= 1) {
          onChange("");
          return;
        }

        let newContent = draftToHtml(convertToRaw(contentState));

        // **Fix for list formatting**
        let cleanContent = newContent
          // .replace(/>\s+</g, "><") // Remove unnecessary spaces
          .replace(/\n/g, "") // Remove extra line breaks
          .replace(/\\"/g, '"')
          .replace(/"([^"]+)"/g, "'$1'")
          .replace(/<p>(<ol>|<ul>)/g, "$1") // Remove <p> wrapping lists
          .replace(/(<\/ol>|<\/ul>)<\/p>/g, "$1"); // Remove </p> wrapping lists

        const isEmpty =
          cleanContent
            .replace(/<p>(&nbsp;|\s|<br>|<br\/>|<br \/>)*<\/p>/g, "")
            .trim() === "";
        onChange(isEmpty ? "" : cleanContent);
      }
    }
  };

  // Handle key commands (like bold, italic) without losing cursor
  const handleKeyCommand = (command, state) => {
    const newState = RichUtils.handleKeyCommand(state, command);
    if (newState) {
      handleEditorChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  // Handle pasted text with formatting
  const handlePastedText = (text, html, editorState) => {
    if (html) {
      try {
        const blocksFromHTML = convertFromHTML(html);
        
        // Ensure we have valid blocks
        if (!blocksFromHTML.contentBlocks || blocksFromHTML.contentBlocks.length === 0) {
          return false;
        }
        
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        
        // Create new state
        const newState = EditorState.push(
          editorState,
          contentState,
          'insert-fragment'
        );
        
        handleEditorChange(newState);
        return true;
      } catch (error) {
        console.error("Error handling paste:", error);
        return false;
      }
    }
    return false;
  };

  const toolbarOptions = useMemo(
    () => ({
      options: ["inline", "list", "textAlign", "history"],
      inline: {
        options: ["bold", "italic", "underline"],
        inDropdown: false,
      },
      list: {
        options: ["unordered", "ordered"],
        inDropdown: false,
      },
      textAlign: {
        options: ["left", "center", "right"],
        inDropdown: false,
      },
    }),
    []
  );

  const editorWrapperStyle = {
    border: errorMessage
      ? "1px solid #d40e10"
      : editBoolean
      ? "1px solid orange"
      : "1px solid #ccc",
    borderRadius: "6px",
    overflow: "hidden",
  };

  const editorStyle = {
    minHeight: minHeight,
    maxHeight: maxHeight,
    paddingLeft: "10px",
    overflow: "auto",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    wordBreak: "break-all",
    whiteSpace: "pre-wrap",
  };

  return (
    <div style={{ marginTop: "10px", width: "100%" }}>
      <style>
      {`
          .public-DraftEditor-content {
            word-break: break-all;
            word-wrap: break-word;
            overflow-wrap: break-word;
          }
          .DraftEditor-root {
            width: 100%;
          }
          /* Add padding to all toolbar buttons */
          .rdw-option-wrapper {
            padding: 5px !important;
          }
          /* Ensure consistent styling for buttons */
          .rdw-option-wrapper img {
            display: block;
          }
          /* Style for active buttons */
          .rdw-option-active {
            box-shadow: 1px 1px 0px #ccc inset !important;
          }
        `}
      </style>
      <div style={editorWrapperStyle}>
        <Editor
          ref={editorRef}
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          toolbar={toolbarOptions}
          placeholder={placeholder}
          readOnly={isDisable}
          toolbarClassName="custom-toolbar"
          editorStyle={editorStyle}
          handlePastedText={handlePastedText}
          handleKeyCommand={handleKeyCommand}
          stripPastedStyles={false}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </div>
      {errorMessage && (
        <div
          style={{
            color: "#d40e10",
            marginTop: "4px",
            fontSize: "14px",
            textAlign: "left",
            marginLeft: "10px",
          }}
        >
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default memo(CustomRichEditor);




// jodit editor (old)

// import React, { useRef, memo, useMemo } from "react";
// import JoditEditor from "jodit-react";
// import { Box } from "@mui/material";

// const RichEditor = ({
//   placeholder = "",
//   onChange,
//   value,
//   errorMessage,
//   editBoolean,
//   isDisable,
// }) => {
//   const editor = useRef(null);

//   const handleChange = (newContent) => {
//     if (!newContent) {
//       onChange('');
//       return;
//     }

//     // Remove literal newlines, extra spaces between HTML tags, and escaped quotes
//     let cleanContent = newContent
//       .replace(/>\s+</g, '><')
//       .replace(/\n/g, '')
//       .replace(/\\"/g, '"')
//       .replace(/"([^"]+)"/g, "'$1'"); // Convert double quotes to single quotes for text content
    
//     const isEmpty = cleanContent.replace(/<p>(&nbsp;|\s|<br>|<br\/>|<br \/>)*<\/p>/g, '').trim() === '';
//     onChange(isEmpty ? '' : cleanContent);
//   };

//   const config = useMemo(() => {
//     return {
//       readonly: isDisable,
//       placeholder: placeholder || "",
//       height: 300,
//       disablePlugins: [
//         "font",
//         "speech-recognize",
//         "add-new-line",
//         "image",
//         "video",
//         "file",
//         "media",
//         "print",
//         "preview",
//         "table",
//         "symbols",
//         "eraser",
//         "strikethrough",
//         "brush",
//         "source",
//         "spellcheck",
//         "symbols",
//         "indent",
//         "fullsize",
//         "|",
//         "outdent",
//         "indent",
//         "fullsize",
//         "horizontal-line", // Added this
//         "insert-format-block", // Added this (corrected spelling)
//         "formatBlock",
//         "link",
//         "line-height",
//       ],
//       editorCssClass: editBoolean ? "orange-outline" : "",
//       buttons: [
//         "bold",
//         "italic",
//         "underline",
//         "ul",
//         "ol",
//         "align",
//         "undo",
//         "redo",
//       ],
//       controls: {
//         ol: {
//           data: {
//             number: "1, 2, 3...",
//           },
//           list: {
//             number: "Number",
//           },
//           command: "insertOrderedList",
//           tags: ["ol"],
//           tooltip: "Ordered list",
//         },
//         ul: {
//           command: "insertUnorderedList",
//           tags: ["ul"],
//           tooltip: "Unordered list",
//         },
//       },
//     };
//   }, [placeholder, editBoolean, isDisable]);

//   return useMemo(
//     () => (
//       <Box
//         sx={{
//           mt: "10px",
//           width: "100%",
//           textAlign: "start",
//           mb: "0px",
//           "& .jodit-container": {
//             border: errorMessage
//               ? "1px solid #d40e10"
//               : editBoolean
//               ? "1px solid orange"
//               : "",
//             minHeight: "50px !important",
//             maxHeight: "200px !important",
//             borderRadius: "6px !important",
//             overflow: "hidden",
//           },
//           "& .jodit-wysiwyg": {
//             minHeight: "50px !important",
//             maxHeight: "100px !important",
//           },
//           "& .jodit-workplace": {
//             minHeight: "50px !important",
//             maxHeight: "100px !important",
//             fontFamily: "Lato",
//             fontWeight: 400,
//             fontSize: "12px",
//             lineHeight: "16px",
//             color: "#1D1F2C",
//             pointerEvents: isDisable ? "none" : "auto",
//           },
//           // Styles for unordered lists
//           "& ul": {
//             listStyle: "disc",
//             marginLeft: "20px",
//             "& li": {
//               marginBottom: "5px",
//             },
//           },
//           // Styles for nested unordered lists
//           "& ul ul": {
//             listStyle: "circle",
//             marginLeft: "20px",
//           },
//           "& ul ul ul": {
//             listStyle: "square",
//             marginLeft: "20px",
//           },
//           // Styles for ordered lists
//           "& ol": {
//             listStyle: "decimal",
//             marginLeft: "20px",
//             "& li": {
//               marginBottom: "5px",
//             },
//           },
//           // Styles for nested ordered lists
//           "& ol ol": {
//             listStyle: "lower-alpha",
//             marginLeft: "20px",
//           },
//           "& ol ol ol": {
//             listStyle: "lower-roman",
//             marginLeft: "20px",
//           },
//         }}
//       >
//         <JoditEditor
//           ref={editor}
//           value={value}
//           config={config}
//           onChange={isDisable ? undefined : handleChange}
//         />
//         {errorMessage && (
//           <Box
//             sx={{
//               color: "error.main",
//               fontSize: "14px",
//               mt: 0.5,
//               textAlign: "start",
//               paddingLeft: "10px",
//             }}
//           >
//             {errorMessage}
//           </Box>
//         )}
//       </Box>
//     ),
//     [config, value, onChange, errorMessage, editBoolean, isDisable]
//   );
// };

// export default memo(RichEditor);

