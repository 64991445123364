import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { AndroidMockup } from "react-device-mockup";
import { useSelector } from "react-redux";
import { getGameDataName } from "../../../../../../utils/gameDataName";
import previous_image from "../../../../../../assets/previous_image.svg";
import transparent_background from "../../../../../../assets/transparent_background.svg";
import { IoIosArrowBack } from "react-icons/io";
import { RxCross1 } from "react-icons/rx";
// import quiz_image from "../../../../../../assets/16ratio9.png";

export default function QuizPreviewSTW() {
  const data = useSelector((store) => store.OfferReducer.offerData);
  // const gameDataName = getGameDataName(data?.createGame?.game_type_id);
  // const openRewardIndex = data?.createGame?.openRewardIndex;
  // const [previewIndex, setPreviewIndex] = useState(0);
  const options = data?.createGame?.quiz?.spinTheWheelData?.Options || [];
  const question = data?.createGame?.quiz?.spinTheWheelData?.Question || "";
  const answer = data?.createGame?.quiz?.spinTheWheelData?.Answer || "";
  const quiz_image =
    data?.createGame?.quiz?.spinTheWheelData?.question_banner || "";
  console.log(data?.createGame?.quiz?.spinTheWheelData, options, "options");
  return (
    <Box
      sx={{
        width: "33%",
        background: "#E8F0F9",
        minHeight: "calc(100vh - 100px)",
        borderRadius: "0px 6px 0px 0px",
        position: "absolute",
        top: "0px",
        right: "0px",
        display: "flex", // Added display: flex
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column", // Changed to column layout
          alignItems: "center",
          gap: "15px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            color: "#1D1F2C",
            mb: "20px",
          }}
        >
          Preview
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
          {/* <Box>
            <img
              src={previous_image}
              alt="previous_image"
              style={{
                cursor: "pointer",
                height: "32px",
                width: "32px",
              }}
              onClick={() => {
                if (previewIndex === 0) {
                  setPreviewIndex(1);
                } else {
                  setPreviewIndex(0);
                }
              }}
            />
          </Box> */}

          <Box>
            <AndroidMockup
              screenWidth={220}
              frameColor="#0D0D0F"
              statusbarColor="#0D0D0F"
            >
              <Box
                sx={{
                  height: "100%",
                  backgroundImage: `url(${transparent_background})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "12px 12px 0px 0px",
                    marginTop: "75px",
                    paddingTop: "8px",
                    paddingX: "8px",
                    height: "calc(100% - 115px)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Proxima Nova A",
                        fontSize: "10.931px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "15.304px",
                      }}
                    >
                      Well done
                    </Typography>
                    <RxCross1
                      style={{
                        height: "9px",
                        width: "9px",
                        color: "#78787D",
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      color: "#474747",
                      fontFeatureSettings: "'liga' off, 'clig' off",
                      fontFamily: "Proxima Nova A",
                      fontSize: "7.652px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "10.931px",
                      textAlign: "start",
                      marginTop: "2px",
                    }}
                  >
                    Answer correctly to enter the lucky draw.
                  </Typography>
                  <Box
                    sx={{
                      marginY: "8px",
                      width: "100%",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        aspectRatio: "16:9",
                        borderRadius: "4px",
                        height: "115px",
                      }}
                      src={quiz_image}
                      alt="quiz_image"
                    />
                  </Box>

                  <Box
                    sx={{
                      mx: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#212121",
                        fontFeatureSettings: "'liga' off, 'clig' off",
                        fontFamily: "Proxima Nova A",
                        fontSize: "9.838px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "13.117px",
                        textAlign: "start",
                      }}
                    >
                      {question}
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        marginTop: "10px",
                        height: "90px",
                        overflowY: "auto",
                        msOverflowStyle: "none", // Hide scrollbar in IE/Edge
                        scrollbarWidth: "none", // Hide scrollbar in Firefox
                        "&::-webkit-scrollbar": {
                          // Hide scrollbar in Chrome/Safari/Webkit
                          display: "none",
                        },
                      }}
                    >
                      {options?.map(
                        (item, index) =>
                          item?.value && (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <Box
                                sx={{
                                  width: "13px",
                                  height: "13px",
                                  border:
                                    item?.id == answer
                                      ? "3px solid #8800EC"
                                      : "1px solid #78787D",
                                  borderRadius: "50%",
                                  backgroundColor:
                                    item?.id == answer
                                      ? "#white"
                                      : "transparent",
                                  position: "relative",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {/* {item?.id == answer && (
                                  <Box
                                    sx={{
                                      width: "6px",
                                      height: "6px",
                                      borderRadius: "50%",
                                      backgroundColor: "white",
                                    }}
                                  />
                                )} */}
                              </Box>
                              <Typography
                                sx={{
                                  color: "#212121",
                                  fontFeatureSettings: "'liga' off, 'clig' off",
                                  fontFamily: "Proxima Nova A",
                                  fontSize: "7.652px",
                                  fontStyle: "normal",
                                  fontWeight: item?.id == answer ? 600 : 400,
                                  lineHeight: "10.931px",
                                }}
                              >
                                {item?.value}
                              </Typography>
                            </Box>
                          )
                      )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      marginTop: "19px",
                      marginBottom: "10px",
                    }}
                  >
                    <Button
                      sx={{
                        width: "100%",
                        height: "22px",
                        borderRadius: "3px",
                        // backgroundColor: "#2A2A2A1F",
                        // color: "#767070",
                        backgroundColor: "#8800EC",
                        color: "white",
                        fontFamily: "Proxima Nova A",
                        fontSize: "7.652px",
                        fontWeight: 500,
                        textAlign: "center",
                        fontFeatureSettings: "'liga' off, 'clig' off",
                        fontStyle: "normal",
                        lineHeight: "10.931px",
                        letterSpacing: "1.093px",
                        textTransform: "uppercase",
                        "&:hover": {
                          // backgroundColor: "#2A2A2A1F",
                          backgroundColor: "#8800EC",
                        },
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={{
                    pt: "10px",
                    pb: "13px",
                    backgroundColor: "#F4F4F4",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#474747",
                      fontFeatureSettings: "'liga' off, 'clig' off",
                      fontFamily: "Proxima Nova A",
                      fontSize: "7.652px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "10.931px",
                      textDecorationStyle: "solid",
                      textDecorationSkipInk: "none",
                      textUnderlineOffset: "auto",
                      textUnderlinePosition: "from-font",
                    }}
                  >
                    I agree to the{" "}
                    <span
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      Terms & Conditions
                    </span>
                  </Typography>
                </Box>
              </Box>
            </AndroidMockup>
          </Box>
          {/* 
          <Box>
            <img
              src={previous_image}
              alt="previous_image"
              style={{
                cursor: "pointer",
                height: "32px",
                width: "32px",
                rotate: "180deg",
              }}
              onClick={() => {
                if (previewIndex === 0) {
                  setPreviewIndex(1);
                } else {
                  setPreviewIndex(0);
                }
              }}
            />
          </Box> */}
        </Box>

        {/* <Typography
          sx={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            color: "#1D1F2C",
            mt: "20px",
          }}
        >{`(${previewIndex + 1}/2)`}
        </Typography> */}
      </Box>
    </Box>
  );
}
