import React, { useEffect, useRef, useState } from "react";
import {
  getMakerCheckerPermission,
  getToken,
  getUserId,
} from "../../../utils/token";
import axios from "axios";
import { Box, Button, Typography } from "@mui/material";
import { BootstrapTooltip } from "../../../utils/tooltip";
import { MdPreview } from "react-icons/md";
import usePermission from "../../../utils/permissions";
import CustomSwitch from "../../../UIComponents/CustomSwitch";
import { FaPauseCircle, FaPlayCircle, FaPlus } from "react-icons/fa";
import copyIcon from "../../../assets/copy.png";
import UiTable from "../../UiTable";
import preview from "../../../assets/eye.svg";
import copy from "../../../assets/copy.svg";
import play from "../../../assets/play.svg";
import pause from "../../../assets/pause.svg";
import { toast, ToastContainer } from "react-toastify";
import CustomConfirmationDialog from "../../../UIComponents/CustomConfirmationDialog";
import SearchBar from "../../../UIComponents/SearchBar";
import exportImg from "../../../assets/export.svg";
import plus from "../../../assets/Plus.svg";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  editReward,
  saveAfterPlayWithGameChanges,
  saveChangedOfferData,
  selectGameType,
  setAfterPlayReward,
  setAfterPlayWithGameChanges,
  setBeforePlay2by2Change,
  setBeforePlay2by2Reward,
  setBeforePlayChange,
  setOfferDetailsDataChanges,
  setQualifyingCriteriaDataChanges,
  setQuizChange,
} from "../../../Redux/OfferReducer/actionType";
import TableTotalCount from "../../TableTotalCount";
import ToggleColumn from "../../../UIComponents/ToggleColumn";
import FilterAndSearch from "../../Filter/FilterAndSearch";
import dayjs from "dayjs";
import { formatDateNew } from "../../../utils/formateDate";
import { getGameDataName } from "../../../utils/gameDataName";
import priority_zero from "../../../assets/priority_zero.svg";
import priority_more_then_one from "../../../assets/priority_more_then_zero.svg";

const TData = () => {
  return [
    { key: "id", displayName: "Offer ID", flag: true },
    { key: "name", displayName: "Offer Name", flag: true },
    { key: "game_type", displayName: "Game Type", flag: true },
    // { key: "reward_currency", displayName: "Reward Currency", flag: true },
    // { key: "duration", displayName: "Duration", flag: true },
    { key: "offer_status", displayName: "Offer Status", flag: true },
    { key: "product_id", displayName: "Product", flag: true },
    { key: "category_id", displayName: "Category", flag: true },
    { key: "subcategory_id", displayName: "Subcategory", flag: true },
    { key: "merchant_id", displayName: "Merchant", flag: true },
    { key: "created_by", displayName: "Created By", flag: false },
    { key: "offer_created_date", displayName: "Created Date", flag: false },
    { key: "starts_at", displayName: "Start Date", flag: false },
    { key: "ends_at", displayName: "End Date", flag: false },
    { key: "programId", displayName: "Program Id", flag: false },
    { key: "visibility", displayName: "Visibility", flag: true },
  ];
};

function NotApplicable() {
  return (
    <Typography
      fontFamily="Lato"
      fontWeight={400}
      fontSize="12px"
      lineHeight="14.4px"
      letterSpacing="2%"
    >
      NA
    </Typography>
  );
}

const dateFilterLayout = {
  select: "ALL_TIME",
  from_time: "",
  to_time: "",
};

const filterSelectOptions = [
  { label: "Custom", value: "CUSTOM" },
  { label: "Today", value: "TODAY" },
  { label: "Yesterday", value: "YESTERDAY" },
  { label: "This Week", value: "THIS_WEEK" },
  { label: "This Month", value: "THIS_MONTH" },
  { label: "All Time", value: "ALL_TIME" },
];

// function formatDateNew(inputDateString) {
//   const inputDate = new Date(inputDateString);

//   if (isNaN(inputDate.getTime())) {
//     // Handle invalid date
//     return "Invalid Date";
//   }

//   const year = inputDate.getFullYear();
//   const month = (inputDate.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because months are zero-based
//   const day = inputDate.getDate().toString().padStart(2, "0");

//   return `${year}-${month}-${day}`;
// }

export default function ViewOfferPage() {
  const [fieldsName, setFieldName] = useState(TData);
  const [columnFields, setColumnFields] = useState([]);
  const [dateFilter, setDateFilter] = useState(dateFilterLayout);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterDone, setFilterDone] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState([]);
  const [filterRemoveFlag, setFilterRemoveFlag] = useState(false);
  const [removeFilter, setRemoveFilter] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(() => {
    const initialFilters = {};
    filters.forEach((filter) => {
      const paramValue = searchParams.get(filter.name);
      if (paramValue) {
        initialFilters[filter.name] = paramValue.split(",");
      } else {
        initialFilters[filter.name] = [];
      }
    });

    // Remove keys with empty arrays
    return Object.fromEntries(
      Object.entries(initialFilters).filter(([_, value]) => value.length > 0)
    );
  });
  const [offers, setOffers] = useState({
    data: [],
    paginationModel: {
      pageSize: 15,
      page: 0,
    },
    totalCount: 0,
    allOfferCount: 0,
    pendingOfferCount: 0,
    denyOfferCount: 0,
  });
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("All Offers");
  const [offerStatus, setOfferStatus] = useState(false);
  const [flag, setFlag] = useState(true);
  const [editData, setEditData] = useState(false);
  const [message, setMessage] = useState("");
  const [confirmationText, setConfirmationText] = useState("");
  const [cancelText, setCancelText] = useState("Go Back");
  const [search, setSearch] = useState("");
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const token = getToken();
  const userId = getUserId();
  const makerCheckerPermission = getMakerCheckerPermission();
  const permission = usePermission("/view-offers");
  const IdRef = useRef("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gameData = useSelector((store) => store?.OfferReducer?.allGames);
  console.log(gameData, "gameName_all");
  const filterCount = Array.from(searchParams.keys()).length;
  const data = useSelector((store) => store?.OfferReducer);
  // testing

  const totalTab = [
    { name: "All Offers", totalCount: offers?.allOfferCount },
    { name: "Pending", totalCount: offers?.pendingOfferCount },
    { name: "Denied", totalCount: offers?.denyOfferCount },
  ];

  const getAllOffers = async () => {
    setLoading(true);
    const body = {
      page: offers?.paginationModel.page + 1,
      itemsPerPage: offers?.paginationModel.pageSize,
      ...(search && { search }),
      ...selectedFilters,
    };

    if (dateFilter?.select) {
      body["day"] = dateFilter?.select;
    }

    if (dateFilter?.select === "CUSTOM") {
      let startDate = formatDateNew(dateFilter?.from_time ?? "");
      let endDate = formatDateNew(dateFilter?.to_time ?? "");
      body["dateStart"] = startDate;
      body["dateEnd"] = endDate;
    }

    if (activeTab === "Pending") {
      body.offerApprovalStatus = "PENDING";
    } else if (activeTab === "Denied") {
      body.offerApprovalStatus = "DENIED";
    } else {
      body.offerApprovalStatus = "APPROVED";
    }

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(`${baseUrl}/api/offers`, body, {
        headers,
      });
      // console.log(response.data);
      let formateData = response?.data?.data?.map((e) => {
        // console.log(activeTab, "activeTab");
        return {
          id: e.id,
          alternativeId: e.id + Math.random(),
          name: e.offer_name,
          game_type: e.game_type,
          // reward_currency: e.reward_currency,
          offer_status: e.status,
          duration: e.duration + " Days",
          visibility: e?.visibility,
          offer_visibility: e?.visibility,
          offer_status: e.status,
          offer_created_by: e?.offer_created_by,
          created_by: e?.created_by,
          offer_created_date: e?.created_at,
          starts_at: e?.starts_at,
          ends_at: e?.ends_at,
          product_id: e?.product_codes,
          category_id: e?.category_codes,
          subcategory_id: e?.subcategory_codes,
          merchant_id: e?.merchant_codes,
          programId: e?.programId,
          action: {
            copyBtn: copyIcon,
            priority: e?.priority,
            ...(activeTab === "All Offers" && {
              editBtn:
                e.status === "ACTIVE" ? (
                  <img
                    style={{ width: "17px", height: "17px" }}
                    src={pause}
                    alt=""
                  />
                ) : (
                  <img
                    style={{ width: "17px", height: "17px" }}
                    src={play}
                    alt=""
                  />
                ),
            }),
          },
        };
      });
      setOffers((prev) => {
        return {
          ...prev,
          data: formateData ?? [],
          totalCount: response?.data?.offerCount ?? 0,
          allOfferCount: response?.data?.allOfferCount ?? 0,
          pendingOfferCount: response?.data?.pendingOfferCount ?? 0,
          denyOfferCount: response?.data?.denyOfferCount ?? 0,
        };
      });
      setStartDate(response.data.day_start_date ?? "");
      setEndDate(response.data.day_end_date ?? "");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onToggleColumnReset = () => {
    setFieldName(TData);
  };

  // Debounce utility function
  const debounce = (func, delay) => {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), delay);
    };
  };

  // Debounced handleVisibility function
  const handleVisibility = debounce((e, params) => {
    e.stopPropagation();
    console.log(params, "params");
    const id = params?.row?.id;
    if (!id) return;

    const filterData = offers.data.find((e) => e.id === id);
    const url = `${baseUrl}/api/admin/offer/${id}`;
    // console.log(params?.row?.offer_visibility, "params");
    axios
      .post(
        url,
        {
          visibility: params?.row?.offer_visibility === 0 ? 1 : 0,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOfferStatus((prev) => !prev);
        toast.success("offer visibility changed successfully");
      })
      .catch((err) => {
        console.log(
          "error while status updating",
          err?.response?.data?.message
        );
        toast.error(err?.response?.data?.message);
      });
  }, 500);

  function processRewardLimits(rewardLimits) {
    // Initialize the limits object with default values including isOpen properties
    const limitsObject = {
      user_level_isOpen: false,
      product_level_isOpen: false,
      user_level_limit: false,
      user_level_value: false,
      product_level_limit: false,
      product_level_value: false,
    };

    // Return default object if rewardLimits is not an array
    if (!Array.isArray(rewardLimits)) {
      return limitsObject;
    }

    // Process each limit in the array
    rewardLimits.forEach((limit) => {
      // Construct the key based on limit_level and entity_type
      const key = `${limit.limit_level}_level_${
        limit.entity_type === "reward_count" ? "limit" : "value"
      }`;

      // Set the corresponding flag to true
      limitsObject[key] = true;

      // Set the corresponding isOpen flag to true
      limitsObject[`${limit.limit_level}_level_isOpen`] = true;
    });

    return limitsObject;
  }

  const fetchFilters = async () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    // const token = getToken();

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Adjust content type as needed
    };
    try {
      const response = await axios.get(
        `${baseUrl}/api/filters?page=viewOffers`,
        {
          headers,
        }
      );
      if (response) {
        const config = response.data;
        let obj = {};
        const data = config.map((res) => {
          if (!obj.hasOwnProperty(res?.name)) {
            obj[res?.name] = [];
          }
          return { ...res, state: [] };
        });
        setFilters(response?.data);
        console.log(config, data, "filter");
        // setFilterOptions(obj);
        // setFilterConfig(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function handleStatusChangeByID(id) {
    if (!id) return;

    const filterData = offers.data.find((e) => e.id === id);
    console.log(filterData, "filterData");
    // toast.success("Status Changed Successfully");
    const url = `${baseUrl}/api/admin/offer/${id}`;
    axios
      .post(
        url,
        {
          status: filterData?.offer_status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOfferStatus((prev) => !prev);
        toast.success("offer status changed successfully");
      })
      .catch((err) => {
        console.log(
          "error while status updating",
          err?.response?.data?.message
        );
        toast.error(err?.response?.data?.message);
      });
  }

  function handleEdit(e, params) {
    if (!permission?.UPDATE) {
      // toast.error("You don't have permission");
      return;
    }
    e.stopPropagation();
    IdRef.current = params?.row?.id;
    // const filterData = offers?.data?.find((e) => e?.id === params?.id);
    const filterData = params?.row;
    // console.log(filterData.offer_status)
    if (permission?.UPDATE) {
      if (filterData?.offer_status === "ACTIVE") {
        setMessage("Are you sure you want to pause this offer?");
        setConfirmationText("Yes, Pause");
      } else {
        setMessage("Are you sure you want to activate this offer?");
        setConfirmationText("Yes, Activate");
      }
      setEditData(true);
    }
    // console.log(`delete ${params.id}`);
  }
  const exportToCSV = () => {
    if (offers.data.length === 0) {
      toast.error("No data to export");
      return;
    }

    // Generate headers dynamically, excluding action column and button columns
    const headers = Object.keys(offers.data[0]).filter(
      (key) =>
        key !== "action" &&
        key !== "copyBtn" &&
        key !== "deleteBtn" &&
        !key.includes("Btn") &&
        !key.includes("button")
    );

    // Create a mapping of field names to header names from columns definition
    const headerMapping = {};
    columns.forEach((column) => {
      headerMapping[column.field] = column.headerName;
    });

    // Use the exact header names from the table
    const displayHeaders = headers.map(
      (header) =>
        headerMapping[header] ||
        header
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
    );

    // Process data for CSV, properly handling commas and quotes
    const processValue = (value) => {
      if (value === null || value === undefined) return "NA";
      const stringValue = String(value);
      // If value contains comma, quote, or newline, wrap in quotes and escape existing quotes
      if (
        stringValue.includes(",") ||
        stringValue.includes('"') ||
        stringValue.includes("\n")
      ) {
        return `"${stringValue.replace(/"/g, '""')}"`;
      }
      return stringValue;
    };

    const csvData = offers.data.map((row) =>
      headers.map((header) => {
        if (header === "visibility") {
          return row[header] ? "Visible" : "Hidden";
        }
        return processValue(row[header]);
      })
    );

    const csvContent = [
      displayHeaders.map(processValue).join(","),
      ...csvData.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `offer_data_${new Date().toISOString().split("T")[0]}.csv`
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url); // Clean up to avoid memory leaks
    }
  };
  const handlePaginationModel = (value) => {
    if (!value) return;
    setOffers((prev) => ({ ...prev, paginationModel: value }));
  };
  console.log(userId, "userId");
  function transformRewardDetails(rewardDetails) {
    const { cashback_type } = rewardDetails;
    let result = {};

    // Only process first-level keys with string or number values
    for (let key in rewardDetails) {
      if (
        typeof rewardDetails[key] === "string" ||
        typeof rewardDetails[key] === "number"
      ) {
        // Add cashback_type?.id before the key if it's a string or number
        result[`${cashback_type?.id}_${key}`] = rewardDetails[key];
      }
    }

    return result;
  }

  const getOfferDataByID = async (id) => {
    const status =
      activeTab === "Pending"
        ? "PENDING"
        : activeTab === "Denied"
        ? "DENIED"
        : "";

    try {
      // Set up the query parameters
      const params = {};
      if (status) {
        params.offerApprovalStatus = status;
      }

      const res = await axios.get(`${baseUrl}/api/admin/offer/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params, // Add the params object to the request
      });
      const data = status ? res?.data?.updated_offer_details : res?.data;
      // const gameName = gameData.find(
      //   (game) => game?.id === data?.game?.game_type_id
      // )?.id;
      const gameName = data?.game?.game_type_code;
      // console.log(data?.game?.gameData, gameName,"gameName_details");
      console.log(res.data, "gameName");
      const offerDetailsObj = {
        product: data?.productId,
        merchant: data?.merchantId,
        title: data?.name,
        description: data?.description,
        startDate: data?.startsAt,
        endDate: data?.endsAt,
        lockingPeriod: data?.lockingPeriod,
        expiryPeriod: data?.expiryPeriod,
        termsAndConditions: data?.termsAndConditions,
        // programId: data?.programId,
        coverPhotoId: data?.coverImageId,
        coverImageUrl: data?.coverImageUrl,
        offerInitiative: data?.initiative,
        offerHistoryId: res?.data?.offer_history?.id ?? "null",
        activeTab,
      };

      const setQualifyingCriteriaObj = {
        linkToRule: data?.earnRuleSetId || null,
        linkToCohort: data?.collectionsIds,
        offerDiscovery: data?.allowImmediateDisbursal ? true : false,
        skipRule: data?.earnRuleSetId?.id ? false : true,
      };

      // const gameDataObj ={
      //   gameName,
      //   game_type_id:data?.game?.game_type_id
      // }

      const rewardData = data?.game?.rewards?.map((reward) => {
        return {
          id: reward?.id,
          limit: false,
          reward_type: reward?.reward_details?.reward_type ?? {},
          isOpen: true,
          ...transformRewardDetails(reward?.reward_details),
          win_probability: `${reward?.reward_details?.win_probability_factor}`,
          win_probability_percentage: reward?.win_probability,
          cashback_type: reward?.reward_details?.cashback_type ?? {},
          program_id: reward?.reward_details?.program_id,
          promo_id: reward?.reward_details?.promo_id ?? "",
          is_default_reward: reward?.reward_details?.is_default_reward,
          oe_offer_id: reward?.reward_details?.oe_offer_id ?? "",
          reward_limit:
            Array.isArray(reward?.rewardLimits) &&
            reward.rewardLimits.length > 0,
          ...processRewardLimits(reward?.rewardLimits),
          rewardLimits: reward?.rewardLimits || [],
        };
      });

      const gameDataName = getGameDataName(gameName);
      console.log(gameDataName, "gameDataName");
      const field = null;
      const value = null;
      const additionalFields = data?.game?.design?.before_play;
      const beforePlayPayload = {
        gameDataName,
        // field,
        // value,
        additionalFields,
      };

      const quizPayload = {
        gameDataName,
        // field,
        // value,
        additionalFields: data?.game?.details,
      };

      const afterPlayWithGameDataPayload = {
        gameDataName,
        // field,
        // value,
        additionalFields: data?.game?.design?.after_play,
      };

      console.log(afterPlayWithGameDataPayload, "quizPayload");
      dispatch({
        type: saveAfterPlayWithGameChanges,
        payload: afterPlayWithGameDataPayload,
      });
      dispatch({ type: setBeforePlayChange, payload: beforePlayPayload });
      dispatch({ type: setQuizChange, payload: quizPayload });

      dispatch({ type: setOfferDetailsDataChanges, payload: offerDetailsObj });
      dispatch({
        type: setQualifyingCriteriaDataChanges,
        payload: setQualifyingCriteriaObj,
      });
      dispatch({
        type: selectGameType,
        payload: {
          gameName,
          game_type_id: data?.game?.game_type_id,
          game_type_code: data?.game?.game_type_code,
          gameTypeName: gameName,
        },
      });
      dispatch({
        type: setAfterPlayReward,
        payload: {
          gameDataName,
          rewardArray: data?.game?.rewards?.map((reward) => {
            return {
              ...reward?.design_details?.after_play,
              isOpen: true,
            };
          }),
        },
      });
      dispatch({
        type: setBeforePlay2by2Reward,
        payload: {
          gameDataName,
          rewardArray: data?.game?.rewards?.map((reward) => {
            return {
              ...reward?.design_details?.before_play,
              isOpen: true,
            };
          }),
        },
      });
      dispatch({
        type: editReward,
        payload: {
          gameDataName: gameDataName,
          updatedRewardArray: rewardData,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleRowClick = (params) => {
    const id = params?.row?.id;
    // console.log(params?.row?.offer_created_by ,userId, "params");
    if (
      activeTab === "Pending" &&
      params?.row?.offer_created_by != userId &&
      makerCheckerPermission?.checker
    ) {
      getOfferDetailsByID(id);
      navigate("/offers/approve-offers/preview", {
        state: {
          offerId: "approve",
          id: id,
        },
      });
    } else {
      if (permission?.UPDATE) {
        getOfferDataByID(id);
        navigate(`/offers/edit-offers/offer-details/${id}`);
      } else {
        toast.error("You don't have permission");
      }
    }
  };

  const handleCopy = (e, params) => {
    e.stopPropagation();
    const id = params?.row?.id;
    if (permission?.CREATE) {
      getOfferDataByID(id);
      navigate("/offers/create-offers/offer-details");
    } else {
      toast.error("You don't have permission");
    }
  };

  const handlePreview = (e, params) => {
    if (!permission?.CREATE) {
      // toast.error("You don't have permission");
      return;
    }
    e.stopPropagation();
    const id = params?.row?.id;
    getOfferDataByID(id);
    navigate(`/offers/create-offers/preview`, {
      state: {
        offerId: "preview",
      },
    });
  };

  const getOfferDetailsByID = async (id) => {
    console.log(id, "abcd");
    try {
      const res = await axios.get(
        `${baseUrl}/api/admin/offer/${id}?offerApprovalStatus=PENDING`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // to save previous offer data
      dispatch({
        type: saveChangedOfferData,
        payload: res?.data?.previous_offer_details,
      });

      const offerDetails = res?.data?.updated_offer_details;
      // const gameName = gameData.find(
      //   (game) => game?.id === offerDetails?.game?.game_type_id
      // )?.id;
      const gameName = offerDetails?.game?.game_type_code;
      console.log(gameName, "gameName");
      const offerDetailsObj = {
        product: offerDetails?.productId,
        merchant: offerDetails?.merchantId,
        title: offerDetails?.name,
        description: offerDetails?.description,
        startDate: offerDetails?.startsAt,
        endDate: offerDetails?.endsAt,
        lockingPeriod: offerDetails?.lockingPeriod,
        expiryPeriod: offerDetails?.expiryPeriod,
        termsAndConditions: offerDetails?.termsAndConditions,
        // programId: offerDetails?.programId,
        coverPhotoId: offerDetails?.coverImageId,
        coverImageUrl: offerDetails?.coverImageUrl,
        offerInitiative: offerDetails?.initiative,
        offerHistoryId: res?.data?.offer_history?.id,
      };

      const setQualifyingCriteriaObj = {
        linkToRule: offerDetails?.earnRuleSetId || null,
        linkToCohort: offerDetails?.collectionsIds,
        offerDiscovery: offerDetails?.allowImmediateDisbursal ? true : false,
        skipRule: offerDetails?.earnRuleSetId?.id ? false : true,
      };

      const rewardData = offerDetails?.game?.rewards?.map((reward) => {
        return {
          id: reward?.id,
          limit: false,
          reward_type: reward?.reward_details?.reward_type ?? {},
          isOpen: true,
          win_probability: `${reward?.reward_details?.win_probability_factor}`,
          win_probability_percentage: reward?.win_probability,
          cashback_type: reward?.reward_details?.cashback_type ?? {},
          program_id: reward?.reward_details?.program_id,
          promo_id: reward?.reward_details?.promo_id ?? "",
          is_default_reward: reward?.reward_details?.is_default_reward,
          oe_offer_id: reward?.reward_details?.oe_offer_id ?? "",
          reward_limit:
            Array.isArray(reward?.rewardLimits) &&
            reward.rewardLimits.length > 0,
          ...processRewardLimits(reward?.rewardLimits),
          rewardLimits: reward?.rewardLimits,
          ...transformRewardDetails(reward?.reward_details),
        };
      });

      const gameDataName = getGameDataName(gameName);

      const field = null;
      const value = null;
      const additionalFields = offerDetails?.game?.design?.before_play;
      const beforePlayPayload = {
        gameDataName,
        // field,
        // value,
        additionalFields,
      };

      const quizPayload = {
        gameDataName,
        // field,
        // value,
        additionalFields: offerDetails?.game?.details,
      };

      const afterPlayWithGameDataPayload = {
        gameDataName,
        // field,
        // value,
        additionalFields: offerDetails?.game?.design?.after_play,
      };

      console.log(afterPlayWithGameDataPayload, "quizPayload");
      dispatch({
        type: saveAfterPlayWithGameChanges,
        payload: afterPlayWithGameDataPayload,
      });
      dispatch({ type: setBeforePlayChange, payload: beforePlayPayload });
      dispatch({ type: setQuizChange, payload: quizPayload });

      dispatch({ type: setOfferDetailsDataChanges, payload: offerDetailsObj });
      dispatch({
        type: setQualifyingCriteriaDataChanges,
        payload: setQualifyingCriteriaObj,
      });
      dispatch({
        type: selectGameType,
        payload: {
          gameName,
          game_type_id: offerDetails?.game?.game_type_id,
          game_type_code: offerDetails?.game?.game_type_code,
          gameTypeName: gameName,
        },
      });
      dispatch({
        type: setAfterPlayReward,
        payload: {
          gameDataName,
          rewardArray: offerDetails?.game?.rewards?.map((reward) => {
            return {
              ...reward?.design_details?.after_play,
              isOpen: true,
            };
          }),
        },
      });
      dispatch({
        type: setBeforePlay2by2Reward,
        payload: {
          gameDataName,
          rewardArray: offerDetails?.game?.rewards?.map((reward) => {
            return {
              ...reward?.design_details?.before_play,
              isOpen: true,
            };
          }),
        },
      });
      dispatch({
        type: editReward,
        payload: {
          gameDataName: gameDataName,
          updatedRewardArray: rewardData,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDateFilter = (name, value) => {
    setDateFilter((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (value === "ALL_TIME") {
      setDateFilter((prev) => ({
        ...prev,
        from_time: { unset: true },
        to_time: { unset: true },
      }));
    }

    if (value === "CUSTOM") {
      setDateFilter((prev) => ({
        ...prev,
        from_time: { unset: true },
        to_time: { unset: true },
      }));
    }
  };

  const handleToggleColumn = (i) => {
    // console.log(i, "index");
    const data = fieldsName.map((e, index) => {
      if (index === i) {
        e.flag = !e.flag;
      }
      return e;
    });
    // console.log(data, "inside handleToggleColumn");
    setFieldName(data);
  };
  const columns = [
    {
      field: "id",
      headerName: "Offer ID",
      minWidth: 50,
      // flex: 0.5,
      renderCell: (params) => {
        if (!params?.value) {
          return <NotApplicable />;
        }
        return (
          <Box
            paddingRight="auto"
            // marginLeft="10px"
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "Offer Name",
      // sortable: true,
      // sortComparator: (v1, v2) => {
      //   // Convert to lowercase to ensure case-insensitive sorting
      //   const a = (v1 || '').toString().toLowerCase();
      //   const b = (v2 || '').toString().toLowerCase();

      //   // Compare strings
      //   return a.localeCompare(b);
      // },
      // flex: 1.4,
      minWidth: 150,
      renderCell: (params) => {
        if (!params?.value) {
          return <NotApplicable />;
        }

        return (
          <BootstrapTooltip title={params.value}>
            <Typography
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              color="#043A87"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Typography>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "game_type",
      headerName: "Game Type",
      minWidth: 100,
      // flex: 0.9,
      renderCell: (params) => {
        if (!params?.value) {
          return <NotApplicable />;
        }

        return (
          <BootstrapTooltip title={params.value}>
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "reward_currency",
      headerName: "Reward Currency",
      minWidth: 120,
      // flex: 0.8,
      renderCell: (params) => {
        if (!params?.value) {
          return <NotApplicable />;
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "offer_status",
      headerName: "Offer Status",
      minWidth: 100,
      // flex: 0.7,
      renderCell: (params) => {
        if (!params?.value) {
          return <NotApplicable />;
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "duration",
      headerName: "Duration",
      minWidth: 100,
      // flex: 0.6,
      renderCell: (params) => {
        if (!params?.value) {
          return <NotApplicable />;
        }

        return (
          <Box
            fontFamily="Lato"
            fontWeight={400}
            fontSize="12px"
            lineHeight="14.4px"
            letterSpacing="2%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace={"nowrap"}
          >
            {params?.value}
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 150,
      // flex: 1,
      headerAlign: "center",
      align: "center",
      marginLeft: "100px",
      renderCell: (params) => {
        const statusValue = offers?.data?.filter((e) => e.id === params.id)[0]
          ?.offer_status;
        return (
          <Box sx={{ display: "flex", gap: "10px" }}>
            <BootstrapTooltip
              title={
                !permission?.CREATE ? "You don't have permission" : "Copy Offer"
              }
            >
              <Box
                // sx={{
                //   width: "100%",
                //   height: "100%",
                //   display: "grid",
                //   placeItems: "center",
                // }}
                onClick={(e) => handleCopy(e, params)}
              >
                <img
                  style={{ height: "18px", width: "18px" }}
                  src={copy}
                  alt="copy"
                />
              </Box>
            </BootstrapTooltip>

            <BootstrapTooltip    title={
                !permission?.CREATE ? "You don't have permission" : "Preview"
              }>
              <Box
                // sx={{
                //   width: "100%",
                //   height: "100%",
                //   display: "grid",
                //   placeItems: "center",
                // }}
                onClick={(e) => handlePreview(e, params)}
              >
                <img
                  src={preview}
                  style={{ height: "18px", height: "18px" }}
                  alt=""
                />
                {/* <MdPreview
                  style={{
                    height: "24px",
                    width: "24px",
                    // color: permission?.UPDATE ? "black" : "gray",
                    color: "black",
                  }}
                /> */}
              </Box>
            </BootstrapTooltip>

            <BootstrapTooltip
              title={
                !permission?.UPDATE
                  ? "You don't have permission"
                  : statusValue === "ACTIVE"
                  ? "Pause"
                  : "Play"
              }
            >
              <Box
                // sx={{
                //   width: "100%",
                //   height: "100%",
                //   display: "grid",
                //   placeItems: "center",
                // }}
                onClick={(e) => handleEdit(e, params)}
              >
                {params?.value?.editBtn}
                {/* <img
              onClick={(e) => handleEdit(e, params)}
              style={{ height: "24px", width: "24px" }}
              src={params?.value}
              alt="delete"
            /> */}
              </Box>
            </BootstrapTooltip>
            {/* <BootstrapTooltip title={"priority"}>
              {params?.value?.priority ? (
                <img
                  style={{ height: "18px", width: "18px" }}
                  src={priority_more_then_one}
                  alt="priority more then one"
                />
              ) : (
                <img   style={{ height: "18px", width: "18px" }} src={priority_zero} alt="priority zero" />
              )}
              
            </BootstrapTooltip> */}
          </Box>
        );
      },
    },
    {
      field: "category_id",
      headerName: "Category",
      minWidth: 100,
      // flex: 0.6,
      renderCell: (params) => {
        if (!params?.value) {
          return <NotApplicable />;
        }

        return (
          <BootstrapTooltip title={params?.value}>
            <Box
              fontFamily="ABeeZee"
              fontWeight={400}
              fontSize="13px"
              lineHeight="15.37px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "subcategory_id",
      headerName: "Subcategory",
      minWidth: 100,
      // flex: 0.6,
      renderCell: (params) => {
        if (!params?.value) {
          return <NotApplicable />;
        }

        return (
          <BootstrapTooltip title={params?.value}>
            <Box
              fontFamily="ABeeZee"
              fontWeight={400}
              fontSize="13px"
              lineHeight="15.37px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "product_id",
      headerName: "Product",
      minWidth: 100,
      // flex: 0.6,
      renderCell: (params) => {
        if (!params?.value) {
          return <NotApplicable />;
        }

        return (
          <BootstrapTooltip title={params?.value}>
            <Box
              fontFamily="ABeeZee"
              fontWeight={400}
              fontSize="13px"
              lineHeight="15.37px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "merchant_id",
      headerName: "Merchant",
      minWidth: 100,
      // flex: 0.6,
      renderCell: (params) => {
        if (!params?.value) {
          return <NotApplicable />;
        }

        return (
          <BootstrapTooltip title={params?.value}>
            <Box
              fontFamily="ABeeZee"
              fontWeight={400}
              fontSize="13px"
              lineHeight="15.37px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={"nowrap"}
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "created_by",
      headerName: "Created By",
      minWidth: 150,
      renderCell: (params) => {
        if (!params?.value) {
          return <NotApplicable />;
        }
        return (
          <BootstrapTooltip title={params?.value}>
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "offer_created_date",
      headerName: "Created Date",
      minWidth: 150,
      renderCell: (params) => {
        if (!params?.value) {
          return <NotApplicable />;
        }
        return (
          <BootstrapTooltip title={params?.value}>
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "starts_at",
      headerName: "Start Date",
      minWidth: 150,
      renderCell: (params) => {
        if (!params?.value) {
          return <NotApplicable />;
        }
        return (
          <BootstrapTooltip title={params?.value}>
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "ends_at",
      headerName: "End Date",
      minWidth: 150,
      renderCell: (params) => {
        if (!params?.value) {
          return <NotApplicable />;
        }
        return (
          <BootstrapTooltip title={params?.value}>
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "programId",
      headerName: "Program Id",
      minWidth: 100,
      renderCell: (params) => {
        if (!params?.value) {
          return <NotApplicable />;
        }
        return (
          <BootstrapTooltip title={params?.value}>
            <Box
              fontFamily="Lato"
              fontWeight={400}
              fontSize="12px"
              lineHeight="14.4px"
              letterSpacing="2%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {params?.value}
            </Box>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "visibility",
      headerName: "Visibility",
      minWidth: 50,
      // flex: 0.5,
      renderCell: (params) => {
        // if (!params?.value) {
        //   return "NA";
        // }

        return (
          <Box
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <CustomSwitch
              checked={params?.value === 1}
              onChange={(e) => handleVisibility(e, params)}
            />
          </Box>
        );
      },
    },
  ];

  // console.log(offers, "offers");
  useEffect(() => {
    let debounceTimer;
    // if (filters?.length > 0) {
    if (flag) {
      setFlag(false);
    } else {
      debounceTimer = setTimeout(() => {
        getAllOffers();
      }, 800);
    }
    // }

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [search]);

  // useEffect(()=>{
  //   setOffers((prev) => {
  //     return {
  //       ...prev,
  //       paginationModel:{
  //         ...prev.paginationModel,
  //         page: 0,
  //       }
  //     };
  //   });
  // },[activeTab])

  useEffect(() => {
    // let debounceTimer;
    if (dateFilter.select !== "CUSTOM") {
      // if (flag) {
      //   setFlag(false);
      // } else {
      // debounceTimer = setTimeout(() => {
      getAllOffers();
      // }, 0);
      // }
    }

    // return () => {
    //   clearTimeout(debounceTimer);
    // };
  }, [
    offerStatus,
    offers?.paginationModel?.page,
    offers?.paginationModel?.pageSize,
    dateFilter?.select,
    activeTab,
    filterDone,
    filterRemoveFlag,
    // filters,
    // search
  ]);

  useEffect(() => {
    if (dateFilter.select === "CUSTOM") {
      if (
        typeof dateFilter?.from_time === "string" &&
        typeof dateFilter?.to_time === "string" &&
        dateFilter.from_time &&
        dateFilter.to_time
      ) {
        console.log(
          "inside custom date filter",
          dateFilter?.to_time,
          dateFilter?.from_time
        );
        getAllOffers();
      }
    }
  }, [
    dateFilter,
    activeTab,
    filterDone,
    filterRemoveFlag,
    offers?.paginationModel?.page,
    offers?.paginationModel?.pageSize,
  ]);

  useEffect(() => {
    if (startDate && endDate) {
      setDateFilter((prev) => ({
        ...prev,
        from_time: dayjs(new Date(startDate)),
        to_time: dayjs(new Date(endDate)),
      }));
    }
  }, [startDate, endDate]);

  useEffect(() => {
    // Step 1: Filter TData columns where flag is true
    let filteredTData = fieldsName?.filter((column) => column.flag === true);

    // Step 2: Filter columns where field matches key from filteredTData
    const filteredColumns = columns.filter((column) =>
      filteredTData.some(
        (filteredColumn) => filteredColumn.key === column.field
      )
    );

    // Step 3: Add the action object at the end
    const actionColumn = columns.find((column) => column.field === "action");
    if (actionColumn) {
      filteredColumns.push(actionColumn);
    } else {
      filteredColumns.push({ field: "action", headerName: "Action" });
    }

    setColumnFields(filteredColumns);
  }, [fieldsName]);

  useEffect(() => {
    const initialFilters = {};
    filters.forEach((filter) => {
      const paramValue = searchParams.get(filter.name);
      if (paramValue) {
        initialFilters[filter.name] = paramValue.split(",");
      } else {
        initialFilters[filter.name] = [];
      }
    });

    // Remove keys with empty arrays
    const filterData = Object.fromEntries(
      Object.entries(initialFilters).filter(([_, value]) => value.length > 0)
    );
    setSelectedFilters(filterData);
  }, [searchParams, filterRemoveFlag]);

  useEffect(() => {
    fetchFilters();
    // to remove the search params from the url on page load
    setSearchParams({});
  }, []);

  console.log(columnFields, "columnFields");
  return (
    <Box sx={{ padding: "20px" }}>
      <ToastContainer />
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: "16px" }}>
        {/* <SearchBar
          value={search}
          setState={setSearch}
          placeholder={"Search here..."}
        /> */}
        <Box>
          <Button
            sx={{
              height: "30px",
              width: "106px",
              backgroundColor: "#E8F0F9",
            }}
            onClick={exportToCSV}
          >
            <img
              style={{ height: "16px", width: "16px" }}
              src={exportImg}
              alt=""
            />
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "14.4px",
                marginLeft: "6px",
                textTransform: "capitalize",
              }}
            >
              Export Data
            </Typography>
          </Button>

          {permission?.CREATE && (
            <Button
              sx={{
                width: "136px",
                height: "30px",
                backgroundColor: "#003D86",
                borderRadius: "6px",
                marginLeft: "12px",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#003D86",
                },
              }}
              onClick={() => navigate("/offers/create-offers/offer-details")}
            >
              <img
                style={{ height: "16px", width: "16px" }}
                src={plus}
                alt=""
              />
              <Typography
                sx={{
                  fontFamily: "Lato",
                  fontSize: "12px",
                  lineHeight: "14.4px",
                  fontWeight: 500,
                  marginLeft: "6px",
                  textTransform: "capitalize",
                }}
              >
                Create New Offer
              </Typography>
            </Button>
          )}
        </Box>
      </Box>
      <FilterAndSearch
        search={search}
        setSearch={setSearch}
        tableFields={fieldsName}
        handleToggleColumn={handleToggleColumn}
        columnDisableIndex={1}
        // filterCount={3}
        filterCount={filterCount}
        dateFilter={dateFilter}
        filterSelectOptions={filterSelectOptions}
        handleDateFilter={handleDateFilter}
        filterData={filters}
        setFilterDone={setFilterDone}
        setFilterRemoveFlag={setFilterRemoveFlag}
        onToggleColumnReset={onToggleColumnReset}
      />
      {/* Table */}
      <Box
        sx={{ display: "flex", gap: "10px", justifyContent: "space-between" }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
          }}
        >
          {totalTab.map((tab, index) => (
            <TableTotalCount
              key={index}
              totalCount={tab.totalCount}
              text={tab.name}
              index={tab.name}
              activeTab={activeTab}
              handleChangeTab={() => setActiveTab(tab.name)}
            />
          ))}
        </Box>
        {/* <Box>
          <ToggleColumn
            tableFields={fieldsName}
            handleToggleColumn={handleToggleColumn}
            disabledIndex={1}
          />
        </Box> */}
      </Box>
      <Box>
        <UiTable
          row={offers?.data || []}
          columns={columnFields || []}
          totalCount={offers?.totalCount}
          paginationModel={offers?.paginationModel}
          setPaginationModel={handlePaginationModel}
          handleRowClick={handleRowClick}
          loading={loading}
          alternaviteIdName={"alternativeId"}
        />
        <CustomConfirmationDialog
          open={editData}
          setOpen={setEditData}
          deleteById={handleStatusChangeByID}
          prop={IdRef.current}
          status="delete"
          message={message}
          confirmationText={confirmationText}
          cancelText={cancelText}
        />
      </Box>
    </Box>
  );
}
