import { Box, Typography } from "@mui/material";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import React from "react";
import Loader from "./Loader";
const CustomNoRowsOverlay = () => {
  return (
    <GridOverlay>
      <Typography variant="body1" fontWeight="bold">
        No Data Found
      </Typography>
    </GridOverlay>
  );
};
const LoadingOverlay = () => {
  return (
    <GridOverlay>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
        }}
      >
        <Loader />
      </Box>
    </GridOverlay>
  );
};
const UiTable = ({
  paginationModel = { page: 0, pageSize: 10 },
  handleRowClick = () => {},
  setPaginationModel = () => {},
  totalCount = 0,
  columns = [],
  row = [],
  loading = false,
  alternaviteIdName,
}) => {
  return (
    <Box
      sx={{
        height: "619px",
        // maxHeight: "700px",
        maxWidth: "80vw",
        "& .MuiDataGrid-root": {
          border: "1px solid transparent",
          // border: "none",
        },
        "& .MuiDataGrid-cell": {
          // borderBottom: "1px solid black",
          // padding: "4px 8px",
          backgroundColor: "inherit",
          borderBottom: "none !important",
        },
        "& .MuiDataGrid-row": {
          "&:nth-of-type(even)": {
            backgroundColor: "white",
          },
          "&:nth-of-type(odd)": {
            backgroundColor: "#F9F9FC",
          },
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#E4E4F0",
          color: "#1D1F2C",
          borderBottom: "none",
          maxHeight: "38px !important",
          minHeight: "38px !important",
          fontFamily: "Lato",
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "14.4px",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: "white",
        },
        "& .MuiDataGrid-footerContainer": {
          backgroundColor: "white",
          color: "purple",
          borderTop: "1px solid transparent",
          borderRadius: "0 0 10px 10px",
          maxHeight: "38px !important",
          minHeight: "38px !important",
        },
        "& .MuiTablePagination-selectLabel": {
          fontFamily: "Lato !important",
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "14.4px",
        },
        "& .MuiTablePagination-displayedRows": {
          fontFamily: "Lato !important",
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "14.4px",
          margin: 0, // Remove default margin
          display: "flex",
          alignItems: "center", // Center text vertically
        },
        "& .MuiDataGrid-selectedRowCount": {
          fontFamily: "Lato !important",
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "14.4px",
        },
        "& .MuiSelect-select": {
          fontFamily: "Lato !important",
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "14.4px",
          mt: "3px",
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `black !important`,
        },
        "& .MuiDataGrid-sortIcon": {
          color: "black",
          backgroundColor: "white",
        },
        "& .MuiDataGrid-footerContainer": {
          backgroundColor: "white",
          color: "purple",
          borderTop: "1px solid transparent",
          borderRadius: "0 0 10px 10px",
          maxHeight: "38px !important",
          minHeight: "38px !important",
          display: "flex",
          alignItems: "center", // Add this to center items vertically
          marginBottom: "5px",
        },
        "& .MuiTablePagination-root": {
          display: "flex",
          alignItems: "center", // Center pagination elements
        },
        "& .MuiTablePagination-toolbar": {
          display: "flex",
          alignItems: "center", // Center toolbar items
          minHeight: "38px !important",
          height: "38px !important",
        },
        "& .MuiTablePagination-selectLabel": {
          fontFamily: "Lato !important",
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "14.4px",
          margin: 0, // Remove default margin
          display: "flex",
          alignItems: "center", // Center text vertically
        },
        // "& .MuiList-padding":{
        //   padding: "0px !important",
        //   paddingBottom: "0px !important",
        //   paddingTop: "0px",
        // },
        paddingBottom: "40px",
      }}
    >
      <DataGrid
        getRowId={(row) => row[alternaviteIdName] || row?.id}
        rows={row || []}
        columns={columns}
        sx={{ cursor: "pointer" }}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[15, 30, 50]}
        rowCount={Math.ceil(totalCount)}
        onRowClick={handleRowClick}
        disableColumnMenu={true}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
          LoadingOverlay: LoadingOverlay,
        }}
        loading={loading}
        rowHeight={36} // Reduces row height
        headerHeight={30} // Reduces header height
        // localeText={{
        //   MuiTablePagination: {
        //     labelRowsPerPage: 'Showing', // Custom text for "Rows per page"
        //   },
        // }}
      />
    </Box>
  );
};
export default UiTable;
