import { Box , Typography } from '@mui/material';
import React, { useState } from 'react'
import { AndroidMockup } from 'react-device-mockup';
import { useSelector } from 'react-redux';
import { getGameDataName } from '../../../../../../utils/gameDataName';
import previous_image from "../../../../../../assets/previous_image.svg";
import transparent_background from "../../../../../../assets/transparent_background.svg";
import { useLocation } from "react-router-dom";

export default function BeforePlayPreviewSC() {
  const data = useSelector((store) => store.OfferReducer.offerData);
  const gameDataName = getGameDataName(data?.createGame?.game_type_code);
  const [previewIndex, setPreviewIndex] = useState(0);
  const { pathname } = useLocation();
  const isPreviewOfferPage = pathname.includes("preview");
  const OfferName = data?.offerDetails?.title;
  const gameName = data?.createGame?.gameTypeName?.split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ")
    .trim();
    const productName = data?.offerDetails?.product?.name;
    
    // Format date as "DD MMM YYYY"
    const formatDate = (dateString) => {
      if (!dateString) return "";
      const date = new Date(dateString);
      const day = date.getDate();
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.getFullYear();
      return `${day} ${month} ${year}`;
    };
    
    const formattedDate = formatDate(data?.offerDetails?.endDate);
  console.log(formattedDate, "OfferName")
  return (
    <Box
    sx={
      isPreviewOfferPage ? 
      {width: "33%",
      background: "#E8F0F9",
      minHeight: "calc(100vh - 100px)",
      borderRadius: "0px 6px 0px 0px",
      position: "absolute",
      top: "0px",
      right: "0px",
      display: "flex", // Added display: flex
      justifyContent: "center", // Center horizontally
      alignItems: "center", // Center vertically
      }
      :{
      width: "calc(100% - (72% + 10px))",
      background: "#E8F0F9",
      height: "calc(100vh - 100px)",
      borderRadius: "0px 6px 0px 0px",
      position: "fixed",
      top: "68px",
      right: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflowY: "auto",
    }}
  >
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column", // Changed to column layout
        alignItems: "center",
        gap: "15px",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: "700",
          lineHeight: "20px",
          color: "#1D1F2C",
          mb: "20px",
        }}
      >
        Preview
      </Typography>

      <Box
        sx={{ display: "flex", alignItems: "center", gap: "15px" }}
      >
        <Box>
          <img
            src={previous_image}
            alt="previous_image"
            style={{
              cursor: "pointer",
              height: "32px",
              width: "32px",
            }}
            onClick={() => {
              if (previewIndex === 0) {
                setPreviewIndex(1);
              } else {
                setPreviewIndex(0);
              }
            }}
          />
        </Box>

        <Box>
          {previewIndex === 0 ? (
            <AndroidMockup
              screenWidth={220}
              frameColor="#0D0D0F"
              statusbarColor="#0D0D0F"
            >
              <Box
                sx={{
                  height: "100%",
                  // backgroundColor: "#212121CC",
                  backgroundImage: `url(${transparent_background})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  // opacity:"80%",
                  width: "100%",
                }}
              >
                <Box mt="120px">
                  <Box sx={{ position: "relative" }}>
                    <img
                      style={{ width: "160px", height: "160px" }}
                      src={
                        data?.createGame?.beforePlay?.[
                          gameDataName
                        ]?.scartch_card_locked_image
                      }
                      alt=""
                    />
              
                  </Box>

                  <Box
                    sx={{
                      height: "27px",
                      marginTop: "15px",
                      paddingX: "45px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Proxima Nova A",
                        fontSize: "8.97px",
                        fontWeight: "400",
                        lineHeight: "13.45px",
                        color: "#FFFFFF",
                        textAlign: "center",
                      }}
                    >
                    {OfferName ? OfferName : "Scratch the card to win an assured reward"}  
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    margin: "9px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "5px",
                    mt: "60px",
                    marginLeft: "12px",
                    // justifyContent:"center",
                  }}
                >
                  <Box
                    sx={{
                      border: "0.5px solid #B2B2B2",
                      width: "75px",
                    }}
                  ></Box>
                  <Typography
                    sx={{
                      fontFamily: "Proxima Nova A",
                      fontSize: "6.73px",
                      lineHeight: "8.97px",
                      color: "#E5E5E5",
                    }}
                  >
                    Earned via
                  </Typography>
                  <Box
                    sx={{
                      border: "0.5px solid #B2B2B2",
                      width: "75px",
                    }}
                  ></Box>
                </Box>

                <Box
                  sx={{
                    margin: "5px 9px",
                    height: "35px",
                    backgroundColor: "#FFFFFF1F ",
                    borderRadius: "7px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Proxima Nova A",
                      fontSize: "8.97px",
                      fontWeight: "600",
                      lineHeight: "13.45px",
                      color: "#FFFFFF",
                      textAlign: "center",
                    }}
                  >
                    {gameName ? gameName : "Scratch Card"}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Proxima Nova A",
                      fontSize: 6.73,
                      fontWeight: "400",
                      lineHeight: "8.97px",
                      textAlign: "center",

                      color: "#E5E5E5",
                    }}
                  >
                    {formattedDate ? formattedDate : ""} · {productName ? productName : "Product"}
                  </Typography>
                </Box>
              </Box>
            </AndroidMockup>
          ) : (
           <AndroidMockup
              screenWidth={220}
              frameColor="#0D0D0F"
              statusbarColor="#0D0D0F"
            >
              <Box
                sx={{
                  height: "100%",
                  // backgroundColor: "#212121CC",
                  backgroundImage: `url(${transparent_background})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  // opacity:"80%",
                  width: "100%",
                }}
              >
                <Box mt="120px">
                  <Box sx={{ position: "relative" }}>
                    <img
                      style={{ width: "160px", height: "160px" }}
                      src={
                        data?.createGame?.beforePlay?.[
                          gameDataName
                        ]?.scartch_card_unlocked_image
                      }
                      alt=""
                    />
                  </Box>

                  <Box
                    sx={{
                      height: "27px",
                      marginTop: "15px",
                      paddingX: "45px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Proxima Nova A",
                        fontSize: "8.97px",
                        fontWeight: "400",
                        lineHeight: "13.45px",
                        color: "#FFFFFF",
                        textAlign: "center",
                      }}
                    >
                      Scratch the card to win an assured reward
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    margin: "9px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "5px",
                    mt: "60px",
                    marginLeft: "12px",
                    // justifyContent:"center",
                  }}
                >
                  <Box
                    sx={{
                      border: "0.5px solid #B2B2B2",
                      width: "75px",
                    }}
                  ></Box>
                  <Typography
                    sx={{
                      fontFamily: "Proxima Nova A",
                      fontSize: "6.73px",
                      lineHeight: "8.97px",
                      color: "#E5E5E5",
                    }}
                  >
                    Earned via
                  </Typography>
                  <Box
                    sx={{
                      border: "0.5px solid #B2B2B2",
                      width: "75px",
                    }}
                  ></Box>
                </Box>

                <Box
                  sx={{
                    margin: "5px 9px",
                    height: "35px",
                    backgroundColor: "#FFFFFF1F ",
                    borderRadius: "7px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Proxima Nova A",
                      fontSize: "8.97px",
                      fontWeight: "600",
                      lineHeight: "13.45px",
                      color: "#FFFFFF",
                      textAlign: "center",
                    }}
                  >
                    {gameName ? gameName : "Scratch Card"}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Proxima Nova A",
                      fontSize: 6.73,
                      fontWeight: "400",
                      lineHeight: "8.97px",
                      textAlign: "center",

                      color: "#E5E5E5",
                    }}
                  >
                    {formattedDate ? formattedDate : ""} · {productName ? productName : "Product"}
                  </Typography>
                </Box>
              </Box>
            </AndroidMockup>
          )}
        </Box>

        <Box>
          <img
            src={previous_image}
            alt="previous_image"
            style={{
              cursor: "pointer",
              height: "32px",
              width: "32px",
              rotate: "180deg",
            }}
            onClick={() => {
              if (previewIndex === 0) {
                setPreviewIndex(1);
              } else {
                setPreviewIndex(0);
              }
            }}
          />
        </Box>
      </Box>

      <Typography
        sx={{
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: "700",
          lineHeight: "20px",
          color: "#1D1F2C",
          mt: "20px",
        }}
      >{`(${previewIndex + 1}/2)`}</Typography>
    </Box>
  </Box>
  )
}
