import React from "react";
import { useSelector } from "react-redux";
import { getGameDataName } from "../../../../../../utils/gameDataName";
import { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { AndroidMockup } from "react-device-mockup";
import previous_image from "../../../../../../assets/previous_image.svg";
import { IoIosArrowBack } from "react-icons/io";
import { useLocation } from "react-router-dom";
import transparent_background from "../../../../../../assets/transparent_background.svg";
import { RxCross2 } from "react-icons/rx";
import NeuCoins from "../commonScreen/NeuCoins";

export default function AfterPlayPreviewNeuCoinsSTW({ openRewardIndex }) {
  const data = useSelector((store) => store.OfferReducer.offerData);
  const gameDataName = getGameDataName(data?.createGame?.game_type_code);
  const [previewIndex, setPreviewIndex] = useState(0);
  const { pathname } = useLocation();
  const isPreviewOfferPage = pathname.includes("preview");

  const banner_image =
    data?.createGame?.afterPlay?.spinTheWheelData?.[openRewardIndex]
      ?.banner_image || "";

  const reward_image =
    data?.createGame?.afterPlay?.spinTheWheelData?.[openRewardIndex]
      ?.logo_image || "";

  console.log(data, "data");

  return (
    <Box
      sx={
        isPreviewOfferPage
          ? {
              width: "33%",
              background: "#E8F0F9",
              minHeight: "calc(100vh - 100px)",
              borderRadius: "0px 6px 0px 0px",
              position: "absolute",
              top: "0px",
              right: "0px",
              display: "flex", // Added display: flex
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
            }
          : {
              width: "calc(100% - (72% + 10px))",
              background: "#E8F0F9",
              height: "calc(100vh - 100px)",
              borderRadius: "0px 6px 0px 0px",
              position: "fixed",
              top: "68px",
              right: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflowY: "auto",
            }
      }
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column", // Changed to column layout
          alignItems: "center",
          gap: "15px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            color: "#1D1F2C",
            mb: "20px",
          }}
        >
          Preview
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
          {/* <Box>
            <img
              src={previous_image}
              alt="previous_image"
              style={{
                cursor: "pointer",
                height: "32px",
                width: "32px",
              }}
              onClick={() => {
                if (previewIndex === 0) {
                  setPreviewIndex(1);
                } else {
                  setPreviewIndex(0);
                }
              }}
            />
          </Box> */}

          <Box>
            {/* {
              previewIndex === 0 ? */}

            <AndroidMockup
              screenWidth={220}
              frameColor="#0D0D0F"
              statusbarColor="#0D0D0F"
            >
              <Box
                sx={{
                  height: "100%",
                  backgroundImage: `url(${transparent_background})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "12px 12px 0px 0px",
                    marginTop: "227px",
                    paddingTop: "8px",
                    paddingX: "8px",
                    height: "calc(100% - 267px)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      py: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#212126",
                        fontFeatureSettings: "'liga' off, 'clig' off",
                        fontFamily: "Proxima Nova A",
                        fontSize: "10.931px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "15.304px",
                      }}
                    >
                      Spin the wheel to win!
                    </Typography>
                    <RxCross2 />
                  </Box>

                  <Box
                    sx={{
                      marginTop: "12px",
                      marginBottom: "13px",
                    }}
                  >
                    <img
                      style={{ height: "45px", width: "100%" }}
                      src={banner_image}
                      alt="Banner Image"
                    />
                  </Box>

                  <Typography
                    sx={{
                      color: "#474747",
                      textAlign: "center",
                      fontFeatureSettings: "'liga' off, 'clig' off",
                      fontFamily: "Proxima Nova A",
                      fontSize: "7.652px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "10.931px",
                    }}
                  >
                    You have won
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <img
                      style={{
                        height: "16px",
                        width: "16px",
                      }}
                      src={reward_image}
                      alt=""
                    />

                    <Typography
                      sx={{
                        textAlign: "center",
                        fontFeatureSettings: "'liga' off, 'clig' off",
                        fontFamily: "Proxima Nova A",
                        fontSize: "13.117px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "17.49px",
                        background:
                          "linear-gradient(90deg, #4294F7 0%, #8A1AF5 33%, #EA3394 66%, #F19C38 100%)",
                        backgroundClip: "text",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      100 Neucoins
                    </Typography>
                  </Box>

                  <Button
                    sx={{
                      py: "5px",
                      px: "13px",
                      borderRadius: "4.372px",
                      backgroundColor: "#8800EC",
                      color: "#FFF",
                      textAlign: "center",
                      fontFeatureSettings: "'liga' off, 'clig' off",
                      fontFamily: "Proxima Nova A",
                      fontSize: "8.745px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "13.117px",
                      width: "100%",
                      marginTop: "13px",
                      "&:hover": {
                        backgroundColor: "#8800EC",
                      },
                    }}
                    onClick={()=>setPreviewIndex(1)}
                  >
                    Submit
                  </Button>
                </Box>
                <Box
                  sx={{
                    pt: "10px",
                    pb: "13px",
                    backgroundColor: "white",
                    borderTop: "1px solid #dfdfe0",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#474747",
                      fontFeatureSettings: "'liga' off, 'clig' off",
                      fontFamily: "Proxima Nova A",
                      fontSize: "7.652px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "10.931px",
                      textDecorationStyle: "solid",
                      textDecorationSkipInk: "none",
                      textUnderlineOffset: "auto",
                      textUnderlinePosition: "from-font",
                    }}
                  >
                    I agree to the{" "}
                    <span
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      Terms & Conditions
                    </span>
                  </Typography>
                </Box>
              </Box>
            </AndroidMockup>
            :
            {/* <AndroidMockup
            screenWidth={220}
            frameColor="#0D0D0F"
            statusbarColor="#0D0D0F"
          >
         <NeuCoins data={data} openRewardIndex={openRewardIndex} gameDataName={gameDataName} />
          </AndroidMockup>
            } */}
          </Box>

          {/* <Box>
            <img
              src={previous_image}
              alt="previous_image"
              style={{
                cursor: "pointer",
                height: "32px",
                width: "32px",
                rotate: "180deg",
              }}
              onClick={() => {
                if (previewIndex === 0) {
                  setPreviewIndex(1);
                } else {
                  setPreviewIndex(0);
                }
              }}
            />
          </Box> */}
        </Box>

        {/* <Typography
          sx={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            color: "#1D1F2C",
            mt: "20px",
          }}
        >{`(${previewIndex + 1}/2)`}
        </Typography> */}
      </Box>
    </Box>
  );
}
