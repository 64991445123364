import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

export const PrivateRoute = ({ children }) => {
    // Get authentication state from MSAL
    const isAuthenticated = useIsAuthenticated();
    // Also check Redux store for backward compatibility
    const isAuthRedux = useSelector(store => store.AuthReducer.token);
    
    // User is authenticated if either MSAL or Redux indicates they are
    const isAuth = isAuthenticated || isAuthRedux;
    
    if (!isAuth) {
        return <Navigate to="/login" />;
    }
    
    return children;
};