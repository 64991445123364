import React from "react";
import { getGameDataName } from "../../../../../../utils/gameDataName";
import { useSelector } from "react-redux";
import AfterPlayPreviewDigitalGoldSC from "./AfterPlayPreviewDigitalGoldSC";
import AfterPlayPreviewNueCoinsSC from "./AfterPlayPreviewNueCoinsSC";
import AfterPlayPreviewPromoNeuCoinsSC from "./AfterPlayPreviewPromoNeuCoinsSC";
import AfterPlayPreviewVoucherSC from "./AfterPlayPreviewVoucherSC";
import AfterPlayPreviewBLNT_SC from "./AfterPlayPreviewBLNT_SC";

export default function AfterPlayPreviewSC({ openRewardIndex }) {
  const data = useSelector((store) => store.OfferReducer.offerData);
  const gameDataName = getGameDataName(data?.createGame?.game_type_code);
  const isDigitalGold =
    data?.createGame?.afterPlay?.[gameDataName]?.[openRewardIndex]?.reward_type
      ?.id === "digital_gold";

  const isNeuCoins =
    data?.createGame?.afterPlay?.[gameDataName]?.[openRewardIndex]?.reward_type
      ?.id === "neu_coin";

  const isCashback =
    data?.createGame?.afterPlay?.[gameDataName]?.[openRewardIndex]?.reward_type
      ?.id === "cashback";

  const isPromoNeuCoins =
    data?.createGame?.afterPlay?.[gameDataName]?.[openRewardIndex]?.reward_type
      ?.id === "promo_neu_coin";

  const isVoucher =
    data?.createGame?.afterPlay?.[gameDataName]?.[openRewardIndex]?.reward_type
      ?.id === "voucher";
      
      const isBetterLuckNextTime =
      data?.createGame?.afterPlay?.[gameDataName]?.[openRewardIndex]?.reward_type
        ?.id === "bl_next_time";

  return (
    <div>
      {isDigitalGold && (
        <AfterPlayPreviewDigitalGoldSC openRewardIndex={openRewardIndex} />
      )}
      {isNeuCoins && (
        <AfterPlayPreviewNueCoinsSC openRewardIndex={openRewardIndex} />
      )}
      {isPromoNeuCoins && (
        <AfterPlayPreviewPromoNeuCoinsSC openRewardIndex={openRewardIndex} />
      )}
      {isVoucher && (
        <AfterPlayPreviewVoucherSC openRewardIndex={openRewardIndex} />
      )}
      {isBetterLuckNextTime && (
        <AfterPlayPreviewBLNT_SC openRewardIndex={openRewardIndex} />
      )}
    </div>
  );
}
