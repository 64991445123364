export function getGameDataName(game_type_code) {
    switch (game_type_code) {
      case "spin_the_wheel":
        return "spinTheWheelData";
      case "slot_machine":
        return "slotMachineData";
      case "scratch_card":
        return "scratchCardData";
      case "trivia":
        return "triviaData";
      default:
        return "";
    }
  }
  