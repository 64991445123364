import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CreateGame_PreData } from "../../../../utils/CreateGame_PreData";
import { useDispatch, useSelector } from "react-redux";
import {
  setAfterPlayChange,
  setAfterPlayReward,
  setDrawerImageOption,
} from "../../../../Redux/OfferReducer/actionType";
import RightDrawer from "./RightDrawer";
import BoldLabel from "../../../../UIComponents/BoldLevel";
import MuiSelectAndSearch from "../../../../UIComponents/MuiSelectAndSearch";
import MuiInput from "../../../../UIComponents/MuiInput";
import ColorInput from "../../../../UIComponents/ColorInput";
import axios from "axios";
import uploadIcon from "../../../../assets/UploadIcon.svg";
import AfterPlayDrawer from "./AfterPlayDrawer";
import arrowUp from "../../../../assets/arrow-up.png";
import arrowDown from "../../../../assets/arrow-down.png";
import savedIcon from "../../../../assets/saved.svg";
import { useLocation } from "react-router-dom";
import MuiTextarea from "../../../../UIComponents/MuiTextarea";
import { getGameDataName } from "../../../../utils/gameDataName";
import { reward, rewards } from "./Data";
import RichEditor from "../../../../UIComponents/RichEditor";



const afterFixedData = (reward, isSpinTheWheel = false) => {
  const baseData = {
    // title: reward?.name?.toUpperCase(),
    title: "Congrats",
    scratch_card_image:
      "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png",
    scratch_card_image_upload_id: 1587,
    after_play_details_title: "Congratulations",
    description: "You have won 100",
    go_back_cta_text: "Go Back",
    logo_image: "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
  };
  if(isSpinTheWheel){
    baseData.banner_image = "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
  }

  if (reward?.id == "neu_coin") {
    baseData.start_shopping_cta_text = "Start Shopping";
    baseData.start_shopping_link = "https://www.admin-gratify.esmagico.net/";
  } else if (reward?.id == "digital_gold") {
    baseData.start_investing_cta_text = "Start Investing";
    baseData.start_investing_link = "https://www.admin-gratify.esmagico.net/";
  } else if (reward?.id == "promo_neu_coin") {
    baseData.after_play_details_description =
      "You have won up to ₹1000 Voucher on BB Now transaction.";
    baseData.subtitle = "subtitle";
    baseData.how_to_redeem =
      "<p>Congrats! You have won up to ₹200 cashback on booking of executive suits<br> <br>1. When you book, use the code in the 'Coupons' section. <br>2. Second point information goes here, which explains the next step. <br>3. Cashback will be received in your Tata Neu account within 15 days.</p>";
    baseData.logo_image_1 =
      "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png";
    baseData.logo_image_2 =
      "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png";
      baseData.redeem_now_cta_text = "Redeem Now";
      baseData.redeem_now_link = "https://www.admin-gratify.esmagico.net/";
      baseData.start_shopping_cta_text = "Start Shopping";
      baseData.start_shopping_link = "https://www.admin-gratify.esmagico.net/";
  } else if (reward?.id == "voucher") {
    baseData.after_play_details_description =
      "You have won up to ₹1000 Voucher on BB Now transaction.";
    baseData.how_to_redeem =
      "<strong>How to redeem</strong><p></p><p>Congrats! You have won up to ₹1000 cashback on booking of executive suits</p><ol><li>When your while booking, use the code in 'Coupons' section.</li><li>Second point informations goes here which tells about next step</li><li> Cashback will be received in your tata neu account within 15 days.</li></ol>";
    baseData.tnc =
      "<strong>Terms and conditions</strong><p></p><p>Congrats! You have won up to ₹1000 cashback on bbnow transaction.</p><ol><li>Expires on June 13, 2024</li><li>Get 5% off worth up to 7100 cashback on select shopping and food brand gift card purchase on Google Pay.</li><li> Eligible brands - Amazon Pay, Flipkart, Myntra, Nykaa, Swiggy, Zomato, Domino and Big basket.</li><li>No code needed. Just tap 'Buy now' and your voucher will be automatically applied when you pay.</li><li>Only valid for payments made with Tata Neu</li></ol>";
    baseData.logo_image =
      "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png";
    baseData.brand_name = "BB Now";
    baseData.click_action = "https://sit.tataneu.com/tataRewards/myRewardsPage";
    baseData.title = "Rs 1000 Voucher";
    baseData.subtitle = "subtitle";
    baseData.get_code_cta_text = "Get Code";
    baseData.redeem_now_cta_text = "Redeem Now";
    baseData.redeem_now_link = "https://www.admin-gratify.esmagico.net/";
  } else if (reward?.id == "bl_next_time") {
    baseData.banner_image =
      "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png";
  }
  else {
    // baseData.logo_image =
    //   "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png";
    // baseData.logo_image_upload_id= 1581;
  }
  return baseData;
};

const isFieldDisabled =
  process.env.REACT_APP_DISABLE_BEFORE_AND_AFTER_PLAY_EDIT === "true";

export default function AfterPlayPage({
  showEdit = isFieldDisabled,
  openRewardIndex,
  setOpenRewardIndex,
  validationObject,
  errorMessageObject,
  disableSaveButton,
}) {
  const [loading, setLoading] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const token = useSelector((store) => store.AuthReducer.token);
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const handleDrawerOpen = (event, backend_level, options) => {
    event.stopPropagation();
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const allReward = useSelector(
    (store) => store?.OfferReducer?.allRewards
  )?.rewardTypes;

  // const allReward = reward?.rewardTypes;
  console.log(allReward,"allreward")

  const data = useSelector(
    (store) => store?.OfferReducer?.offerData?.createGame
  );

  const changedData = useSelector(
    (store) => store.OfferReducer?.changedOfferData?.game?.rewards
  );
  console.log(data);

  const isSpinTheWheel = data?.game_type_code === "spin_the_wheel";
  function compareHexCodes(hex1 = "", hex2 = "") {
    // Normalize hex codes by converting to lowercase and expanding shorthand format if necessary
    const normalizeHex = (hex) => {
      // Remove the '#' symbol if present
      hex = hex.replace("#", "");

      // Convert shorthand hex code (e.g., #ABC) to full form (e.g., #AABBCC)
      if (hex.length === 3) {
        hex = hex
          .split("")
          ?.map((char) => char + char)
          .join("");
      }

      // Return the hex code in lowercase for consistent comparison
      return hex.toLowerCase();
    };

    return normalizeHex(hex1) === normalizeHex(hex2);
  }

  const gameDataName = getGameDataName(data?.game_type_code);

  const rewards = data?.game?.[gameDataName]?.map((item) => {
    return {
      reward_type: item.reward_type,
    };
  });

  const handleAfterPlayChange = (
    field,
    value,
    rewardIndex,
    additionalFields = {},
    isMultiple = false
  ) => {
    // uncomment this code it we need to add multiple images at a time
    // if (false) {
    //   // Handle toggle logic for multiple values with max length 2
    //   let currentValue =
    //     data?.afterPlay?.[gameDataName]?.[rewardIndex]?.[field];

    //   // Ensure currentValue is an array
    //   let updatedValue = Array.isArray(currentValue) ? [...currentValue] : [];

    //   if (updatedValue.includes(value)) {
    //     // Remove the value if it already exists
    //     updatedValue = updatedValue.filter((item) => item !== value);
    //   } else {
    //     // Add the value if it doesn't exist
    //     if (updatedValue.length === 2) {
    //       // If array length is 2, remove the first element
    //       updatedValue.shift();
    //     }
    //     updatedValue.push(value);
    //   }

    //   const payload = {
    //     gameDataName,
    //     field,
    //     value: updatedValue,
    //     additionalFields: {
    //       ...additionalFields,
    //     },
    //     rewardIndex,
    //   };

    //   dispatch({ type: setAfterPlayChange, payload });
    // } else

    if (field.endsWith("_upload")) {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", value);
      axios
        .post(`${BaseUrl}/api/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const baseField = field.replace("_upload", "");
          const payload = {
            gameDataName,
            field,
            value,
            additionalFields: {
              ...additionalFields,
              [`${baseField}_upload_url`]: response.data.public_url,
              [`${baseField}_upload_id`]: response.data.id,
              [baseField]: response.data.public_url,
            },
            rewardIndex,
          };
          dispatch({ type: setAfterPlayChange, payload });
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (field === "isOpen") {
      const newOpenRewardIndex = value ? rewardIndex : null;
      setOpenRewardIndex(newOpenRewardIndex);

      // Close all rewards
      const closeAllPayload = {
        gameDataName,
        field: "isOpen",
        value: false,
        additionalFields: {},
        rewardIndex: -1, // This will close all rewards
      };
      dispatch({ type: setAfterPlayChange, payload: closeAllPayload });

      // Open the clicked reward if it wasn't already open
      if (newOpenRewardIndex !== null) {
        const openPayload = {
          gameDataName,
          field: "isOpen",
          value: true,
          additionalFields: {},
          rewardIndex: newOpenRewardIndex,
        };
        dispatch({ type: setAfterPlayChange, payload: openPayload });
      }
    } else {
      // Existing logic for other fields
      const payload = {
        gameDataName,
        field,
        value,
        additionalFields,
        rewardIndex,
      };
      dispatch({ type: setAfterPlayChange, payload });
    }
  };
  const handleSaveReward = (rewardIndex) => {
    // Save the reward
    dispatch({
      type: setAfterPlayChange,
      payload: {
        gameDataName,
        field: "isSaved",
        value: true,
        additionalFields: {},
        rewardIndex,
      },
    });

    // Close the reward
    setOpenRewardIndex(null);
    dispatch({
      type: setAfterPlayChange,
      payload: {
        gameDataName,
        field: "isOpen",
        value: false,
        additionalFields: {},
        rewardIndex,
      },
    });
  };

  useEffect(() => {
    const mergeRewards = (existingRewards, newRewards) => {
      return newRewards?.map((newReward, index) => {
        // Check if there's an existing reward at the same index AND it's the same reward type
        const existingReward = existingRewards?.[index];
        const isMatchingReward =
          existingReward?.reward_type?.id === newReward?.reward_type?.id;

        if (existingReward && isMatchingReward) {
          // If reward exists at this index AND is the same type, preserve its data
          return {
            ...existingReward,
            isOpen: index === 0,
            isSaved: existingReward.isSaved || false,
          };
        } else {
          // If it's a new reward or different type at this index, initialize with default values
          return {
            ...newReward,
            isOpen: index === 0,
            isSaved: false,
            ...afterFixedData(
              newReward?.reward_type,
              isSpinTheWheel
            ),
          };
        }
      });
    };

    const existingRewards = data?.afterPlay?.[gameDataName] || [];
    const updatedRewards = mergeRewards(existingRewards, rewards);

    dispatch({
      type: setAfterPlayReward,
      payload: {
        gameDataName,
        rewardArray: updatedRewards,
      },
    });

    // Set the first reward as open
    setOpenRewardIndex(0);
  }, [rewards?.length]);

  return (
    <Box>
      <AfterPlayDrawer
        loading={loading}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
        handleSelectImage={handleAfterPlayChange}
        openRewardIndex={openRewardIndex}
      />
      <Box>
        {data?.afterPlay?.[gameDataName]?.map((uniqueReward, rewardIndex) => {
          const matchingReward = allReward?.find(
            (reward) =>
              reward?.reward_type_code === uniqueReward?.reward_type?.id
          );

          validationObject[rewardIndex] = validationObject[rewardIndex] || {};
          if (!matchingReward) return null;
          let num = 1;
          return (
            <Box
              key={rewardIndex}
              sx={{
                marginBottom: "20px",
                border: "1px solid #E6E6E6",
                paddingX: "14px",
                borderRadius: "8px",
                pb: rewardIndex === openRewardIndex ? "10px" : "0px",
                // height: uniqueReward?.isOpen ? "auto" : "15px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "36px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleAfterPlayChange(
                    "isOpen",
                    rewardIndex !== openRewardIndex,
                    rewardIndex,
                    {}
                  )
                }
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "12px" }}
                >
                  {uniqueReward?.isSaved && <img src={savedIcon} alt="" />}
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#1D1F2C",
                      // marginBottom: "10px",
                    }}
                  >
                    {uniqueReward.reward_type.name}
                  </Typography>
                </Box>
                <Button
                  sx={{
                    cursor: "pointer",
                    minWidth: "0px",
                  }}
                  // onClick={() =>
                  //   handleAfterPlayChange(
                  //     "isOpen",
                  //     rewardIndex !== openRewardIndex,
                  //     rewardIndex,
                  //     {}
                  //   )
                  // }
                >
                  {rewardIndex === openRewardIndex ? (
                    <img
                      style={{ width: "16px", height: "16px" }}
                      src={arrowDown}
                      alt=""
                    />
                  ) : (
                    <img
                      style={{ width: "16px", height: "16px" }}
                      src={arrowUp}
                      alt=""
                    />
                  )}
                </Button>
              </Box>

              <Box
                sx={{
                  display: rewardIndex === openRewardIndex ? "block" : "none",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontWeight: 700,
                    color: "#003D86",
                    marginBottom: "10px",
                    my: "16px",
                    textAlign: "left",
                  }}
                >
                  Design
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "start",
                    gap: "20px",
                  }}
                >
                  {console.log(matchingReward,"matchingReward")}
                  {matchingReward?.design_details[data?.game_type_code]
                    ?.after_play?.length > 0 &&
                    matchingReward?.design_details[
                      data?.game_type_code
                    ]?.after_play?.map((field, index) => {
                      if (!field?.mandatory) return null;
                      const labelNum = num++;
                      if (field?.backend_label != "after_play_details") {
                        validationObject[rewardIndex][field?.backend_label] = {
                          mandatory: field?.mandatory,
                        };

                        if (
                          field?.backend_label?.endsWith("color") ||
                          field?.backend_label?.endsWith("colour")
                        ) {
                          // Check if the value doesn't already exist
                          if (
                            !data?.afterPlay?.[gameDataName]?.[rewardIndex]?.[
                              field?.backend_label
                            ]
                          ) {
                            handleAfterPlayChange(
                              field?.backend_label,
                              "#000000",
                              rewardIndex,
                              {}
                            );
                          }
                        }
                      }

                      return (
                        <Box
                          key={field?.backend_label}
                          sx={{
                            width: field?.style?.width
                              ? field?.style?.width
                              : "calc(96% + 20px)",
                            borderTop:
                              field?.input_type == "json"
                                ? "1px solid #E4E4F0"
                                : "none",
                            paddingTop:
                              field?.input_type == "json" ? "16px" : "0px",
                          }}
                        >
                          {field?.input_type === "json" ? (
                            <Typography
                              sx={{
                                fontFamily: "Lato",
                                fontSize: "14px",
                                fontWeight: 700,
                                color: "#003D86",
                                marginBottom: "10px",
                                // my: "16px",
                                textAlign: "left",
                              }}
                            >
                              {field?.label}
                            </Typography>
                          ) : (
                            <BoldLabel
                              label={`${field?.label}`}
                              mt="0px"
                              isRequired={field?.mandatory}
                            />
                          )}

                          {field?.input_type === "dropdown" ? (
                            <Box>
                              <MuiSelectAndSearch
                                isDisable={showEdit}
                                options={field?.options}
                                value={
                                  data?.afterPlay?.[gameDataName]?.[
                                    rewardIndex
                                  ]?.[field?.backend_label] || ""
                                }
                                onChange={(event) =>
                                  handleAfterPlayChange(
                                    field.backend_label,
                                    event,
                                    rewardIndex,
                                    {}
                                  )
                                }
                                editBoolean={
                                  changedData?.[rewardIndex]?.design_details
                                    ?.after_play?.[field?.backend_label] &&
                                  changedData?.[rewardIndex]?.design_details
                                    ?.after_play?.[field?.backend_label] !==
                                    data?.afterPlay?.[gameDataName]?.[
                                      rewardIndex
                                    ]?.[field?.backend_label]
                                }
                                placeholder="--Select--"
                                errorMessage={
                                  errorMessageObject?.[rewardIndex]?.[
                                    field?.backend_label
                                  ]
                                }
                              />
                            </Box>
                          ) : field?.input_type === "input_box" ? (
                            <Box>
                              <MuiInput
                                maxLength={field?.max_characters}
                                disable={showEdit}
                                value={
                                  data?.afterPlay?.[gameDataName]?.[
                                    rewardIndex
                                  ]?.[field?.backend_label] || ""
                                }
                                placeholder={field?.placeholder}
                                onChange={(event) =>
                                  handleAfterPlayChange(
                                    field.backend_label,
                                    event,
                                    rewardIndex,
                                    {}
                                  )
                                }
                                editBoolean={
                                  changedData?.[rewardIndex]?.design_details
                                    ?.after_play?.[field?.backend_label] &&
                                  changedData?.[rewardIndex]?.design_details
                                    ?.after_play?.[field?.backend_label] !==
                                    data?.afterPlay?.[gameDataName]?.[
                                      rewardIndex
                                    ]?.[field?.backend_label]
                                }
                                errorMessage={
                                  errorMessageObject?.[rewardIndex]?.[
                                    field?.backend_label
                                  ]
                                }
                              />
                            </Box>
                          ) : field?.input_type === "color_picker" ? (
                            <Box>
                              <ColorInput
                                preFill={field?.default}
                                // disable={showEdit}
                                disable={true}
                                value={
                                  data?.afterPlay?.[gameDataName]?.[
                                    rewardIndex
                                  ]?.[field?.backend_label] || field?.default
                                }
                                onChange={(color) =>
                                  handleAfterPlayChange(
                                    field?.backend_label,
                                    color,
                                    rewardIndex,
                                    {}
                                  )
                                }
                                editBoolean={
                                  changedData?.[rewardIndex]?.design_details
                                    ?.after_play?.[field?.backend_label] &&
                                  !compareHexCodes(
                                    changedData?.[rewardIndex]?.design_details
                                      ?.after_play?.[field?.backend_label],
                                    data?.afterPlay?.[gameDataName]?.[
                                      rewardIndex
                                    ]?.[field?.backend_label]
                                  )
                                  // changedData?.[rewardIndex]?.design_details?.after_play?.[field?.backend_label] !== undefined &&
                                  // changedData?.[rewardIndex]?.design_details?.after_play?.[field?.backend_label]?.toLowerCase() !==
                                  // data?.afterPlay?.[gameDataName]?.[
                                  //   rewardIndex
                                  // ]?.[field?.backend_label]?.toLowerCase()
                                }
                                errorMessage={
                                  errorMessageObject?.[rewardIndex]?.[
                                    field?.backend_label
                                  ]
                                }
                              />
                            </Box>
                          ) : field?.input_type === "image_upload" ? (
                            <Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "start",
                                  gap: "15px",
                                  mt: "10px",
                                }}
                              >
                                <Box
                                  sx={{
                                    borderRadius: "8px",
                                    cursor: "pointer",
                                    padding: "14px 15px",
                                    height: "80px",
                                    backgroundColor: "#F9F9FC",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    gap: "15px",
                                    width: "100%",
                                    border:
                                      changedData?.[rewardIndex]?.design_details
                                        ?.after_play?.[field?.backend_label] &&
                                      changedData?.[rewardIndex]?.design_details
                                        ?.after_play?.[field?.backend_label] !==
                                        data?.afterPlay?.[gameDataName]?.[
                                          rewardIndex
                                        ]?.[field?.backend_label]
                                        ? "1px solid orange"
                                        : errorMessageObject?.[rewardIndex]?.[
                                            field?.backend_label
                                          ]
                                        ? "1px solid red"
                                        : "none",
                                  }}
                                  onClick={(event) => {
                                    if (showEdit) return;
                                    handleDrawerOpen(event);
                                    dispatch({
                                      type: setDrawerImageOption,
                                      payload: {
                                        rewardIndex: rewardIndex,
                                        backend_label: field?.backend_label,
                                        image_option: field?.options,
                                        gameDataName: gameDataName,
                                        game_type: "afterPlay",
                                        action_type: setAfterPlayChange,
                                      },
                                    });
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: data?.afterPlay?.[
                                        gameDataName
                                      ]?.[rewardIndex]?.[field?.backend_label]
                                        ? "flex"
                                        : "none",
                                      gap: "8px",
                                    }}
                                  >
                                    {/* {Array.isArray(
                                        data?.afterPlay?.[gameDataName]?.[
                                          rewardIndex
                                        ]?.[field?.backend_label]
                                      ) ? (
                                        data?.afterPlay?.[gameDataName]?.[
                                          rewardIndex
                                        ]?.[field?.backend_label]?.map(
                                          (image, index) => (
                                            <img
                                              key={index} // Always include a key when mapping
                                              style={{
                                                borderRadius: "6px",
                                                height: "65px",
                                                width: "65px",
                                              }}
                                              src={image}
                                              alt=""
                                            />
                                          )
                                        )
                                      ) : ( */}
                                    <img
                                      style={{
                                        borderRadius: "6px",
                                        height: "65px",
                                        width: "65px",
                                      }}
                                      src={
                                        data?.afterPlay?.[gameDataName]?.[
                                          rewardIndex
                                        ]?.[field?.backend_label]
                                      }
                                      alt=""
                                    />
                                    {/* )} */}

                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "flex-start",
                                        gap: 1,
                                      }}
                                    >
                                      <Typography
                                        // onClick={handleSelectImageClick}
                                        sx={{
                                          cursor: "pointer",
                                          fontSize: "12px",
                                          fontWeight: 600,
                                          fontFamily: "Lato",
                                          lineHeight: "14.4px",
                                          color: "#29CC6A",
                                          textDecoration: "underline",
                                          display: pathname.includes("preview")
                                            ? "none"
                                            : "block",
                                        }}
                                      >
                                        Change
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontFamily: "Lato",
                                          fontSize: "10px",
                                          fontWeight: 400,
                                          lineHeight: "12px",
                                          color: "#7E8794",
                                          textAlign: "left",
                                        }}
                                      >
                                        {data?.afterPlay?.[gameDataName]?.[
                                          rewardIndex
                                        ]?.[field?.backend_label]
                                          ? "Image Uploaded"
                                          : "No image selected"}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: data?.afterPlay?.[
                                        gameDataName
                                      ]?.[rewardIndex]?.[field?.backend_label]
                                        ? "none"
                                        : "flex",
                                      alignItems: "center",
                                      gap: "15px",
                                    }}
                                  >
                                    <img
                                      style={{
                                        width: "12px",
                                        height: "12px",
                                      }}
                                      src={uploadIcon}
                                      alt=""
                                    />
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "start",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        gap: "8px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          textAlign: "start",
                                          fontFamily: "Lato",
                                          fontSize: "12px",
                                          fontStyle: "normal",
                                          fontWeight: 600,
                                          lineHeight: "14.4px",
                                          color: "#003D86",
                                        }}
                                      >
                                        Upload from computer{" "}
                                        <span style={{ color: "#B5BBC5" }}>
                                          |
                                        </span>{" "}
                                        Paste Image URL
                                      </Typography>
                                      <Typography
                                        sx={{
                                          textAlign: "start",
                                          color: "#7E8794",
                                          fontFamily: "Lato",
                                          fontSize: "10px",
                                          fontStyle: "normal",
                                          fontWeight: 400,
                                          lineHeight: "12px",
                                        }}
                                      >
                                        Maximum 2MB. Upload .jpeg or .png,
                                        files. Aspect Ratio 1:1
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "8px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        textAlign: "start",
                                        fontFamily: "Lato",
                                        fontSize: "12px",
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        lineHeight: "14.4px",
                                        color: "#003D86",
                                        textDecoration: "underline",
                                        display: pathname.includes("preview")
                                          ? "none"
                                          : "block",
                                      }}
                                    >
                                      Or select from presets
                                    </Typography>
                                    <Box
                                      sx={{
                                        position: "relative",
                                        width: "80px",
                                        height: "30px",
                                      }}
                                    >
                                      {field?.options?.length > 0 &&
                                        field?.options
                                          ?.slice(0, 3)
                                          ?.map((image, index) => (
                                            <img
                                              key={index}
                                              src={image?.url}
                                              alt=""
                                              style={{
                                                width: "30px",
                                                height: "30px",
                                                borderRadius: "4px",
                                                position: "absolute",
                                                left: `${index * 20}px`,
                                                zIndex: index,
                                              }}
                                            />
                                          ))}
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                              <Box>
                                {errorMessageObject?.[rewardIndex]?.[
                                  field?.backend_label
                                ] && (
                                  <Typography
                                    sx={{
                                      color: "#FF4242",
                                      fontSize: "10px",
                                      fontFamily: "Lato",
                                      marginTop: "4px",
                                      textAlign: "start",
                                    }}
                                  >
                                    {
                                      errorMessageObject?.[rewardIndex]?.[
                                        field?.backend_label
                                      ]
                                    }
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          ) : field?.input_type === "json" ? (
                            <Box>
                              {field?.fields?.map((element, elementIndex) => {
                                validationObject[rewardIndex][
                                  element?.backend_label
                                ] = {
                                  mandatory: element?.mandatory,
                                };
                                return (
                                  <Box
                                    key={elementIndex}
                                    sx={{
                                      width: element?.style?.width
                                        ? element?.style?.width
                                        : "calc(96% + 20px)",
                                    }}
                                  >
                                    <BoldLabel
                                      label={element?.label}
                                      isRequired={element?.mandatory}
                                    />
                                    {element?.input_type === "input_box" ? (
                                      <Box
                                        sx={{
                                          mb: errorMessageObject?.[
                                            rewardIndex
                                          ]?.[element?.backend_label]
                                            ? "10px"
                                            : "0px",
                                        }}
                                      >
                                        <MuiInput
                                          maxLength={element?.max_characters}
                                          disable={showEdit}
                                          placeholder={element?.placeholder}
                                          value={
                                            data?.afterPlay?.[gameDataName]?.[
                                              rewardIndex
                                            ]?.[element?.backend_label] || ""
                                          }
                                          onChange={(event) =>
                                            handleAfterPlayChange(
                                              element?.backend_label,
                                              event,
                                              rewardIndex,
                                              {}
                                            )
                                          }
                                          editBoolean={
                                            changedData?.[rewardIndex]
                                              ?.design_details?.after_play?.[
                                              element?.backend_label
                                            ] &&
                                            changedData?.[rewardIndex]
                                              ?.design_details?.after_play?.[
                                              element?.backend_label
                                            ] !==
                                              data?.afterPlay?.[gameDataName]?.[
                                                rewardIndex
                                              ]?.[element?.backend_label]
                                          }
                                          errorMessage={
                                            errorMessageObject?.[rewardIndex]?.[
                                              element?.backend_label
                                            ]
                                          }
                                        />
                                      </Box>
                                    ) : element?.input_type === "text_area" ? (
                                      <Box
                                        sx={{
                                          mb: errorMessageObject?.[
                                            rewardIndex
                                          ]?.[element?.backend_label]
                                            ? "10px"
                                            : "0px",
                                        }}
                                      >
                                        <RichEditor
                                          isDisable={showEdit}
                                          value={
                                            data?.afterPlay?.[gameDataName]?.[
                                              rewardIndex
                                            ]?.[element?.backend_label] || ""
                                          }
                                          onChange={(value) =>
                                            handleAfterPlayChange(
                                              element?.backend_label,
                                              value,
                                              rewardIndex,
                                              {}
                                            )
                                          }
                                          editBoolean={
                                            changedData?.[rewardIndex]
                                              ?.design_details?.after_play?.[
                                              element?.backend_label
                                            ] &&
                                            changedData?.[rewardIndex]
                                              ?.design_details?.after_play?.[
                                              element?.backend_label
                                            ] !==
                                              data?.afterPlay?.[gameDataName]?.[
                                                rewardIndex
                                              ]?.[element?.backend_label]
                                          }
                                          errorMessage={
                                            errorMessageObject?.[rewardIndex]?.[
                                              element?.backend_label
                                            ]
                                          }
                                        />
                                      </Box>
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                );
                              })}
                            </Box>
                          ) : null}
                        </Box>
                      );
                    })}
                </Box>
                <Box
                  mt="16px"
                  sx={{
                    display: disableSaveButton ? "none" : "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Button
                    sx={{
                      width: "136px",
                      height: "24px",
                      borderRadius: "6px",
                      backgroundColor: "#003D86",
                      fontFamily: "Lato",
                      fontSize: "12px",
                      fontWeight: "500",
                      color: "#FFFFFF",
                      lineHeight: "14.4px",
                      "&:hover": {
                        backgroundColor: "#003D86",
                      },
                    }}
                    onClick={() => handleSaveReward(rewardIndex)}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
