import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { IoIosArrowBack } from "react-icons/io";

export default function DigitalGold({ data, gameDataName, openRewardIndex }) {
  console.log(data, "data");
  return (
    <Box
      sx={{
        height: "100%",
        backgroundColor: "#EBF0FF7A",
        width: "100%",
      }}
    >
      <Box
        sx={{
          height: "32px",
          backgroundColor: "#FDFDFD",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: "10px",
        }}
      >
        <IoIosArrowBack
          style={{
            marginLeft: "10px",
            height: "10px",
            width: "12px",
            color: "#1A1A1A",
          }}
        />
        <Typography
          sx={{
            fontFamily: "Proxima Nova A",
            fontSize: "8.969px",
            fontWeight: "700",
            lineHeight: "13.454px",
            color: "#1A1A1A",
            textAlign: "start",
          }}
        >
          Reward Details
        </Typography>
      </Box>
      <Box mt="9px">
        <Box sx={{ position: "relative" }}>
          <img
            style={{
              width: "165px",
              height: "165px",
              borderRadius: "12px",
            }}
            src={
              data?.createGame?.afterPlay?.[gameDataName]?.[
                openRewardIndex ? openRewardIndex : 0
              ]?.scratch_card_image
            }
            alt=""
          />
          <Box
            sx={{
              position: "absolute",
              top: "60%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img
              style={{
                width: "40px",
                height: "40px",
              }}
              src={
                data?.createGame?.afterPlay?.[gameDataName]?.[
                  openRewardIndex ? openRewardIndex : 0
                ]?.logo_image
              }
              alt=""
            />
            {/* <Typography
              sx={{
                mt: "5px",
                color: "#000000",
                fontFamily: "Proxima Nova A",
                fontSize: "6.727px",
                fontWeight: "600",
                lineHeight: "8.969px",
                letterSpacing: "1.121px",
                height: "8.969px",
                textTransform: "uppercase",
              }}
            >
              {data?.createGame?.afterPlay?.[gameDataName]?.[
                openRewardIndex ? openRewardIndex : 0
              ]?.reward_type?.name?.toUpperCase()}
            </Typography> */}
            <Typography
              sx={{
                color: "#1A1A1A",
                fontFamily: "Proxima Nova A",
                fontSize: "15.696px",
                fontWeight: "700",
                lineHeight: "19.06px",
                textAlign: "center",
                fontFeatureSettings: "'liga' off, 'clig' off",
                mt: "7px",
              }}
            >
              100 DigiGold
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            height: "27px",
            marginTop: "9px",
            paddingX: "16px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Proxima Nova A",
              fontSize: "10.09px",
              fontWeight: "700",
              lineHeight: "13.454px",
              color: "#1A1A1A",
              textAlign: "center",
              height: "13.454px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              fontFeatureSettings: "'liga' off, 'clig' off",
            }}
          >
            {
              data?.createGame?.afterPlay?.[gameDataName]?.[openRewardIndex]
                ?.title
            }
          </Typography>
          <Typography
            sx={{
              fontFamily: "Proxima Nova A",
              fontSize: "7.848px",
              fontWeight: "400",
              lineHeight: "11.212px",
              color: "#4D4D4D",
              textAlign: "center",
              mt: "5px",
              maxHeight: "22.424px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              fontFeatureSettings: "'liga' off, 'clig' off",
              mx: "20px",
            }}
          >
            {
              data?.createGame?.afterPlay?.[gameDataName]?.[
                openRewardIndex || 0
              ]?.description
            }
          </Typography>
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{
                borderRadius: "4.485px",
                backgroundColor: "white",
                width: "112px",
                maxWidth: "112px",
                border: "1px solid var(--opacity-outline-24, #78787D3D)",
                marginTop: "10px",
                "&:hover": {
                  backgroundColor: "white",
                },
                fontFamily: "Proxima Nova A",
                fontSize: "7.848px",
                fontWeight: "600",
                lineHeight: "11.212px",
                color: "#1A1A1A",
                padding: "5px 13px",
                textTransform: "inherit",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                "& .MuiButton-label": {
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "block",
                  width: "100%",
                },
              }}
                onClick={()=>{
                  window.open(
                    data?.createGame?.afterPlay?.[gameDataName]?.[
                      openRewardIndex
                    ]?.start_investing_link,
                    "_blank"
                  );
                }}
            >
              <Typography
                noWrap
                sx={{
                  fontFamily: "Proxima Nova A",
                  fontSize: "7.848px",
                  fontWeight: "600",
                  lineHeight: "11.212px",
                  color: "#1A1A1A",
                }}
              >
                {(() => {
                  const text =
                    data?.createGame?.afterPlay?.[gameDataName]?.[
                      openRewardIndex || 0
                    ]?.start_investing_cta_text || "Start Investing";

                  return text.length > 20
                    ? text.substring(0, 20) + "..."
                    : text;
                })()}
              </Typography>
            </Button>
          </Box> */}
        </Box>
      </Box>

      <Box
        sx={{
          height: "42px",
          // width: "100%",
          backgroundColor: "#FDFDFD",
          borderRadius: "20px 20px 0px 0px",
          marginTop: "160px",
          padding: "9px",
          cursor: "pointer",
        }}
        onClick={()=>{
          window.open(
            data?.createGame?.afterPlay?.[gameDataName]?.[
              openRewardIndex
            ]?.start_investing_link,
            "_blank"
          );
        }}
      >
        <Box
          sx={{
            backgroundColor: "#8800EC",
            borderRadius: "4.485px",
            width: "100%",
            height: "23px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Proxima Nova A",
              fontSize: "12px",
              fontWeight: "600",
              lineHeight: "18px",
              color: "#FFFFFF",
            }}
          >
               {(() => {
                  const text =
                    data?.createGame?.afterPlay?.[gameDataName]?.[
                      openRewardIndex || 0
                    ]?.start_investing_cta_text || "Start Shopping";

                  return text.length > 20
                    ? text.substring(0, 20) + "..."
                    : text;
                })()}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
