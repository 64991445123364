import React, { useState } from 'react';
import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Button,
  Box,
} from "@mui/material";
import { toast, ToastContainer } from 'react-toastify';

const UrlUpload = ({
  placeholder,
  onChange,
  handleUpload,
  value,
  errorMessage,
  disable = false,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [isValidating, setIsValidating] = useState(false);

  // Get trusted domains from environment variables
  // If REACT_APP_TRUSTED_DOMAINS is not set or is empty, we'll accept all URLs
  const TRUSTED_DOMAINS = process.env.REACT_APP_TRUSTED_DOMAINS
    ? process.env.REACT_APP_TRUSTED_DOMAINS.split(',').map(domain => domain.trim()).filter(Boolean)
    : [];
  
  // Flag to determine if we should validate against whitelist
  const shouldValidateWhitelist = TRUSTED_DOMAINS.length > 0;

  const validateUrl = (url) => {
    const URL_REGEX = /^https?:\/\/(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+\/?.*$/;
    return URL_REGEX.test(url);
  };

  const isUrlWhitelisted = (url) => {
    // If no trusted domains are specified, accept all valid URLs
    if (!shouldValidateWhitelist) {
      return true;
    }
    
    try {
      const urlObj = new URL(url);
      const domain = urlObj.hostname;
      
      // Check if the domain matches any in our whitelist
      return TRUSTED_DOMAINS.some(trustedDomain => 
        domain === trustedDomain || domain.endsWith(`.${trustedDomain}`)
      );
    } catch (error) {
      // If URL parsing fails, return false
      return false;
    }
  };

  // Function to check if URL points to a valid image
  const isValidImageUrl = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => reject(new Error("Invalid image URL"));
      img.src = url;
    });
  };

  const onUploadClick = async () => {
    setError(null);
    if(!value) {
      return;
    }
    if (!validateUrl(value)) {
      toast.error("Invalid URL format"); 
      setError("Invalid URL format");
      return;
    }
    if (!isUrlWhitelisted(value)) {
      toast.error("URL domain not allowed");
      setError("URL domain not allowed. Please use a trusted domain.");
      return;
    }

    setIsValidating(true);
    try {
      // Check if the URL points to a valid image
      await isValidImageUrl(value);
      
      // Call the original handleUpload function
      await handleUpload();
      
      // Set uploading state to true
      setIsUploading(true);
      
      // After 2 seconds, reset the uploading state
      setTimeout(() => {
        setIsUploading(false);
      }, 2000);
    } catch (error) {
      // Handle any potential errors from handleUpload or image validation
      console.error('Upload failed', error);
      toast.error("Invalid image URL. Please provide a valid image URL.");
      setError("Invalid image URL. Please provide a valid image URL.");
    } finally {
      setIsValidating(false);
    }
  };

  return (
    <FormControl sx={{ width: "100%", mb: "10px" }} variant="outlined">
      <OutlinedInput
        size="small"
        disabled={disable}
        sx={{
          height: "32px",
          "& input": {
            fontFamily: "Lato",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            padding: "8px",
          },
          "& fieldset": {
            borderColor: disable ? "#B5BBC5" : errorMessage || error ? "#d74848" : "",
            borderRadius: "6px",
          },
          "&:hover fieldset": {
            borderColor: disable ? "#B5BBC5" : errorMessage || error ? "#d74848" : "",
          },
          "&.Mui-focused fieldset": {
            borderColor: disable ? "#B5BBC5" : errorMessage || error ? "#d74848" : "",
          },
          "&.Mui-disabled": {
            opacity: 1,
            "& fieldset": {
              borderColor: "#B5BBC5",
            },
          },
        }}
        onChange={(e) => onChange(e?.target?.value)}
        value={value}
        error={!!errorMessage || !!error}
        endAdornment={
          <InputAdornment position="end">
            <Button
              color="inherit"
              onClick={onUploadClick}
              disabled={disable || isUploading || isValidating}
              sx={{
                marginRight: "-13px",
                backgroundColor: "#F9F9FC",
                color: disable || isUploading || isValidating ? "#B5BBC5" : "#003D86",
                fontFamily: "Lato",
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "16px",
                borderRadius: "4px",
                height: "32px",
                width: "92px",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                alignItems: "center",
                textTransform: "none",
              }}
              size="small"
            >
              {isValidating ? "Validating..." : isUploading ? "Url Added" : "Add Url"}
            </Button>
          </InputAdornment>
        }
        placeholder={placeholder}
      />
      {(errorMessage || error) && (
        <Box
          sx={{
            mt: 1,
            fontSize: "12px",
            color: "#d74848",
            textAlign: "left",
            ml: "20px",
          }}
        >
          {errorMessage ? errorMessage : error}
        </Box>
      )}
    </FormControl>
  );
};

export default UrlUpload;