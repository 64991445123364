import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { AndroidMockup } from "react-device-mockup";
import { useSelector } from "react-redux";
import { getGameDataName } from "../../../../../../utils/gameDataName";
import previous_image from "../../../../../../assets/previous_image.svg";
import transparent_background from "../../../../../../assets/transparent_background.svg";
import { IoIosArrowBack } from "react-icons/io";
import { useLocation } from "react-router-dom";
import DigitalGold from "../commonScreen/DigitalGold";
export default function AfterPlayPreviewDigitalGoldSC({ openRewardIndex }) {
  const data = useSelector((store) => store.OfferReducer.offerData);
  const gameDataName = getGameDataName(data?.createGame?.game_type_code);
  const [previewIndex, setPreviewIndex] = useState(0);
  const { pathname } = useLocation();
  const isPreviewOfferPage = pathname.includes("preview");

  return (
    <Box
      sx={
        isPreviewOfferPage
          ? {
              width: "33%",
              background: "#E8F0F9",
              minHeight: "calc(100vh - 100px)",
              borderRadius: "0px 6px 0px 0px",
              position: "absolute",
              top: "0px",
              right: "0px",
              display: "flex", // Added display: flex
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
            }
          : {
              width: "calc(100% - (72% + 10px))",
              background: "#E8F0F9",
              height: "calc(100vh - 100px)",
              borderRadius: "0px 6px 0px 0px",
              position: "fixed",
              top: "68px",
              right: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflowY: "auto",
            }
      }
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column", // Changed to column layout
          alignItems: "center",
          gap: "15px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            color: "#1D1F2C",
            mb: "20px",
          }}
        >
          Preview
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
          {/* <Box>
            <img
              src={previous_image}
              alt="previous_image"
              style={{
                cursor: "pointer",
                height: "32px",
                width: "32px",
              }}
              onClick={() => {
                if (previewIndex === 0) {
                  setPreviewIndex(1);
                } else {
                  setPreviewIndex(0);
                }
              }}
            />
          </Box> */}

          <Box>
            <AndroidMockup
              screenWidth={220}
              frameColor="#0D0D0F"
              statusbarColor="#0D0D0F"
            >
              <DigitalGold
                data={data}
                gameDataName={gameDataName}
                openRewardIndex={openRewardIndex}
              />
            </AndroidMockup>
          </Box>

          {/* <Box>
            <img
              src={previous_image}
              alt="previous_image"
              style={{
                cursor: "pointer",
                height: "32px",
                width: "32px",
                rotate: "180deg",
              }}
              onClick={() => {
                if (previewIndex === 0) {
                  setPreviewIndex(1);
                } else {
                  setPreviewIndex(0);
                }
              }}
            />
          </Box> */}
        </Box>

        {/* <Typography
          sx={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            color: "#1D1F2C",
            mt: "20px",
          }}
        >{`(${previewIndex + 1}/2)`}</Typography> */}
      </Box>
    </Box>
  );
}
