import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { IoIosArrowBack } from "react-icons/io";

export default function PromoNeuCoin({ data, gameDataName, openRewardIndex }) {
  return (
    <Box
    sx={{
      height: "100%",
      backgroundColor: "#EBF0FF7A",
      width: "100%",
    }}
  >
    <Box
      sx={{
        height: "32px",
        backgroundColor: "#FDFDFD",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        gap: "10px",
      }}
    >
      <IoIosArrowBack
        style={{
          marginLeft: "10px",
          height: "10px",
          width: "12px",
          color: "#1A1A1A",
        }}
      />
      <Typography
        sx={{
          fontFamily: "Proxima Nova A",
          fontSize: "8.969px",
          fontWeight: "700",
          lineHeight: "13.454px",
          color: "#1A1A1A",
          textAlign: "start",
        }}
      >
        Reward Details
      </Typography>
    </Box>
    <Box mt="9px"
        sx={{
          overflowY: "auto",
          wordWrap: "break-word",
          height:"366px"
          // maxHeight: "400px",
        }}
    >
      <Box sx={{ position: "relative" }}>
        <img
          style={{
            width: "165px",
            height: "165px",
            borderRadius: "12px",
          }}
          src={
            data?.createGame?.afterPlay?.[gameDataName]?.[
              openRewardIndex ? openRewardIndex : 0
            ]?.scratch_card_image
          }
          alt=""
        />
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "0px",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                width: "40px",
                height: "40px",
              }}
              src={
                data?.createGame?.afterPlay?.[gameDataName]?.[
                  openRewardIndex ? openRewardIndex : 0
                ]?.logo_image_1
              }
              alt=""
            />
            <img
              style={{
                width: "40px",
                height: "40px",
              }}
              src={
                data?.createGame?.afterPlay?.[gameDataName]?.[
                  openRewardIndex ? openRewardIndex : 0
                ]?.logo_image_2
              }
              alt=""
            />
          </Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "80%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {/* <Typography
            sx={{
              color: "#000000",
              fontFamily: "Proxima Nova A",
              fontSize: "6.727px",
              fontWeight: "600",
              lineHeight: "8.969px",
              letterSpacing: "1.121px",
              height: "8.969px",
              textTransform: "uppercase",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {
              data?.createGame?.afterPlay?.[gameDataName]?.[
                openRewardIndex ? openRewardIndex : 0
              ]?.reward_type?.name
            }
          </Typography> */}
          <Typography
            sx={{
              color: "#1A1A1A",
              fontFamily: "Proxima Nova A",
              fontSize: "15.696px",
              fontWeight: "700",
              lineHeight: "19.06px",
              textAlign: "center",
              fontFeatureSettings: "'liga' off, 'clig' off",
              mt: "5px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {data?.createGame?.afterPlay?.[gameDataName]?.[
              openRewardIndex || 0
            ]?.title?.slice(0, 10) +
              (data?.createGame?.afterPlay?.[gameDataName]?.[
                openRewardIndex || 0
              ]?.title?.length > 10
                ? "..."
                : "")}
          </Typography>
          <Typography
            sx={{
              color: "var(--on-background-high-contrast, #1A1A1A)",
              textAlign: "center",
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: "Proxima Nova A",
              fontSize: "7.848px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "11.212px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {data?.createGame?.afterPlay?.[gameDataName]?.[
              openRewardIndex || 0
            ]?.subtitle?.slice(0, 15) +
              (data?.createGame?.afterPlay?.[gameDataName]?.[
                openRewardIndex || 0
              ]?.subtitle?.length > 15
                ? "..."
                : "")}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          height: "27px",
          marginTop: "9px",
          paddingX: "4px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Proxima Nova A",
            fontSize: "10.09px",
            fontWeight: "700",
            lineHeight: "13.454px",
            color: "#1A1A1A",
            textAlign: "center",
            height: "13.454px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            fontFeatureSettings: "'liga' off, 'clig' off",
          }}
        >
          {
            data?.createGame?.afterPlay?.[gameDataName]?.[
              openRewardIndex
            ]?.after_play_details_title
          }
        </Typography>
        <Typography
          sx={{
            fontFamily: "Proxima Nova A",
            fontSize: "7.848px",
            fontWeight: "400",
            lineHeight: "11.212px",
            color: "#4D4D4D",
            textAlign: "center",
            mt: "5px",
            maxHeight: "22.424px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            fontFeatureSettings: "'liga' off, 'clig' off",
            mx: "20px",
          }}
        >
          {
            data?.createGame?.afterPlay?.[gameDataName]?.[
              openRewardIndex || 0
            ]?.after_play_details_description
          }
        </Typography>
        <Box>
          <Box
            sx={{
              borderTop: "1px dotted #78787D3D",
              // maxHeight: "120px",
              // overflowY: "auto",
              marginTop: "12px",
              paddingX: "8px",
              // "&::-webkit-scrollbar": {
              //   width: "4px",
              // },
              // "&::-webkit-scrollbar-thumb": {
              //   backgroundColor: "#78787D3D",
              //   borderRadius: "2px",
              // },
            }}
          >
            <Box
              sx={{
                // backgroundColor: "#f4f4f4",
                // padding: "8px 15px",
                paddingTop:"12px",
                borderRadius: "6px",
                textAlign: "left",
                color: "#212121",
                fontFamily: "Proxima Nova A",
                fontSize: "12px",
                display: "flex",
                flexDirection: "column",
                // overflowY: "auto",
                // wordWrap: "break-word",
                // maxHeight: "120px",
                // Enhanced typography styles
                "& h1, & h2, & h3, & h4, & h5, & h6": {
                  fontWeight: "bold",
                  lineHeight: 1.2,
                  margin: "0px",
                  // marginBottom: "8px",
                },
                "& h1": { fontSize: "18px" },
                "& h2": { fontSize: "16px" },
                "& h3": { fontSize: "15px" },
                "& h4": { fontSize: "14px" },
                "& h5": { fontSize: "13px" },
                "& h6": { fontSize: "12px" },
                // Paragraph styles
                "& p": {
                  // marginBottom: "12px",
                  fontSize: "12px",
                  lineHeight: "1.4",
                },
                // Text formatting
                "& strong, & b": {
                  fontWeight: "bold",
                },
                "& em, & i": {
                  fontStyle: "italic",
                },
                // List styles
                "& ul, & ol": {
                  paddingLeft: "12px",
                  // marginBottom: "12px",
                  // marginTop: "4px",
                },
                "& ul": {
                  listStyleType: "disc",
                },
                "& ol": {
                  listStyleType: "decimal",
                },
                // Nested list styles
                "& ul ul": {
                  listStyleType: "circle",
                  marginBottom: "0",
                },
                "& ul ul ul": {
                  listStyleType: "square",
                },
                "& ol ol": {
                  listStyleType: "lower-alpha",
                  marginBottom: "0",
                },
                "& ol ol ol": {
                  listStyleType: "lower-roman",
                },
                "& li": {
                  marginBottom: "4px",
                  lineHeight: "1.4",
                },
                // Link styles
                "& a": {
                  color: "#005CBD",
                  textDecoration: "underline",
                  "&:hover": {
                    textDecoration: "none",
                  },
                },
                // Table styles
                "& table": {
                  borderCollapse: "collapse",
                  width: "100%",
                  marginBottom: "12px",
                  fontSize: "13px",
                },
                "& th, & td": {
                  border: "1px solid #ddd",
                  padding: "6px",
                  textAlign: "left",
                },
                "& th": {
                  backgroundColor: "#f2f2f2",
                  fontWeight: "bold",
                },
                // Quote styles
                "& blockquote": {
                  borderLeft: "3px solid #005CBD",
                  margin: "12px 0",
                  paddingLeft: "12px",
                  fontStyle: "italic",
                  color: "#555",
                },
                // Code styles
                "& code": {
                  backgroundColor: "#e6e6e6",
                  padding: "2px 4px",
                  borderRadius: "3px",
                  fontSize: "12px",
                  fontFamily: "monospace",
                },
                // Horizontal rule
                "& hr": {
                  border: "none",
                  borderTop: "1px solid #ddd",
                  margin: "12px 0",
                },
                // Image styles
                "& img": {
                  maxWidth: "100%",
                  height: "auto",
                  marginBottom: "12px",
                  borderRadius: "4px",
                },
                // Jodit specific styles
                "& .jodit-wysiwyg": {
                  minHeight: "50px",
                  padding: "8px",
                },
                // Alignment classes
                "& .jodit-text-center": {
                  textAlign: "center",
                },
                "& .jodit-text-right": {
                  textAlign: "right",
                },
                "& .jodit-text-justify": {
                  textAlign: "justify",
                },
              }}
              dangerouslySetInnerHTML={{
                __html:
                  data?.createGame?.afterPlay?.[gameDataName]?.[
                    openRewardIndex
                  ]?.how_to_redeem || "",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>

    <Box
      sx={{
        height: "42px",
        // width: "100%",
        backgroundColor: "#FDFDFD",
        borderRadius: "20px 20px 0px 0px",
        // marginTop: "160px",
        padding: "9px",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#8800EC",
          borderRadius: "4.485px",
          width: "100%",
          height: "23px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Proxima Nova A",
            fontSize: "12px",
            fontWeight: "600",
            lineHeight: "18px",
            color: "#FFFFFF",
          }}
        >
          {
            data?.createGame?.afterPlay?.[gameDataName]?.[
              openRewardIndex || 0
            ]?.start_shopping_cta_text
          }
        </Typography>
      </Box>
    </Box>
  </Box>
  )
}
