import {
  allDataSubmitted,
  cancelErrorSubmit,
  clearCreateOfferData,
  clearRewardPreviousValue,
  closeModel,
  editConfirmation,
  editOfferFail,
  editOfferSuccess,
  editReward,
  errorSubmitOffer,
  getCategory,
  getCohortsData,
  getCurrency,
  getGames,
  getGameType,
  getInitiative,
  getMerchant,
  getPreviouslyCreateRewards,
  getProduct,
  getRewardTypes,
  getRuleSet,
  getSubCategory,
  initialOfferEditValue,
  projectionLoaderStatus,
  resetEdit,
  saveAfterPlayWithGameChanges,
  saveChangedOfferData,
  savePreviousDataForEditOffer,
  saveRewardPreviousValue,
  selectGameType,
  setAfterPlayChange,
  setAfterPlayReward,
  setAfterPlayWithGameChanges,
  setBeforePlay2by2Change,
  setBeforePlay2by2Reward,
  setBeforePlayChange,
  setDrawerImageOption,
  setOfferDetailsDataChanges,
  setQualifyingCriteriaDataChanges,
  setQuizChange,
  setRewardArray,
  toggleReward,
} from "./actionType";

const beforePlayFixedData = {
  title_color: "#000000",
  background_overlay_color: "#000000",
  title: "Before Play Title",
  show_earned_via: {
    id: true,
    name: "Yes",
  },
  scartch_card_locked_image:
   "https://gratifystorage01.blob.core.windows.net/gratify-dev/bd3b96_responsive-media.png",
  scartch_card_unlocked_image:
    "https://gratifystorage01.blob.core.windows.net/gratify-dev/ec3858_unlocked.png"
};

const beforePlaySpinTheWheelData = {
  no_of_spins : "3",
  read_instruction : "<strong>Game Instructions</strong><p></p><p>Please read the following instructions carefully before starting the game.</p><ol><li>The game consists of multiple levels with increasing difficulty.</li><li>Use the on-screen controls or keyboard shortcuts to play.</li><li>Each level has a specific objective that must be completed to progress.</li><li>You will have a limited number of attempts or lives.</li><li>Collect power-ups and bonuses to enhance your gameplay.</li><li>Avoid obstacles and enemies to prevent losing lives.</li><li>Your final score will be based on your performance throughout the game.</li><li>Click the 'Start Game' button to begin.</li></ol>",
  terms_and_conditions :  "<strong>Terms and conditions</strong><p></p><p>Congrats! You have won up to ₹1000 cashback on bbnow transaction.</p><ol><li>Expires on June 13, 2024</li><li>Get 5% off worth up to 7100 cashback on select shopping and food brand gift card purchase on Google Pay.</li><li> Eligible brands - Amazon Pay, Flipkart, Myntra, Nykaa, Swiggy, Zomato, Domino and Big basket.</li><li>No code needed. Just tap 'Buy now' and your voucher will be automatically applied when you pay.</li><li>Only valid for payments made with Tata Neu</li></ol>"
};

const quizFixedData = {
  read_instructions: "<strong>Read Instructions</strong><p></p><p>Please read the following instructions carefully before starting the quiz.</p><ol><li>The quiz consists of multiple-choice questions (MCQs).</li><li>You will have a limited time to complete the quiz.</li><li>Each question carries equal marks.</li><li>Once you submit an answer, you cannot change it.</li><li>Do not refresh the page or navigate away during the quiz.</li><li>Ensure a stable internet connection for a smooth experience.</li><li>Click the 'Submit' button after answering all questions.</li></ol>",
  terms_and_conditions: "<strong>Terms and conditions</strong><p></p><p>Congrats! You have won up to ₹1000 cashback on bbnow transaction.</p><ol><li>Expires on June 13, 2024</li><li>Get 5% off worth up to 7100 cashback on select shopping and food brand gift card purchase on Google Pay.</li><li> Eligible brands - Amazon Pay, Flipkart, Myntra, Nykaa, Swiggy, Zomato, Domino and Big basket.</li><li>No code needed. Just tap 'Buy now' and your voucher will be automatically applied when you pay.</li><li>Only valid for payments made with Tata Neu</li></ol>",
  question_banner :  "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
}

const afterPlayWithGameFixedData = {
  title: "After Play Title",
  congratulation_image:
    "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png",
};

const initialState = {
  offerData: {
    offerDetails: {},
    setQualifyingCriteria: {
      step: 1,
      skipRule: false,
      offerDiscovery: false,
    },
    createGame: {
      game: {
        scratchCardData: [],
        spinTheWheelData: [],
        slotMachineData: [],
        triviaData: [],
        triviaData_step1: {
          triviaType: "",
          questions: [
            {
              title: "",
              addMedia: false,
              images: [],
              options: [{ optionTitle: "", isSelected: true }],
            },
          ],
        },
      },
      beforePlay: {
        scratchCardData: beforePlayFixedData ?? {},
        spinTheWheelData: beforePlaySpinTheWheelData ?? {},
        slotMachineData: beforePlayFixedData ?? {},
        triviaData: beforePlayFixedData ?? {},
      },
      beforePlay2by2: {
        scratchCardData: [],
        spinTheWheelData: [],
        slotMachineData: [],
        triviaData: [],
      },
      afterPlay: {
        scratchCardData: [],
        spinTheWheelData: [],
        slotMachineData: [],
        triviaData: [],
      },
      afterPlayWithGame: {
        scratchCardData: {},
        spinTheWheelData: afterPlayWithGameFixedData ?? {},
        slotMachineData: {},
        triviaData: {},
      },
      quiz: {
        scratchCardData: {},
        spinTheWheelData: {
          Question_type : {id: 'single', name: 'Single Choice'},
          Answer:0,
          Options: [
            {
              id: 0,
              value: "",
            },
          ],
          read_instructions: quizFixedData.read_instructions,
          terms_and_conditions: quizFixedData.terms_and_conditions,
          question_banner: quizFixedData.question_banner
        },
        slotMachineData: {},
        triviaData: {},
      },
      whenPlay: {
        triviaData: {},
      },
      addNewRewards: {
        scratchCardData: {
          rewardType: "",
          previouslyCreatedReward: "",
          templates: "",
          startFromScratch: {
            rewardType: {},
            rewardTitle: "",
          },
        },
        spinTheWheelData: {
          rewardType: "",
          previouslyCreatedReward: "",
          templates: "",
          startFromScratch: {
            rewardType: {},
            rewardTitle: "",
          },
        },
        slotMachineData: {
          rewardType: "",
          previouslyCreatedReward: "",
          templates: "",
          startFromScratch: {
            rewardType: {},
            rewardTitle: "",
          },
        },
        triviaData: {
          rewardType: "",
          previouslyCreatedReward: "",
          templates: "",
          startFromScratch: {
            rewardType: {},
            rewardTitle: "",
          },
        },
      },
      drawerData: {
        backend_label: "",
        image_option: [],
        gameDataName: "",
        game_type: "",
        action_type: "",
        rewardIndex: "",
      },
    },
  },
  categoryOption: [],
  subCategoryOption: [],
  productOption: [],
  merchantOption: [],
  ruleOption: [],
  cohortsOption: [],
  initiativeOption: [],
  gamesOption: [],
  rewardCurrencyOption: [],
  initialOfferEditValue: [],
  flag: false,
  editSuccess: false,
  editError: false,
  editErrorMessage: "",
  submitOfferError: false,
  offerId: "3",
  errorMessage: "",
  allGames: [],
  allRewardTypes: [],
  rewardPreviousValue: {},
  allRewards: {},
  projectionLoader: false,
  previousOfferDataForEditOffer: {},
  changedOfferData: {},
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case saveChangedOfferData:
      return {
        ...state,
        changedOfferData: payload,
      };
    case setOfferDetailsDataChanges:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          offerDetails: {
            ...state.offerData.offerDetails,
            ...payload,
          },
        },
      };
    case setQualifyingCriteriaDataChanges:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          setQualifyingCriteria: {
            ...state.offerData.setQualifyingCriteria,
            ...payload,
          },
        },
      };
    case selectGameType:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            ...payload,
          },
        },
      };
    case toggleReward:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            game: {
              ...state.offerData.createGame.game,
              [payload.gameDataName]: payload.updatedRewardArray,
            },
          },
        },
      };
    case setRewardArray:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            game: {
              ...state.offerData.createGame.game,
              [payload.gameDataName]: payload.rewardArray,
            },
          },
        },
      };
    case editReward:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            game: {
              ...state.offerData.createGame.game,
              [payload.gameDataName]: payload.updatedRewardArray,
            },
          },
        },
      };
    case setBeforePlayChange:
      const { gameDataName, field, value, additionalFields } = payload;
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            beforePlay: {
              ...state.offerData.createGame.beforePlay,
              [gameDataName]: {
                ...state.offerData.createGame.beforePlay[gameDataName],
                [field]: value,
                ...additionalFields,
              },
            },
          },
        },
      };
    case setQuizChange: {
      const { gameDataName, field, value, additionalFields } = payload;
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            quiz: {
              ...state.offerData.createGame.quiz,
              [gameDataName]: {
                ...state.offerData.createGame.quiz[gameDataName],
                [field]: value,
                ...additionalFields,
              },
            },
          },
        },
      };
    }
    case setAfterPlayWithGameChanges: {
      console.log("Reducer received action:", payload);
      const { gameDataName, field, value, additionalFields } = payload;
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            afterPlayWithGame: {
              ...state.offerData.createGame.afterPlayWithGame,
              [gameDataName]: {
                ...state.offerData.createGame.afterPlayWithGame[gameDataName],
                [field]: value,
                ...additionalFields,
              },
            },
          },
        },
      };
    }
    case saveAfterPlayWithGameChanges: {
      console.log("Reducer received action:", payload);
      const { gameDataName, field, value, additionalFields } = payload;
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            afterPlayWithGame: {
              ...state.offerData.createGame.afterPlayWithGame,
              [gameDataName]: {
                ...additionalFields,
              },
            },
          },
        },
      };
    }
    case setAfterPlayChange:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            afterPlay: {
              ...state.offerData.createGame.afterPlay,
              [payload.gameDataName]: state.offerData.createGame.afterPlay[
                payload.gameDataName
              ].map((reward, index) => {
                if (index === payload.rewardIndex) {
                  return {
                    ...reward,
                    [payload.field]: payload.value,
                    ...payload.additionalFields,
                  };
                }
                return reward;
              }),
            },
          },
        },
      };
    case setAfterPlayReward:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            afterPlay: {
              ...state.offerData.createGame.afterPlay,
              [payload.gameDataName]: payload.rewardArray,
            },
          },
        },
      };
    case setDrawerImageOption:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            drawerData: {
              ...state.offerData.createGame.drawerData,
              image_option: payload?.image_option,
              backend_label: payload?.backend_label,
              gameDataName: payload?.gameDataName,
              game_type: payload?.game_type,
              action_type: payload?.action_type,
              rewardIndex: payload?.rewardIndex,
            },
          },
        },
      };
    case setBeforePlay2by2Change:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            beforePlay2by2: {
              ...state.offerData.createGame.beforePlay2by2,
              [payload.gameDataName]: state.offerData.createGame.beforePlay2by2[
                payload.gameDataName
              ].map((reward, index) => {
                if (index === payload.rewardIndex) {
                  return {
                    ...reward,
                    [payload.field]: payload.value,
                    ...payload.additionalFields,
                  };
                }
                return reward;
              }),
            },
          },
        },
      };
    case setBeforePlay2by2Reward:
      return {
        ...state,
        offerData: {
          ...state.offerData,
          createGame: {
            ...state.offerData.createGame,
            beforePlay2by2: {
              ...state.offerData.createGame.beforePlay2by2,
              [payload.gameDataName]: payload.rewardArray,
            },
          },
        },
      };
    case clearCreateOfferData:
      return {
        ...state,
        offerData: initialState.offerData,
      };
    case getCategory:
      return { ...state, categoryOption: payload };
    case getSubCategory:
      return { ...state, subCategoryOption: payload };
    case getProduct:
      return { ...state, productOption: payload };
    case getMerchant:
      return { ...state, merchantOption: payload };
    case getRuleSet:
      return { ...state, ruleOption: payload };
    case getInitiative:
      return { ...state, initiativeOption: payload };
    case getGames:
      return { ...state, gamesOption: payload };
    case getCurrency:
      return { ...state, rewardCurrencyOption: payload };
    case allDataSubmitted:
      return { ...state, flag: true, offerId: payload.offerId };
    case editOfferSuccess:
      return { ...state, editSuccess: true };
    case editOfferFail:
      return { ...state, editError: true, editErrorMessage: payload };
    case editConfirmation:
      return { ...state, flag: true };
    case resetEdit:
      return { ...state, editSuccess: false, editError: false, flag: false };
    case closeModel:
      return { ...state, flag: false };
    case getCohortsData:
      return { ...state, cohortsOption: payload };
    case initialOfferEditValue:
      return { ...state, initialOfferEditValue: payload };
    case errorSubmitOffer:
      return { ...state, submitOfferError: true, errorMessage: payload };
    case cancelErrorSubmit:
      return { ...state, submitOfferError: false };
    case getGameType:
      return { ...state, allGames: payload };
    case getRewardTypes:
      return { ...state, allRewardTypes: payload };
    case saveRewardPreviousValue:
      return { ...state, rewardPreviousValue: payload };
    case clearRewardPreviousValue:
      return { ...state, rewardPreviousValue: {} };
    case getPreviouslyCreateRewards:
      return { ...state, allRewards: payload };
    case projectionLoaderStatus:
      return { ...state, projectionLoader: payload };
    case savePreviousDataForEditOffer:
      return {
        ...state,
        previousOfferDataForEditOffer: { ...state.offerData },
      };
    default:
      return state;
  }
};
