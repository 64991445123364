import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import tickImage from "../../../../assets/whiteTick.png";
import { useDispatch, useSelector } from "react-redux";
import { CreateGame_PreData } from "../../../../utils/CreateGame_PreData";
import BoldLabel from "../../../../UIComponents/BoldLevel";
import MuiInput from "../../../../UIComponents/MuiInput";
import ColorInput from "../../../../UIComponents/ColorInput";
import MuiRadioGroup from "../../../../UIComponents/MuiRadioGroup";
import SmallImageUpload from "../../../../UIComponents/SmallImageUpload";
import {
  setQuizChange,
  setDrawerImageOption,
} from "../../../../Redux/OfferReducer/actionType";
import MuiSelectAndSearch from "../../../../UIComponents/MuiSelectAndSearch";
import RightDrawer from "./RightDrawer";
import axios from "axios";
import uploadIcon from "../../../../assets/UploadIcon.svg";
import { useLocation } from "react-router-dom";
import { getGameDataName } from "../../../../utils/gameDataName";
import { game_type } from "./Data";
import optionSelected from "../../../../assets/optionSelected.svg";
import optionNotSelected from "../../../../assets/optionNotSelected.svg";
import optionDelete from "../../../../assets/optionDelete.svg";
import add from "../../../../assets/add.svg";
import dots from "../../../../assets/dots.svg";
import RichEditor from "../../../../UIComponents/RichEditor";

const isFieldDisabled =
  process.env.REACT_APP_DISABLE_BEFORE_AND_AFTER_PLAY_EDIT === "true";

export default function QuizPage({
  showEdit,
  validationObject,
  errorMessageObject,
}) {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const token = useSelector((store) => store.AuthReducer.token);
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { pathname } = useLocation();
  //   to open drawer
  const handleDrawerOpen = (event, backend_level, options) => {
    event.stopPropagation();
    setDrawerOpen(true);
  };

  //   to close drawer
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const data = useSelector(
    (store) => store?.OfferReducer?.offerData?.createGame
  );

  const fields = useSelector((store) => store?.OfferReducer?.allGames)?.find(
    (e) => e?.id === data?.game_type_id
  )?.game_fields?.quiz;
  // const fields = game_type.find((e) => e?.id === data?.game_type_id)
  //   ?.game_fields?.quiz;

  const changedData = useSelector(
    (store) => store.OfferReducer?.changedOfferData?.game?.details
  );
console.log(changedData, "changedData");
  function compareHexCodes(hex1 = "", hex2) {
    // Normalize hex codes by converting to lowercase and expanding shorthand format if necessary
    const normalizeHex = (hex) => {
      // Remove the '#' symbol if present
      hex = hex.replace("#", "");

      // Convert shorthand hex code (e.g., #ABC) to full form (e.g., #AABBCC)
      if (hex.length === 3) {
        hex = hex
          .split("")
          ?.map((char) => char + char)
          .join("");
      }

      // Return the hex code in lowercase for consistent comparison
      return hex.toLowerCase();
    };

    return normalizeHex(hex1) === normalizeHex(hex2);
  }

  const dispatch = useDispatch();
  // console.log(fields, "fields");
  // console.log(changedData, "previousData");
  const gameDataName = getGameDataName(data?.game_type_code);

  const handleBeforePlayChange = (field, value, additionalFields = {}) => {
    if (field.endsWith("_upload")) {
      const formData = new FormData();
      formData.append("file", value);
      axios
        .post(`${BaseUrl}/api/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("Upload successful", response.data);
          const baseField = field.replace("_upload", "");
          const payload = {
            gameDataName,
            field,
            value,
            additionalFields: {
              ...additionalFields,
              [`${baseField}_upload_url`]: response.data.public_url,
              [`${baseField}_upload_id`]: response.data.id,
              [baseField]: response.data.public_url,
            },
          };
          dispatch({ type: setQuizChange, payload });
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
    } else {
      const payload = {
        gameDataName,
        field,
        value,
        additionalFields,
      };
      dispatch({ type: setQuizChange, payload });
    }
  };
  const getColorPickerBackendLabel = (field) => {
    return field?.options?.find((o) => o.value === "color")?.backend_label;
  };

  const getColorPickerErrorMessage = (field) => {
    const colorBackendLabel = getColorPickerBackendLabel(field);
    return errorMessageObject?.[colorBackendLabel];
  };

  const getImageUploadBackendLabel = (field) => {
    if (
      field?.backend_label &&
      data?.quiz?.[gameDataName]?.[field?.backend_label]
    ) {
      return data?.quiz?.[gameDataName]?.[field?.backend_label]?.backend_label;
    }
    return field?.backend_label;
  };

  const getImageUploadErrorMessage = (field) => {
    const imageBackendLabel = getImageUploadBackendLabel(field);
    return errorMessageObject?.[imageBackendLabel];
  };

  const handleAddOptions = () => {
    const currentOptions = data?.quiz?.[gameDataName]?.Options || [];
    const maxId = currentOptions.reduce((max, option) => 
        Math.max(max, option.id || 0), 0);

    // Create new option with value instead of option
    const newOption = {
        id: maxId + 1,
        value: ``,
    };

    const updatedOptions = [...currentOptions, newOption];

    dispatch({
        type: setQuizChange,
        payload: {
            gameDataName,
            field: "Options",
            value: updatedOptions,
        },
    });
  };

  const handleToggleOption = (optionIndex, field) => () => {
    const currentOptions = data?.quiz?.[gameDataName]?.Options || [];
    const selectedOption = currentOptions[optionIndex];

    // Check the question type (single or multiple)
    const questionType = data?.quiz?.[gameDataName]?.[field?.backend_label + "_type"];
    const isMultipleSelection = questionType?.value === "multiple" || questionType?.id === "multiple";

    if (isMultipleSelection) {
        // For multiple selection, maintain array of correct answer IDs
        const currentAnswers = Array.isArray(data?.quiz?.[gameDataName]?.Answer) 
            ? data?.quiz?.[gameDataName]?.Answer 
            : [];
            
        const newAnswers = currentAnswers.includes(selectedOption.id)
            ? currentAnswers.filter(id => id !== selectedOption.id)
            : [...currentAnswers, selectedOption.id];

        dispatch({
            type: setQuizChange,
            payload: {
                gameDataName,
                field: "Answer",
                value: newAnswers,
            },
        });
    } else {
        // For single selection, store single ID
        dispatch({
            type: setQuizChange,
            payload: {
                gameDataName,
                field: "Answer",
                value: selectedOption.id,
            },
        });
    }
  };

  const handleDeleteOption = (optionIndex) => () => {
    const currentOptions = data?.quiz?.[gameDataName]?.Options || [];

    // Remove the option at the specified index
    const updatedOptions = currentOptions.filter(
      (_, index) => index !== optionIndex
    );

    dispatch({
      type: setQuizChange,
      payload: {
        gameDataName,
        field: "Options",
        value: updatedOptions,
      },
    });
  };

  const handleOptionChange = (optionIndex, newValue) => {
    const currentOptions = data?.quiz?.[gameDataName]?.Options || [];

    // Update using value instead of option
    const updatedOptions = currentOptions.map((option, index) =>
        index === optionIndex ? { ...option, value: newValue } : option
    );

    dispatch({
        type: setQuizChange,
        payload: {
            gameDataName,
            field: "Options",
            value: updatedOptions,
        },
    });
  };

  console.log(validationObject, "validationObject");
  console.log(errorMessageObject, "errorMessageObject");
  console.log(data?.quiz?.[gameDataName], "data");

  let num = 1;

  return (
    <Box>
      <RightDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
        handleSelectImage={handleBeforePlayChange}
      />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "start",
          gap: "20px",
        }}
      >
        {fields?.length > 0 &&
          fields?.map((field, index) => {
            if (!field?.mandatory) return null;
            const labelNum = num++;
            validationObject[field?.backend_label] = {
              mandatory: field?.mandatory,
            };
            if (field?.backend_label === "question") {
              validationObject["question_type"] = {
                mandatory: true,
              };
              // console.log(  "scratch_card");
            }
            return (
              <Box
                key={field?.backend_label}
                sx={{
                  // mt: index > 0 ? "20px" : "0px",
                  width: field?.style?.width
                    ? field?.style?.width
                    : "calc(96% + 20px)",
                }}
              >
                <BoldLabel label={`${field?.label}`} style={{display: field?.backend_label === "Question" ? "none" : "block"}} mt="0px" isRequired={true}/>
                {field?.input_type === "question" ? (
                  <>
                    {(() => {
                      validationObject[field?.backend_label + "_type"] = {
                        mandatory: true,
                      };
                      return null;
                    })()}
                    <Box>
                      {/* <MuiSelectAndSearch
                        // isDisable={showEdit}
                        options={field?.options}
                        value={
                          data?.quiz?.[gameDataName]?.[
                            field?.backend_label + "_type"
                          ] || ""
                        }
                        onChange={(event) =>
                          handleBeforePlayChange(
                            field?.backend_label + "_type",
                            event
                          )
                        }
                        placeholder="--Select--"
                        editBoolean={
                          changedData?.[field?.backend_label + "_type"] &&
                          changedData?.[field?.backend_label + "_type"]?.id !==
                            data?.quiz?.[gameDataName]?.[
                              field?.backend_label + "_type"
                            ]?.id
                        }
                        errorMessage={
                          errorMessageObject?.[field?.backend_label + "_type"]
                        }
                      /> */}

                      <BoldLabel label={"Question"} isRequired={true} mt={errorMessageObject?.[field?.backend_label + "_type"]?"0px":"20px" }/>
                      <MuiInput
                        maxLength={field?.max_characters}
                        // disable={showEdit}
                        placeholder={field?.placeholder}
                        value={
                          data?.quiz?.[gameDataName]?.[field?.backend_label] ||
                          ""
                        }
                        onChange={(event) =>
                          handleBeforePlayChange(field?.backend_label, event)
                        }
                        editBoolean={
                          changedData?.[field?.backend_label] &&
                          changedData?.[field?.backend_label] !==
                            data?.quiz?.[gameDataName]?.[field?.backend_label]
                        }
                        errorMessage={
                          errorMessageObject?.[field?.backend_label]
                        }
                        mt="8px"
                      />
                      <BoldLabel label="Add Options" isRequired={true} mt={errorMessageObject?.[field?.backend_label]?"28px":"16px"} />
                      <Box mt="8px">
                        {console.log(data?.quiz?.[gameDataName]?.Options.length,"Options")}
                        {data?.quiz?.[gameDataName]?.Options?.map(
                          (options, optionIndex) => {
                            const isEmptyOption = !options?.value?.trim();
                            return (
                              <Box
                                key={optionIndex}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "16px",
                                  mb: "8px",
                                }}
                              >
                                <img
                                  style={{
                                    height: "24px",
                                    width: "24px",
                                  }}
                                  src={dots}
                                  alt=""
                                />
                                <MuiInput
                                  value={options?.value}
                                  onChange={(event) =>
                                    handleOptionChange(optionIndex, event)
                                  }
                                  placeholder={`Option ${optionIndex + 1}`}
                                  mt="0px"
                                  editBoolean={
                                    changedData?.Options && 
                                    changedData?.Options[optionIndex]?.value !== 
                                    data?.quiz?.[gameDataName]?.Options?.[optionIndex]?.value
                                  }
                                  errorMessage={errorMessageObject?.Options && isEmptyOption}
                                />
                                <Button
                                  onClick={handleToggleOption(
                                    optionIndex,
                                    field
                                  )}
                                  sx={{
                                    minWidth: "0px",
                                    minHeight: "0px",
                                    p: "0px",
                                    // border: errorMessageObject?.Options && 
                                    //        !data?.quiz?.[gameDataName]?.Answer ? 
                                    //        "1px solid #FF4242" : "none",
                                    borderRadius: "4px"
                                  }}
                                >
                                  <img
                                    style={{
                                      height: "32px",
                                      width: "32px",
                                    }}
                                    src={
                                      (Array.isArray(data?.quiz?.[gameDataName]?.Answer) 
                                          ? data?.quiz?.[gameDataName]?.Answer.includes(options.id)
                                          : data?.quiz?.[gameDataName]?.Answer === options.id)
                                          ? optionSelected 
                                          : optionNotSelected
                                    }
                                    alt={
                                      (Array.isArray(data?.quiz?.[gameDataName]?.Answer) 
                                          ? data?.quiz?.[gameDataName]?.Answer.includes(options.id)
                                          : data?.quiz?.[gameDataName]?.Answer === options.id)
                                          ? "Option Selected"
                                          : "Option Not Selected"
                                    }
                                  />
                                </Button>

                                <Button
                                  onClick={handleDeleteOption(optionIndex)}
                                  sx={{
                                    minWidth: "0px",
                                    minHeight: "0px",
                                    p: "0px",
                                    // display:data?.quiz?.[gameDataName]?.Options.length>1? "block":"none",
                                    visibility: data?.quiz?.[gameDataName]?.Options.length>1? "visible":"hidden",
                                    // mt: "8px",
                                  }}
                                >
                                  <img
                                    style={{
                                      height: "32px",
                                      width: "32px",
                                    }}
                                    src={optionDelete}
                                    alt="Delete Option"
                                  />
                                </Button>
                              </Box>
                            );
                          }
                        )}
                      </Box>
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          onClick={handleAddOptions}
                          sx={{
                            height: "28px",
                            width: "102px",
                            textTransform: "capitalize",
                            fontFamily: "Lato",
                            fontSize: "12px",
                            fontWeight: "700",
                            lineHeight: "14.4px",
                            color: "#003D86",
                          }}
                        >
                          <img
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                            src={add}
                            alt=""
                          />{" "}
                          &nbsp; Add More
                        </Button>
                      </Box>
                    </Box>
                  </>
                ) : field?.input_type === "dropdown" ? (
                  <Box>
                    <MuiSelectAndSearch
                      isDisable={showEdit}
                      options={field?.options}
                      value={
                        data?.quiz?.[gameDataName]?.[field?.backend_label] || ""
                      }
                      onChange={(event) =>
                        handleBeforePlayChange(field?.backend_label, event)
                      }
                      placeholder="--Select--"
                      editBoolean={
                        changedData?.[field?.backend_label] &&
                        changedData?.[field?.backend_label]?.id !==
                          data?.quiz?.[gameDataName]?.[field?.backend_label]?.id
                      }
                      errorMessage={errorMessageObject?.[field?.backend_label]}
                    />
                  </Box>
                ) : field?.input_type === "input_box" ? (
                  <Box>
                    <MuiInput
                      maxLength={field?.max_characters}
                      disable={showEdit}
                      placeholder={field?.placeholder}
                      value={
                        data?.quiz?.[gameDataName]?.[field?.backend_label] || ""
                      }
                      onChange={(event) =>
                        handleBeforePlayChange(field?.backend_label, event)
                      }
                      editBoolean={
                        changedData?.[field?.backend_label] &&
                        changedData?.[field?.backend_label] !==
                          data?.quiz?.[gameDataName]?.[field?.backend_label]
                      }
                      errorMessage={errorMessageObject?.[field?.backend_label]}
                    />
                  </Box>
                ) : field?.input_type === "color_picker" ? (
                  <Box>
                    <ColorInput
                      preFill={field?.default}
                      disable={showEdit}
                      value={data?.quiz?.[gameDataName]?.[field?.backend_label]}
                      onChange={(color) =>
                        handleBeforePlayChange(field?.backend_label, color)
                      }
                      editBoolean={
                        changedData?.[field?.backend_label] &&
                        !compareHexCodes(
                          changedData?.[field?.backend_label],
                          data?.quiz?.[gameDataName]?.[field?.backend_label]
                        )
                      }
                      errorMessage={errorMessageObject?.[field?.backend_label]}
                    />
                  </Box>
                ) : field?.input_type === "image_upload" ? (
                  <Box mt={"10px"} >
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        gap: "15px",
                        borderRadius: "8px",
                        border:
                          changedData?.[field?.backend_label] &&
                          changedData?.[field?.backend_label] !==
                            data?.quiz?.[gameDataName]?.[
                              field?.backend_label
                            ]
                            ? "1px solid orange"
                            : getImageUploadErrorMessage(field)
                            ? "1px solid #FF4242"
                            : "none",
                      }}
                    >
                      <Box
                        sx={{
                          borderRadius: "8px",
                          cursor: "pointer",
                          // px: "27px",
                          padding: "14px 15px",
                          // py: "42px",
                          height: "80px",
                          backgroundColor: "#F9F9FC",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "15px",
                          width: "100%",
                        }}
                        onClick={(event) => {
                          if (showEdit) return;
                          handleDrawerOpen(event);
                          dispatch({
                            type: setDrawerImageOption,
                            payload: {
                              backend_label: field?.backend_label,
                              image_option: field?.options,
                              gameDataName: gameDataName,
                              game_type: "quiz",
                              action_type: setQuizChange,
                            },
                          });
                        }}
                      >
                        {data?.quiz?.[gameDataName]?.[
                          field?.backend_label
                        ] ? (
                          <Box
                            sx={{
                              display: "flex",

                              gap: "8px",
                            }}
                          >
                            <img
                              style={{
                                borderRadius: "6px",
                                height: "65px",
                                width: "65px",
                              }}
                              src={
                                data?.quiz?.[gameDataName]?.[
                                  field?.backend_label
                                ]
                              }
                              alt=""
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "flex-start",
                                gap: 1,
                              }}
                            >
                              <Typography
                                // onClick={handleSelectImageClick}
                                sx={{
                                  cursor: "pointer",
                                  fontSize: "12px",
                                  fontWeight: 600,
                                  fontFamily: "Lato",
                                  lineHeight: "14.4px",
                                  color: "#29CC6A",
                                  textDecoration: "underline",
                                  display: pathname.includes("preview")
                                    ? "none"
                                    : "block",
                                }}
                              >
                                Change
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily: "Lato",
                                  fontSize: "10px",
                                  fontWeight: 400,
                                  lineHeight: "12px",
                                  color: "#7E8794",
                                  textAlign: "left",
                                }}
                              >
                                {data?.quiz?.[gameDataName]?.[
                                  field?.backend_label
                                ]
                                  ? "Image Uploaded"
                                  : "No image selected"}
                              </Typography>
                            </Box>
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "15px",
                            }}
                          >
                            <img
                              style={{ width: "12px", height: "12px" }}
                              src={uploadIcon}
                              alt=""
                            />
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "start",
                                justifyContent: "center",
                                flexDirection: "column",
                                gap: "8px",
                              }}
                            >
                              <Typography
                                sx={{
                                  textAlign: "start",
                                  fontFamily: "Lato",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 600,
                                  lineHeight: "14.4px",
                                  color: "#003D86",
                                }}
                              >
                                Upload from computer{" "}
                                <span style={{ color: "#B5BBC5" }}>|</span>{" "}
                                Paste Image URL
                              </Typography>
                              <Typography
                                sx={{
                                  textAlign: "start",
                                  color: "#7E8794",
                                  fontFamily: "Lato",
                                  fontSize: "10px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "12px",
                                }}
                              >
                                Maximum 2MB. Upload .jpeg or .png, files.
                                Aspect Ratio 1:1
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              textAlign: "start",
                              fontFamily: "Lato",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "14.4px",
                              color: "#003D86",
                              textDecoration: "underline",
                              display: pathname.includes("preview")
                                ? "none"
                                : "block",
                            }}
                          >
                            Or select from presets
                          </Typography>
                          <Box
                            sx={{
                              position: "relative",
                              width: "80px",
                              height: "30px",
                            }}
                          >
                            {field?.options?.length > 0 &&
                              field?.options
                                ?.slice(0, 3)
                                ?.map((image, index) => (
                                  <img
                                    key={index}
                                    src={image?.url}
                                    alt=""
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      borderRadius: "4px",
                                      position: "absolute",
                                      left: `${index * 15}px`, // This controls the overlap; adjust as needed
                                      zIndex: index,
                                    }}
                                  />
                                ))}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      {getImageUploadErrorMessage(field) && (
                        <Typography
                          sx={{
                            color: "#FF4242",
                            fontSize: "10px",
                            fontFamily: "Lato",
                            marginTop: "4px",
                            textAlign: "start",
                          }}
                        >
                          {getImageUploadErrorMessage(field)}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
                ):field?.input_type === "text_area" ? (
                  <Box
                    sx={{
                      mb: errorMessageObject?.[
                        field?.backend_label
                      ]
                        ? "10px"
                        : "0px",
                    }}
                  >
                    <RichEditor
                      isDisable={showEdit}
                      value={
                        data?.quiz?.[gameDataName]?.[field?.backend_label] || ""
                      }
                      onChange={(event) =>
                        handleBeforePlayChange(field?.backend_label, event)
                      }
                      editBoolean={
                        changedData?.[field?.backend_label] &&
                        changedData?.[field?.backend_label] !==
                          data?.quiz?.[gameDataName]?.[field?.backend_label]
                      }
                      errorMessage={errorMessageObject?.[field?.backend_label]}
                    />
                  </Box>)
                 : (
                  ""
                )}
              </Box>
            );
          })}
      </Box>
    </Box>
  );
}
