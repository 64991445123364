import "./App.css";
import AllRoutes from "./components/AllRoutes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { loginRequest } from './authConfig';

/**
 * Main application component that wraps the entire app with the MSAL provider
 * for authentication functionality.
 */
function App({ instance }) {
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  const CreateOfferUrl = process.env.REACT_APP_OFFER_SUCCESS_URL;

  return (
    <MsalProvider instance={instance}>
      <div
        className="App"
        style={{
          background: "#F7F9FC",
          height: "100vh",
        }}
      >
        
        {/* Show authenticated content only when user is signed in */}
        <AllRoutes />
        {/* <AuthenticatedTemplate>
          <AllRoutes />
        </AuthenticatedTemplate> */}
        
        {/* Show unauthenticated content when user is not signed in */}
        {/* <UnauthenticatedTemplate>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div style={{ textAlign: 'center' }}>
              <h2>Welcome to Gratify Admin</h2>
              <p>Please sign in to access the admin portal</p>
              <button 
                className="btn btn-primary"
                onClick={() => instance.loginRedirect(loginRequest)}
              >
                Sign in
              </button>
            </div>
          </div>
        </UnauthenticatedTemplate> */}
      </div>
    </MsalProvider>
  );
}

export default App;
