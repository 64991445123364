import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import tickImage from "../../../../assets/whiteTick.png";
import { useDispatch, useSelector } from "react-redux";
import { CreateGame_PreData } from "../../../../utils/CreateGame_PreData";
import BoldLabel from "../../../../UIComponents/BoldLevel";
import MuiInput from "../../../../UIComponents/MuiInput";
import ColorInput from "../../../../UIComponents/ColorInput";
import MuiRadioGroup from "../../../../UIComponents/MuiRadioGroup";
import SmallImageUpload from "../../../../UIComponents/SmallImageUpload";
import {
  setBeforePlayChange,
  setDrawerImageOption,
} from "../../../../Redux/OfferReducer/actionType";
import MuiSelectAndSearch from "../../../../UIComponents/MuiSelectAndSearch";
import RightDrawer from "./RightDrawer";
import axios from "axios";
import uploadIcon from "../../../../assets/UploadIcon.svg";
import { useLocation } from "react-router-dom";
import { getGameDataName } from "../../../../utils/gameDataName";
import { game_type } from "./Data";
import RichEditor from "../../../../UIComponents/RichEditor";

const isFieldDisabled =
  process.env.REACT_APP_DISABLE_BEFORE_AND_AFTER_PLAY_EDIT === "true";

export default function BeforePlayPage({
  showEdit = isFieldDisabled,
  validationObject,
  errorMessageObject,
}) {
  const { ctaColor, color } = CreateGame_PreData();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const token = useSelector((store) => store.AuthReducer.token);
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { pathname } = useLocation();
  //   to open drawer
  const handleDrawerOpen = (event, backend_level, options) => {
    event.stopPropagation();
    setDrawerOpen(true);
  };

  //   to close drawer
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const data = useSelector(
    (store) => store?.OfferReducer?.offerData?.createGame
  );
  const fields = useSelector((store) => store?.OfferReducer?.allGames)?.find(
    (e) => e?.id === data?.game_type_id
  )?.design_fields?.before_play;

  // const fields = game_type?.find(
  //   (e) => e?.id === data?.game_type_id
  // )?.design_fields?.before_play;

  const changedData = useSelector(
    (store) => store.OfferReducer?.changedOfferData?.game?.design?.before_play
  );

  function compareHexCodes(hex1 = "", hex2) {
    // Normalize hex codes by converting to lowercase and expanding shorthand format if necessary
    const normalizeHex = (hex) => {
      // Remove the '#' symbol if present
      hex = hex.replace("#", "");

      // Convert shorthand hex code (e.g., #ABC) to full form (e.g., #AABBCC)
      if (hex.length === 3) {
        hex = hex
          .split("")
          ?.map((char) => char + char)
          .join("");
      }

      // Return the hex code in lowercase for consistent comparison
      return hex.toLowerCase();
    };

    return normalizeHex(hex1) === normalizeHex(hex2);
  }

  // Example usage
  console.log(compareHexCodes("#ABC", "#AABBCC")); // true
  console.log(compareHexCodes("#FFF", "#FFFFFF")); // true
  console.log(compareHexCodes("#123456", "#654321")); // false

  const dispatch = useDispatch();
  // console.log(fields, "fields");
  // console.log(changedData, "previousData");
  const gameDataName = getGameDataName(data?.game_type_code);

  const handleBeforePlayChange = (field, value, additionalFields = {}) => {
    if (field.endsWith("_upload")) {
      const formData = new FormData();
      formData.append("file", value);
      axios
        .post(`${BaseUrl}/api/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("Upload successful", response.data);
          const baseField = field.replace("_upload", "");
          const payload = {
            gameDataName,
            field,
            value,
            additionalFields: {
              ...additionalFields,
              [`${baseField}_upload_url`]: response.data.public_url,
              [`${baseField}_upload_id`]: response.data.id,
              [baseField]: response.data.public_url,
            },
          };
          dispatch({ type: setBeforePlayChange, payload });
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
    } else {
      const payload = {
        gameDataName,
        field,
        value,
        additionalFields,
      };
      dispatch({ type: setBeforePlayChange, payload });
    }
  };
  const getColorPickerBackendLabel = (field) => {
    return field?.options?.find((o) => o.value === "color")?.backend_label;
  };

  const getColorPickerErrorMessage = (field) => {
    const colorBackendLabel = getColorPickerBackendLabel(field);
    return errorMessageObject?.[colorBackendLabel];
  };

  const getImageUploadBackendLabel = (field) => {
    if (
      field?.backend_label &&
      data?.beforePlay?.[gameDataName]?.[field?.backend_label]
    ) {
      return data?.beforePlay?.[gameDataName]?.[field?.backend_label]
        ?.backend_label;
    }
    return field?.backend_label;
  };

  const getImageUploadErrorMessage = (field) => {
    const imageBackendLabel = getImageUploadBackendLabel(field);
    return errorMessageObject?.[imageBackendLabel];
  };

  console.log(data, "validationObject");

  let num = 1;

  return (
    <Box>
      <Typography
        sx={{
          color: "#1D1F2C",
          fontFamily: "Lato",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "16px",
          textTransform: "capitalize",
          textAlign: "left",
          my: "12px",
        }}
      >
        Add Details
      </Typography>

      <RightDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
        handleSelectImage={handleBeforePlayChange}
      />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "start",
          gap: "20px",
        }}
      >
        {fields?.length > 0 &&
          fields?.map((field, index) => {
            if (!field?.mandatory) return null;
            const labelNum = num++;
            validationObject[field?.backend_label] = {
              mandatory: field?.mandatory,
            };
            if (field?.backend_label === "scratch_card") {
              validationObject[
                data?.beforePlay?.[gameDataName]?.[
                  field?.backend_label
                ]?.backend_label
              ] = {
                mandatory: true,
              };
              // console.log(  "scratch_card");
            }
            return (
              <Box
                key={field?.backend_label}
                sx={{
                  // mt: index > 0 ? "20px" : "0px",
                  width: field?.style?.width
                    ? field?.style?.width
                    : "calc(96% + 20px)",
                }}
              >
                <BoldLabel label={`${field?.label}`} mt="0px" isRequired={field?.mandatory} />
                {field?.input_type === "dropdown" ? (
                  <Box>
                    <MuiSelectAndSearch
                      isDisable={showEdit}
                      options={field?.options}
                      value={
                        data?.beforePlay?.[gameDataName]?.[
                          field?.backend_label
                        ] || ""
                      }
                      onChange={(event) =>
                        handleBeforePlayChange(field?.backend_label, event)
                      }
                      placeholder="--Select--"
                      editBoolean={
                        changedData?.[field?.backend_label] &&
                        changedData?.[field?.backend_label]?.id !==
                          data?.beforePlay?.[gameDataName]?.[
                            field?.backend_label
                          ]?.id
                      }
                      errorMessage={errorMessageObject?.[field?.backend_label]}
                    />
                  </Box>
                ) : field?.input_type === "text_area" ? (
                  <Box>
                    <RichEditor
                      isDisable={showEdit}
                      placeholder={field?.placeholder}
                      value={
                        data?.beforePlay?.[gameDataName]?.[
                          field?.backend_label
                        ] || ""
                      }
                      onChange={(event) =>
                        handleBeforePlayChange(field?.backend_label, event)
                      }
                      editBoolean={
                        changedData?.[field?.backend_label] &&
                        changedData?.[field?.backend_label] !==
                          data?.beforePlay?.[gameDataName]?.[field?.backend_label]
                      }
                      errorMessage={errorMessageObject?.[field?.backend_label]}
                    />
                  </Box>
                ):
                field?.input_type === "select_number" ? (
                  <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    mt: '8px'
                  }}
                >
                  {field?.options?.map((spin, spinIndex) => {
                    const isSelected = data?.beforePlay?.[gameDataName]?.[field?.backend_label] === spin.id;
                    return (
                      <Button
                        key={spin.id}
                        onClick={() => handleBeforePlayChange(field?.backend_label, spin.id)}
                        sx={{
                          width: '59px',
                          height: '30px',
                          padding: '12px',
                          border: isSelected ? "1px solid #9CBFEB" : '1px solid #E0E2E7',
                          borderRadius: '6px',
                          backgroundColor: isSelected ? '#EBF0FF' : 'white',
                          color: "#1D1F2C",
                          fontFamily: "Lato",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "16px",
                          '&:hover': {
                            backgroundColor: isSelected ? '#E8F0F9' : 'white',
                          },
                        }}
                      >
                        {spin.name}
                      </Button>
                    );
                  })}
                </Box>
                )
                 : field?.input_type === "input_box" ? (
                  <Box>
                    <MuiInput
                      maxLength={field?.max_characters}
                      disable={showEdit}
                      placeholder={field?.placeholder}
                      value={
                        data?.beforePlay?.[gameDataName]?.[
                          field?.backend_label
                        ] || ""
                      }
                      onChange={(event) =>
                        handleBeforePlayChange(field?.backend_label, event)
                      }
                      editBoolean={
                        changedData?.[field?.backend_label] &&
                        changedData?.[field?.backend_label] !==
                          data?.beforePlay?.[gameDataName]?.[
                            field?.backend_label
                          ]
                      }
                      errorMessage={errorMessageObject?.[field?.backend_label]}
                    />
                  </Box>
                ) : field?.input_type === "color_picker" ? (
                  <Box>
                    <ColorInput
                      preFill={field?.default}
                      // disable={showEdit}
                      disable={true}
                      value={
                        data?.beforePlay?.[gameDataName]?.[field?.backend_label]
                      }
                      onChange={(color) =>
                        handleBeforePlayChange(field?.backend_label, color)
                      }
                      editBoolean={
                        changedData?.[field?.backend_label] &&
                        !compareHexCodes(
                          changedData?.[field?.backend_label],
                          data?.beforePlay?.[gameDataName]?.[
                            field?.backend_label
                          ]
                        )
                      }
                      errorMessage={errorMessageObject?.[field?.backend_label]}
                    />
                    {field?.description && (
                      <Typography
                        sx={{
                          color: "#858D98",
                          fontFamily: "Lato",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "16px",
                          mt: "8px",
                          textAlign: "left",
                        }}
                      >
                        {field?.description}
                      </Typography>
                    )}
                  </Box>
                ) : field?.input_type === "image_upload" ? (
                  <Box mt={"10px"} >
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                          gap: "15px",
                          borderRadius: "8px",
                          border:
                            changedData?.[field?.backend_label] &&
                            changedData?.[field?.backend_label] !==
                              data?.beforePlay?.[gameDataName]?.[
                                field?.backend_label
                              ]
                              ? "1px solid orange"
                              : getImageUploadErrorMessage(field)
                              ? "1px solid #FF4242"
                              : "none",
                        }}
                      >
                        <Box
                          sx={{
                            borderRadius: "8px",
                            cursor: "pointer",
                            // px: "27px",
                            padding: "14px 15px",
                            // py: "42px",
                            height: "80px",
                            backgroundColor: "#F9F9FC",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "15px",
                            width: "100%",
                          }}
                          onClick={(event) => {
                            if (showEdit) return;
                            handleDrawerOpen(event);
                            dispatch({
                              type: setDrawerImageOption,
                              payload: {
                                backend_label: field?.backend_label,
                                image_option: field?.options,
                                gameDataName: gameDataName,
                                game_type: "beforePlay",
                                action_type: setBeforePlayChange,
                              },
                            });
                          }}
                        >
                          {data?.beforePlay?.[gameDataName]?.[
                            field?.backend_label
                          ] ? (
                            <Box
                              sx={{
                                display: "flex",

                                gap: "8px",
                              }}
                            >
                              <img
                                style={{
                                  borderRadius: "6px",
                                  height: "65px",
                                  width: "65px",
                                }}
                                src={
                                  data?.beforePlay?.[gameDataName]?.[
                                    field?.backend_label
                                  ]
                                }
                                alt=""
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "flex-start",
                                  gap: 1,
                                }}
                              >
                                <Typography
                                  // onClick={handleSelectImageClick}
                                  sx={{
                                    cursor: "pointer",
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    fontFamily: "Lato",
                                    lineHeight: "14.4px",
                                    color: "#29CC6A",
                                    textDecoration: "underline",
                                    display: pathname.includes("preview")
                                      ? "none"
                                      : "block",
                                  }}
                                >
                                  Change
                                </Typography>
                                <Typography
                                  sx={{
                                    fontFamily: "Lato",
                                    fontSize: "10px",
                                    fontWeight: 400,
                                    lineHeight: "12px",
                                    color: "#7E8794",
                                    textAlign: "left",
                                  }}
                                >
                                  {data?.beforePlay?.[gameDataName]?.[
                                    field?.backend_label
                                  ]
                                    ? "Image Uploaded"
                                    : "No image selected"}
                                </Typography>
                              </Box>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "15px",
                              }}
                            >
                              <img
                                style={{ width: "12px", height: "12px" }}
                                src={uploadIcon}
                                alt=""
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "start",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  gap: "8px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    textAlign: "start",
                                    fontFamily: "Lato",
                                    fontSize: "12px",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "14.4px",
                                    color: "#003D86",
                                  }}
                                >
                                  Upload from computer{" "}
                                  <span style={{ color: "#B5BBC5" }}>|</span>{" "}
                                  Paste Image URL
                                </Typography>
                                <Typography
                                  sx={{
                                    textAlign: "start",
                                    color: "#7E8794",
                                    fontFamily: "Lato",
                                    fontSize: "10px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "12px",
                                  }}
                                >
                                  Maximum 2MB. Upload .jpeg or .png, files.
                                  Aspect Ratio 1:1
                                </Typography>
                              </Box>
                            </Box>
                          )}
                          <Box
                            sx={{
                              display: "flex",
                              gap: "8px",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                textAlign: "start",
                                fontFamily: "Lato",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "14.4px",
                                color: "#003D86",
                                textDecoration: "underline",
                                display: pathname.includes("preview")
                                  ? "none"
                                  : "block",
                              }}
                            >
                              Or select from presets
                            </Typography>
                            <Box
                              sx={{
                                position: "relative",
                                width: "80px",
                                height: "30px",
                              }}
                            >
                              {field?.options?.length > 0 &&
                                field?.options
                                  ?.slice(0, 3)
                                  ?.map((image, index) => (
                                    <img
                                      key={index}
                                      src={image?.url}
                                      alt=""
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        borderRadius: "4px",
                                        position: "absolute",
                                        left: `${index * 15}px`, // This controls the overlap; adjust as needed
                                        zIndex: index,
                                      }}
                                    />
                                  ))}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        {getImageUploadErrorMessage(field) && (
                          <Typography
                            sx={{
                              color: "#FF4242",
                              fontSize: "10px",
                              fontFamily: "Lato",
                              marginTop: "4px",
                              textAlign: "start",
                            }}
                          >
                            {getImageUploadErrorMessage(field)}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
              </Box>
            );
          })}
      </Box>
    </Box>
  );
}
