export const reward_type = [
    {
        "id": 8,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/a30c64_Cash back icon.png",
        "name": "CASHBACK",
        "code": "cashback",
        "reward_details": [
            {
                "label": "Select Reward Type",
                "options": [
                    {
                        "id": "better_luck_next_time",
                        "name": "Better Luck Next Time"
                    },
                    {
                        "id": "voucher",
                        "name": "Voucher"
                    },
                    {
                        "id": "neu_coin",
                        "name": "NeuCoins"
                    },
                    {
                        "id": "digital_gold",
                        "name": "Digital Gold"
                    },
                    {
                        "id": "cashback",
                        "name": "Cashback"
                    },
                    {
                        "id": "promo_neu_coin",
                        "name": "PROMO NEUCOINS"
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "reward_type"
            },
            {
                "label": "Select Cashback Type",
                "options": [
                    {
                        "label": "Fixed Cashback",
                        "value": "FIXED_CASHBACK",
                        "fields": [
                            {
                                "label": "Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "reward_value"
                            }
                        ]
                    },
                    {
                        "label": "Dynamic Cashback",
                        "value": "DYNAMIC_CASHBACK",
                        "fields": [
                            {
                                "label": "Min Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "min"
                            },
                            {
                                "label": "Max Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "max"
                            },
                            {
                                "label": "Avg Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "mean"
                            },
                            {
                                "label": "Std Deviation",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "standard_deviation"
                            }
                        ]
                    },
                    {
                        "label": "% Cashback",
                        "value": "PERCENTAGE_CASHBACK",
                        "fields": [
                            {
                                "label": "Percentage",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "percentage"
                            },
                            {
                                "label": "Max Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "max_value"
                            }
                        ]
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "cashback_type"
            },
            {
                "label": "Reward Value",
                "mandatory": true,
                "input_type": "array",
                "backend_label": "value"
            },
            {
                "label": "Reward Title",
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "title"
            },
            {
                "label": "Choose A Reward Icon",
                "options": [
                    "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                    "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                    "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww",
                    "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                    "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww"
                ],
                "mandatory": true,
                "input_type": "image_upload",
                "backend_label": "reward_icon"
            },
            {
                "mandatory": false,
                "input_type": "reward_limit",
                "limitOptions": {
                    "limitTypeOptions": [
                        {
                            "id": "reward_count",
                            "name": "No. of Redemptions"
                        }
                    ],
                    "limitLevelOptions": [
                        {
                            "id": "product",
                            "name": "Product"
                        },
                        {
                            "id": "user",
                            "name": "User"
                        }
                    ]
                },
                "backend_label": "reward_limit"
            }
        ],
        "design_details": {},
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "created_at": "2024-07-31 07:34:34",
        "last_modified_at": "2025-01-16 09:45:37"
    },
    {
        "id": 9,
        "icon": "",
        "name": "Promotions",
        "code": "voucher",
        "reward_details": [
            {
                "label": "Reward",
                "style": {
                    "width": "23%"
                },
                "options": [
                    {
                        "id": "better_luck_next_time",
                        "name": "Better Luck Next Time"
                    },
                    {
                        "id": "voucher",
                        "name": "Voucher"
                    },
                    {
                        "id": "neu_coin",
                        "name": "NeuCoins"
                    },
                    {
                        "id": "digital_gold",
                        "name": "Digital Gold"
                    },
                    {
                        "id": "cashback",
                        "name": "Cashback"
                    },
                    {
                        "id": "promo_neu_coin",
                        "name": "PROMO NEUCOINS"
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "reward_type"
            },
            {
                "type": "number",
                "label": "Win Probability",
                "style": {
                    "width": "23%"
                },
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "win_probability"
            },
            {
                "type": "string",
                "label": "Program Id",
                "style": {
                    "width": "23%"
                },
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "program_id"
            },
            {
                "type": "string",
                "label": "Offer Id",
                "style": {
                    "width": "23%"
                },
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "oe_offer_id"
            },
            {
                "mandatory": false,
                "input_type": "reward_limit",
                "limitOptions": {
                    "limitTypeOptions": [
                        {
                            "id": "reward_value",
                            "name": "Value"
                        },
                        {
                            "id": "reward_count",
                            "name": "No. of Redemptions"
                        }
                    ],
                    "limitLevelOptions": [
                        {
                            "id": "product",
                            "name": "Product"
                        },
                        {
                            "id": "user",
                            "name": "User"
                        }
                    ]
                },
                "backend_label": "reward_limit"
            }
        ],
        "design_details": {
            "6": {
                "after_play": [
                    {
                        "label": "Banner Image",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "banner_image"
                    },
                    {
                        "label": "Currency logo",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image"
                    },
                    {
                        "label": "Title",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "backend_label": "title"
                    },
                    {
                        "label": "Title Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "title_colour"
                    },
                    {
                        "label": "Subtitle",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "backend_label": "subtitle"
                    },
                    {
                        "label": "Subtitle Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "reward_value_color"
                    },
                    {
                        "label": "Get code cta text",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "Get Code",
                        "backend_label": "get_code_cta_text"
                    },
                    {
                        "label": "Redeem now cta text",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "Redeem Now",
                        "backend_label": "redeem_now_cta_text"
                    },
                    {
                        "label": "Redeem now link",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "https://www.sample.com/",
                        "backend_label": "redeem_now_link"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "after_play_details_title",
                                "max_characters": 50
                            },
                            {
                                "label": "Description",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "after_play_details_description",
                                "max_characters": 100
                            },
                            {
                                "label": "How to Redeem",
                                "mandatory": true,
                                "input_type": "text_area",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "how_to_redeem",
                                "max_characters": 200
                            },
                            {
                                "label": "Offer T&C",
                                "mandatory": true,
                                "input_type": "text_area",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "tnc",
                                "max_characters": 200
                            }
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "before_play": [
                    {
                        "label": "Reward",
                        "style": {
                            "width": "48%"
                        },
                        "options": [
                            {
                                "id": "better_luck_next_time",
                                "name": "Better Luck Next Time"
                            },
                            {
                                "id": "voucher",
                                "name": "Voucher"
                            },
                            {
                                "id": "neu_coin",
                                "name": "Neucoins"
                            },
                            {
                                "id": "digital_gold",
                                "name": "Digital Gold"
                            },
                            {
                                "id": "cashback",
                                "name": "Cashback"
                            },
                            {
                                "id": "promo_neu_coin",
                                "name": "PROMO NEUCOINS"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "dropdown",
                        "backend_label": "before_play_reward"
                    },
                    {
                        "label": "Spoke Title",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "eg : Get 100 Neucoins",
                        "backend_label": "spoke_text"
                    },
                    {
                        "label": "Spoke Title Color",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "before_play_spoke_title_color"
                    },
                    {
                        "label": "Merchant Logo",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "before_play_merchant_logo"
                    }
                ]
            },
            "9": {
                "after_play": [
                    {
                        "label": "Currency logo",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image"
                    },
                    {
                        "label": "Title",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "backend_label": "title"
                    },
                    {
                        "label": "Title Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "title_colour"
                    },
                    {
                        "label": "Subtitle",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "backend_label": "subtitle"
                    },
                    {
                        "label": "Subtitle Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "reward_value_color"
                    },
                    {
                        "label": "Get code cta text",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "Get Code",
                        "backend_label": "get_code_cta_text"
                    },
                    {
                        "label": "Redeem now cta text",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "Redeem Now",
                        "backend_label": "redeem_now_cta_text"
                    },
                    {
                        "label": "Redeem now link",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "https://www.sample.com/",
                        "backend_label": "redeem_now_link"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "after_play_details_title",
                                "max_characters": 50
                            },
                            {
                                "label": "Description",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "after_play_details_description",
                                "max_characters": 100
                            },
                            {
                                "label": "How to Redeem",
                                "mandatory": true,
                                "input_type": "text_area",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "how_to_redeem",
                                "max_characters": 200
                            },
                            {
                                "label": "Offer T&C",
                                "mandatory": true,
                                "input_type": "text_area",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "tnc",
                                "max_characters": 200
                            }
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "before_play": [
                    {
                        "label": "Cover Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "cover_image"
                    }
                ]
            }
        },
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "created_at": "2024-07-31 07:35:03",
        "last_modified_at": "2025-02-26 10:44:55"
    },
    {
        "id": 10,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f1e32a_neucoin_icon.png",
        "name": "NeuCoins",
        "code": "neu_coin",
        "reward_details": [
            {
                "label": "Reward",
                "style": {
                    "width": "23%"
                },
                "options": [
                    {
                        "id": "better_luck_next_time",
                        "name": "Better Luck Next Time"
                    },
                    {
                        "id": "voucher",
                        "name": "Voucher"
                    },
                    {
                        "id": "neu_coin",
                        "name": "NeuCoins"
                    },
                    {
                        "id": "digital_gold",
                        "name": "Digital Gold"
                    },
                    {
                        "id": "cashback",
                        "name": "Cashback"
                    },
                    {
                        "id": "promo_neu_coin",
                        "name": "PROMO NEUCOINS"
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "reward_type"
            },
            {
                "type": "number",
                "label": "Win Probability",
                "style": {
                    "width": "23%"
                },
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "win_probability"
            },
            {
                "type": "string",
                "label": "Program Id",
                "style": {
                    "width": "23%"
                },
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "program_id"
            },
            {
                "label": "Cashback Type",
                "style": {
                    "width": "23%"
                },
                "options": [
                    {
                        "label": "Fixed",
                        "value": "FIXED_CASHBACK",
                        "fields": [
                            {
                                "type": "number",
                                "label": "Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "reward_value"
                            }
                        ]
                    },
                    {
                        "label": "Dynamic Cashback",
                        "value": "DYNAMIC_CASHBACK",
                        "fields": [
                            {
                                "type": "number",
                                "label": "Min. Value",
                                "style": {
                                    "width": "22%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "min"
                            },
                            {
                                "type": "number",
                                "label": "Max. Value",
                                "style": {
                                    "width": "22%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "max"
                            },
                            {
                                "type": "number",
                                "label": "Avg. Value",
                                "style": {
                                    "width": "22%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "mean"
                            },
                            {
                                "type": "number",
                                "label": "Std. Deviation",
                                "style": {
                                    "width": "22%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "standard_deviation"
                            }
                        ]
                    },
                    {
                        "label": "Percentage",
                        "value": "PERCENTAGE_CASHBACK",
                        "fields": [
                            {
                                "type": "number",
                                "label": "Percentage",
                                "style": {
                                    "width": "48%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "percentage"
                            },
                            {
                                "type": "number",
                                "label": "Max. Value",
                                "style": {
                                    "width": "48%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "max_value"
                            }
                        ]
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "cashback_type"
            },
            {
                "label": "Reward Value",
                "mandatory": true,
                "input_type": "array",
                "backend_label": "value"
            },
            {
                "mandatory": false,
                "input_type": "reward_limit",
                "limitOptions": {
                    "limitTypeOptions": [
                        {
                            "id": "reward_value",
                            "name": "Value"
                        },
                        {
                            "id": "reward_count",
                            "name": "No. of Redemptions"
                        }
                    ],
                    "limitLevelOptions": [
                        {
                            "id": "product",
                            "name": "Product"
                        },
                        {
                            "id": "user",
                            "name": "User"
                        }
                    ]
                },
                "backend_label": "reward_limit"
            }
        ],
        "design_details": {
            "6": {
                "after_play": [
                    {
                        "label": "Banner Image",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "banner_image"
                    },
                    {
                        "label": "Currency Logo",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image"
                    },
                    {
                        "label": "Title Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "title_colour"
                    },
                    {
                        "label": "Reward Value Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "reward_value_color"
                    },
                    {
                        "label": "Go back cta text",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "Go Back",
                        "backend_label": "go_back_cta_text"
                    },
                    {
                        "label": "Start shopping cta text",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "Start Shopping",
                        "backend_label": "start_shopping_cta_text"
                    },
                    {
                        "label": "Start shopping link",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "https://www.sample.com/",
                        "backend_label": "start_shopping_link"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "title",
                                "max_characters": 50
                            },
                            {
                                "label": "Description",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "description",
                                "max_characters": 100
                            }
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "before_play": [
                    {
                        "label": "Reward",
                        "style": {
                            "width": "48%"
                        },
                        "options": [
                            {
                                "id": "better_luck_next_time",
                                "name": "Better Luck Next Time"
                            },
                            {
                                "id": "voucher",
                                "name": "Voucher"
                            },
                            {
                                "id": "neu_coin",
                                "name": "Neucoins"
                            },
                            {
                                "id": "digital_gold",
                                "name": "Digital Gold"
                            },
                            {
                                "id": "cashback",
                                "name": "Cashback"
                            },
                            {
                                "id": "promo_neu_coin",
                                "name": "PROMO NEUCOINS"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "dropdown",
                        "backend_label": "before_play_reward"
                    },
                    {
                        "label": "Reward Color",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "before_play_reward_color"
                    },
                    {
                        "label": "Title Color",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "before_play_title_color"
                    },
                    {
                        "label": "Logo",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "before_play_logo"
                    }
                ]
            },
            "9": {
                "after_play": [
                    {
                        "label": "Currency Logo",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image"
                    },
                    {
                        "label": "Title Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "title_colour"
                    },
                    {
                        "label": "Reward Value Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "reward_value_color"
                    },
                    {
                        "label": "Go back cta text",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "Go Back",
                        "backend_label": "go_back_cta_text"
                    },
                    {
                        "label": "Start shopping cta text",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "Start Shopping",
                        "backend_label": "start_shopping_cta_text"
                    },
                    {
                        "label": "Start shopping link",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "https://www.sample.com/",
                        "backend_label": "start_shopping_link"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "title",
                                "max_characters": 50
                            },
                            {
                                "label": "Description",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "description",
                                "max_characters": 100
                            }
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "before_play": [
                    {
                        "label": "Cover Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "cover_image"
                    }
                ]
            }
        },
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "created_at": "2024-07-31 07:36:28",
        "last_modified_at": "2025-02-24 11:57:23"
    },
    {
        "id": 11,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/d5603e_digital_gold_icon.png",
        "name": "Digital Gold",
        "code": "digital_gold",
        "reward_details": [
            {
                "label": "Reward",
                "style": {
                    "width": "23%"
                },
                "options": [
                    {
                        "id": "better_luck_next_time",
                        "name": "Better Luck Next Time"
                    },
                    {
                        "id": "voucher",
                        "name": "Voucher"
                    },
                    {
                        "id": "neu_coin",
                        "name": "NeuCoins"
                    },
                    {
                        "id": "digital_gold",
                        "name": "Digital Gold"
                    },
                    {
                        "id": "cashback",
                        "name": "Cashback"
                    },
                    {
                        "id": "promo_neu_coin",
                        "name": "PROMO NEUCOINS"
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "reward_type"
            },
            {
                "type": "number",
                "label": "Win Probability",
                "style": {
                    "width": "23%"
                },
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "win_probability"
            },
            {
                "type": "string",
                "label": "Program Id",
                "style": {
                    "width": "23%"
                },
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "program_id"
            },
            {
                "label": "Cashback Type",
                "style": {
                    "width": "23%"
                },
                "options": [
                    {
                        "label": "Fixed",
                        "value": "FIXED_CASHBACK",
                        "fields": [
                            {
                                "type": "number",
                                "label": "Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "reward_value"
                            }
                        ]
                    },
                    {
                        "label": "Dynamic Cashback",
                        "value": "DYNAMIC_CASHBACK",
                        "fields": [
                            {
                                "type": "number",
                                "label": "Min. Value",
                                "style": {
                                    "width": "22%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "min"
                            },
                            {
                                "type": "number",
                                "label": "Max. Value",
                                "style": {
                                    "width": "22%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "max"
                            },
                            {
                                "type": "number",
                                "label": "Avg. Value",
                                "style": {
                                    "width": "22%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "mean"
                            },
                            {
                                "type": "number",
                                "label": "Std. Deviation",
                                "style": {
                                    "width": "22%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "standard_deviation"
                            }
                        ]
                    },
                    {
                        "label": "Percentage",
                        "value": "PERCENTAGE_CASHBACK",
                        "fields": [
                            {
                                "type": "number",
                                "label": "Percentage",
                                "style": {
                                    "width": "48%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "percentage"
                            },
                            {
                                "type": "number",
                                "label": "Max. Value",
                                "style": {
                                    "width": "48%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "max_value"
                            }
                        ]
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "cashback_type"
            },
            {
                "label": "Reward Value",
                "mandatory": true,
                "input_type": "array",
                "backend_label": "value"
            },
            {
                "mandatory": false,
                "input_type": "reward_limit",
                "limitOptions": {
                    "limitTypeOptions": [
                        {
                            "id": "reward_value",
                            "name": "Value"
                        },
                        {
                            "id": "reward_count",
                            "name": "No. of Redemptions"
                        }
                    ],
                    "limitLevelOptions": [
                        {
                            "id": "product",
                            "name": "Product"
                        },
                        {
                            "id": "user",
                            "name": "User"
                        }
                    ]
                },
                "backend_label": "reward_limit"
            }
        ],
        "design_details": {
            "6": {
                "after_play": [
                    {
                        "label": "Banner Image",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "banner_image"
                    },
                    {
                        "label": "Currency Logo",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image"
                    },
                    {
                        "label": "Title Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "title_colour"
                    },
                    {
                        "label": "Reward Value Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "reward_value_color"
                    },
                    {
                        "label": "Go back cta text",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "Go Back",
                        "backend_label": "go_back_cta_text"
                    },
                    {
                        "label": "Start investing cta text",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "Start Investing",
                        "backend_label": "start_investing_cta_text"
                    },
                    {
                        "label": "Start investing link",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "https://www.sample.com/",
                        "backend_label": "start_investing_link"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "title",
                                "max_characters": 50
                            },
                            {
                                "label": "Description",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "description",
                                "max_characters": 100
                            }
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "before_play": [
                    {
                        "label": "Reward",
                        "style": {
                            "width": "48%"
                        },
                        "options": [
                            {
                                "id": "better_luck_next_time",
                                "name": "Better Luck Next Time"
                            },
                            {
                                "id": "voucher",
                                "name": "Voucher"
                            },
                            {
                                "id": "neu_coin",
                                "name": "Neucoins"
                            },
                            {
                                "id": "digital_gold",
                                "name": "Digital Gold"
                            },
                            {
                                "id": "cashback",
                                "name": "Cashback"
                            },
                            {
                                "id": "promo_neu_coin",
                                "name": "PROMO NEUCOINS"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "dropdown",
                        "backend_label": "before_play_reward"
                    },
                    {
                        "label": "Reward Color",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "before_play_reward_color"
                    },
                    {
                        "label": "Title Color",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "before_play_title_color"
                    },
                    {
                        "label": "Logo",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "before_play_logo"
                    }
                ]
            },
            "9": {
                "after_play": [
                    {
                        "label": "Currency Logo",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image"
                    },
                    {
                        "label": "Title Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "title_colour"
                    },
                    {
                        "label": "Reward Value Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "reward_value_color"
                    },
                    {
                        "label": "Go back cta text",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "Go Back",
                        "backend_label": "go_back_cta_text"
                    },
                    {
                        "label": "Start investing cta text",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "Start Investing",
                        "backend_label": "start_investing_cta_text"
                    },
                    {
                        "label": "Start investing link",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "https://www.sample.com/",
                        "backend_label": "start_investing_link"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "title",
                                "max_characters": 50
                            },
                            {
                                "label": "Description",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "description",
                                "max_characters": 100
                            }
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "before_play": [
                    {
                        "label": "Cover Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "cover_image"
                    }
                ]
            }
        },
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "created_at": "2024-07-31 07:36:40",
        "last_modified_at": "2025-02-24 11:57:23"
    },
    {
        "id": 12,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/After.png",
        "name": "BETTER LUCK NEXT TIME",
        "code": "bl_next_time",
        "reward_details": [
            {
                "label": "Reward",
                "style": {
                    "width": "23%"
                },
                "options": [
                    {
                        "id": "better_luck_next_time",
                        "name": "Better Luck Next Time"
                    },
                    {
                        "id": "voucher",
                        "name": "Voucher"
                    },
                    {
                        "id": "neu_coin",
                        "name": "NeuCoins"
                    },
                    {
                        "id": "digital_gold",
                        "name": "Digital Gold"
                    },
                    {
                        "id": "cashback",
                        "name": "Cashback"
                    },
                    {
                        "id": "promo_neu_coin",
                        "name": "PROMO NEUCOINS"
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "reward_type"
            },
            {
                "type": "number",
                "label": "Win Probability",
                "style": {
                    "width": "23%"
                },
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "win_probability"
            }
        ],
        "design_details": {
            "6": {
                "after_play": [
                    {
                        "label": "Banner Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "banner_image"
                    },
                    {
                        "label": "Title",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "Better Luck Next Time!",
                        "backend_label": "title"
                    },
                    {
                        "label": "Description",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "Better Luck Next Time!",
                        "backend_label": "description"
                    },
                    {
                        "label": "Go back cta text",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "Go Back",
                        "backend_label": "go_back_cta_text"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "after_play_details_title",
                                "max_characters": 50
                            }
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "before_play": [
                    {
                        "label": "Reward",
                        "style": {
                            "width": "48%"
                        },
                        "options": [
                            {
                                "id": "better_luck_next_time",
                                "name": "Better Luck Next Time"
                            },
                            {
                                "id": "voucher",
                                "name": "Voucher"
                            },
                            {
                                "id": "neu_coin",
                                "name": "Neucoins"
                            },
                            {
                                "id": "digital_gold",
                                "name": "Digital Gold"
                            },
                            {
                                "id": "cashback",
                                "name": "Cashback"
                            },
                            {
                                "id": "promo_neu_coin",
                                "name": "PROMO NEUCOINS"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "dropdown",
                        "backend_label": "before_play_reward"
                    },
                    {
                        "label": "Spoke Title",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "eg : Get 100 Neucoins",
                        "backend_label": "spoke_text"
                    },
                    {
                        "label": "Spoke Title Color",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "before_play_spoke_title_color"
                    },
                    {
                        "label": "Logo",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "before_play_logo"
                    }
                ]
            },
            "9": {
                "after_play": [
                    {
                        "label": "Banner Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "banner_image"
                    },
                    {
                        "label": "Title",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "Better Luck Next Time!",
                        "backend_label": "title"
                    },
                    {
                        "label": "Description",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "Better Luck Next Time!",
                        "backend_label": "description"
                    },
                    {
                        "label": "Go back cta text",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "Go Back",
                        "backend_label": "go_back_cta_text"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "after_play_details_title",
                                "max_characters": 50
                            }
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "before_play": [
                    {
                        "label": "Cover Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "cover_image"
                    }
                ]
            }
        },
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "created_at": "2024-08-02 04:11:01",
        "last_modified_at": "2025-03-06 08:30:08"
    },
    {
        "id": 13,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/d5603e_digital_gold_icon.png",
        "name": "Promo Neucoins",
        "code": "promo_neu_coin",
        "reward_details": [
            {
                "label": "Reward",
                "style": {
                    "width": "23%"
                },
                "options": [
                    {
                        "id": "better_luck_next_time",
                        "name": "Better Luck Next Time"
                    },
                    {
                        "id": "voucher",
                        "name": "Voucher"
                    },
                    {
                        "id": "neu_coin",
                        "name": "NeuCoins"
                    },
                    {
                        "id": "digital_gold",
                        "name": "Digital Gold"
                    },
                    {
                        "id": "cashback",
                        "name": "Cashback"
                    },
                    {
                        "id": "promo_neu_coin",
                        "name": "PROMO NEUCOINS"
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "reward_type"
            },
            {
                "type": "number",
                "label": "Win Probability",
                "style": {
                    "width": "23%"
                },
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "win_probability"
            },
            {
                "type": "string",
                "label": "Program Id",
                "style": {
                    "width": "23%"
                },
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "program_id"
            },
            {
                "type": "string",
                "label": "Promo Identifier",
                "style": {
                    "width": "23%"
                },
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "promo_id"
            },
            {
                "mandatory": false,
                "input_type": "reward_limit",
                "limitOptions": {
                    "limitTypeOptions": [
                        {
                            "id": "reward_value",
                            "name": "Value"
                        },
                        {
                            "id": "reward_count",
                            "name": "No. of Redemptions"
                        }
                    ],
                    "limitLevelOptions": [
                        {
                            "id": "product",
                            "name": "Product"
                        },
                        {
                            "id": "user",
                            "name": "User"
                        }
                    ]
                },
                "backend_label": "reward_limit"
            }
        ],
        "design_details": {
            "6": {
                "after_play": [
                    {
                        "label": "Banner Image",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "banner_image"
                    },
                    {
                        "label": "Currency logo",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image_1"
                    },
                    {
                        "label": "Merchant Logo",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image_2"
                    },
                    {
                        "label": "Title",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "1000 Neucoins",
                        "backend_label": "title"
                    },
                    {
                        "label": "Title Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "title_colour"
                    },
                    {
                        "label": "Subtitle",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "on your next purchase",
                        "backend_label": "subtitle"
                    },
                    {
                        "label": "Subtitle Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "reward_value_color"
                    },
                    {
                        "label": "Start shopping link",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "https://www.sample.com/",
                        "backend_label": "start_shopping_link"
                    },
                    {
                        "label": "Start shopping cta text",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "Start Shopping",
                        "backend_label": "start_shopping_cta_text"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "after_play_details_title",
                                "max_characters": 50
                            },
                            {
                                "label": "Description",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "after_play_details_description",
                                "max_characters": 100
                            },
                            {
                                "label": "How to Redeem",
                                "mandatory": true,
                                "input_type": "text_area",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "how_to_redeem",
                                "max_characters": 200
                            }
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "before_play": [
                    {
                        "label": "Reward",
                        "style": {
                            "width": "48%"
                        },
                        "options": [
                            {
                                "id": "better_luck_next_time",
                                "name": "Better Luck Next Time"
                            },
                            {
                                "id": "voucher",
                                "name": "Voucher"
                            },
                            {
                                "id": "neu_coin",
                                "name": "Neucoins"
                            },
                            {
                                "id": "digital_gold",
                                "name": "Digital Gold"
                            },
                            {
                                "id": "cashback",
                                "name": "Cashback"
                            },
                            {
                                "id": "promo_neu_coin",
                                "name": "PROMO NEUCOINS"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "dropdown",
                        "backend_label": "before_play_reward"
                    },
                    {
                        "label": "Spoke Title",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "eg : Get 100 Neucoins",
                        "backend_label": "spoke_text"
                    },
                    {
                        "label": "Spoke Title Color",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "before_play_spoke_title_color"
                    },
                    {
                        "label": "Merchant Logo",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "before_play_merchant_logo"
                    }
                ]
            },
            "9": {
                "after_play": [
                    {
                        "label": "Currency logo",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image_1"
                    },
                    {
                        "label": "Merchant Logo",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image_2"
                    },
                    {
                        "label": "Title",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "1000 Neucoins",
                        "backend_label": "title"
                    },
                    {
                        "label": "Title Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "title_colour"
                    },
                    {
                        "label": "Subtitle",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "on your next purchase",
                        "backend_label": "subtitle"
                    },
                    {
                        "label": "Subtitle Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "reward_value_color"
                    },
                    {
                        "label": "Start shopping link",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "https://www.sample.com/",
                        "backend_label": "start_shopping_link"
                    },
                    {
                        "label": "Start shopping cta text",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "Start Shopping",
                        "backend_label": "start_shopping_cta_text"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "after_play_details_title",
                                "max_characters": 50
                            },
                            {
                                "label": "Description",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "after_play_details_description",
                                "max_characters": 100
                            },
                            {
                                "label": "How to Redeem",
                                "mandatory": true,
                                "input_type": "text_area",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "how_to_redeem",
                                "max_characters": 200
                            }
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "before_play": [
                    {
                        "label": "Cover Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "cover_image"
                    }
                ]
            }
        },
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "created_at": "2024-11-05 11:33:55",
        "last_modified_at": "2025-02-26 10:47:26"
    }
]

export const reward = {
    "rewardTypes": [
        {
            "id": 9,
            "icon": "",
            "name": "Promotions",
            "reward_details": [
                {
                    "label": "Reward",
                    "style": {
                        "width": "23%"
                    },
                    "options": [
                        {
                            "id": "better_luck_next_time",
                            "name": "Better Luck Next Time"
                        },
                        {
                            "id": "voucher",
                            "name": "Voucher"
                        },
                        {
                            "id": "neu_coin",
                            "name": "NeuCoins"
                        },
                        {
                            "id": "digital_gold",
                            "name": "Digital Gold"
                        },
                        {
                            "id": "cashback",
                            "name": "Cashback"
                        },
                        {
                            "id": "promo_neu_coin",
                            "name": "PROMO NEUCOINS"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "dropdown",
                    "backend_label": "reward_type"
                },
                {
                    "type": "number",
                    "label": "Win Probability",
                    "style": {
                        "width": "23%"
                    },
                    "mandatory": true,
                    "input_type": "input_box",
                    "backend_label": "win_probability"
                },
                {
                    "type": "string",
                    "label": "Program Id",
                    "style": {
                        "width": "23%"
                    },
                    "mandatory": true,
                    "input_type": "input_box",
                    "backend_label": "program_id"
                },
                {
                    "type": "string",
                    "label": "Offer Id",
                    "style": {
                        "width": "23%"
                    },
                    "mandatory": true,
                    "input_type": "input_box",
                    "backend_label": "oe_offer_id"
                },
                {
                    "mandatory": false,
                    "input_type": "reward_limit",
                    "limitOptions": {
                        "limitTypeOptions": [
                            {
                                "id": "reward_value",
                                "name": "Value"
                            },
                            {
                                "id": "reward_count",
                                "name": "No. of Redemptions"
                            }
                        ],
                        "limitLevelOptions": [
                            {
                                "id": "product",
                                "name": "Product"
                            },
                            {
                                "id": "user",
                                "name": "User"
                            }
                        ]
                    },
                    "backend_label": "reward_limit"
                }
            ],
            "design_details": {
                "spin_the_wheel": {
                    "after_play": [
                        {
                            "label": "Banner Image",
                            "options": [
                                {
                                    "id": 1579,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                                },
                                {
                                    "id": 1580,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                                },
                                {
                                    "id": 1581,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "banner_image"
                        },
                        {
                            "label": "Currency logo",
                            "options": [
                                {
                                    "id": 1579,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                                },
                                {
                                    "id": 1580,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                                },
                                {
                                    "id": 1581,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "logo_image"
                        },
                        {
                            "label": "Title",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "backend_label": "title"
                        },
                        {
                            "label": "Title Colour",
                            "style": {
                                "width": "48%"
                            },
                            "default": "#000000",
                            "mandatory": true,
                            "input_type": "color_picker",
                            "backend_label": "title_colour"
                        },
                        {
                            "label": "Subtitle",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "backend_label": "subtitle"
                        },
                        {
                            "label": "Subtitle Colour",
                            "style": {
                                "width": "48%"
                            },
                            "default": "#000000",
                            "mandatory": true,
                            "input_type": "color_picker",
                            "backend_label": "reward_value_color"
                        },
                        {
                            "label": "Get code cta text",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "Get Code",
                            "backend_label": "get_code_cta_text"
                        },
                        {
                            "label": "Redeem now cta text",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "Redeem Now",
                            "backend_label": "redeem_now_cta_text"
                        },
                        {
                            "label": "Redeem now link",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "https://www.sample.com/",
                            "backend_label": "redeem_now_link"
                        },
                        {
                            "label": "Scratch Card Image",
                            "options": [
                                {
                                    "id": 1582,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                                },
                                {
                                    "id": 1583,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                                },
                                {
                                    "id": 1584,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                                },
                                {
                                    "id": 1585,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                                },
                                {
                                    "id": 1586,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                                },
                                {
                                    "id": 1587,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                                },
                                {
                                    "id": 1588,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "scratch_card_image"
                        },
                        {
                            "label": "After Play Details",
                            "fields": [
                                {
                                    "label": "Title",
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "placeholder": "e.g : Congratulations!",
                                    "backend_label": "after_play_details_title",
                                    "max_characters": 50
                                },
                                {
                                    "label": "Description",
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "placeholder": "e.g : You have won 100 Neucoins",
                                    "backend_label": "after_play_details_description",
                                    "max_characters": 100
                                },
                                {
                                    "label": "How to Redeem",
                                    "mandatory": true,
                                    "input_type": "text_area",
                                    "placeholder": "e.g : You have won 100 Neucoins",
                                    "backend_label": "how_to_redeem",
                                    "max_characters": 200
                                },
                                {
                                    "label": "Offer T&C",
                                    "mandatory": true,
                                    "input_type": "text_area",
                                    "placeholder": "e.g : You have won 100 Neucoins",
                                    "backend_label": "tnc",
                                    "max_characters": 200
                                }
                            ],
                            "mandatory": true,
                            "input_type": "json",
                            "backend_label": "after_play_details"
                        }
                    ],
                    "before_play": [
                        {
                            "label": "Reward",
                            "style": {
                                "width": "48%"
                            },
                            "options": [
                                {
                                    "id": "better_luck_next_time",
                                    "name": "Better Luck Next Time"
                                },
                                {
                                    "id": "voucher",
                                    "name": "Voucher"
                                },
                                {
                                    "id": "neu_coin",
                                    "name": "Neucoins"
                                },
                                {
                                    "id": "digital_gold",
                                    "name": "Digital Gold"
                                },
                                {
                                    "id": "cashback",
                                    "name": "Cashback"
                                },
                                {
                                    "id": "promo_neu_coin",
                                    "name": "PROMO NEUCOINS"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "dropdown",
                            "backend_label": "before_play_reward"
                        },
                        {
                            "label": "Spoke Title",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "eg : Get 100 Neucoins",
                            "backend_label": "spoke_text"
                        },
                        {
                            "label": "Spoke Title Color",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "color_picker",
                            "backend_label": "before_play_spoke_title_color"
                        },
                        {
                            "label": "Merchant Logo",
                            "options": [
                                {
                                    "id": 1582,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                                },
                                {
                                    "id": 1583,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                                },
                                {
                                    "id": 1584,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                                },
                                {
                                    "id": 1585,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                                },
                                {
                                    "id": 1586,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                                },
                                {
                                    "id": 1587,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                                },
                                {
                                    "id": 1588,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "before_play_merchant_logo"
                        }
                    ]
                },
                "scratch_card": {
                    "after_play": [
                        {
                            "label": "Currency logo",
                            "options": [
                                {
                                    "id": 1579,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                                },
                                {
                                    "id": 1580,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                                },
                                {
                                    "id": 1581,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "logo_image"
                        },
                        {
                            "label": "Title",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "backend_label": "title"
                        },
                        {
                            "label": "Title Colour",
                            "style": {
                                "width": "48%"
                            },
                            "default": "#000000",
                            "mandatory": true,
                            "input_type": "color_picker",
                            "backend_label": "title_colour"
                        },
                        {
                            "label": "Subtitle",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "backend_label": "subtitle"
                        },
                        {
                            "label": "Subtitle Colour",
                            "style": {
                                "width": "48%"
                            },
                            "default": "#000000",
                            "mandatory": true,
                            "input_type": "color_picker",
                            "backend_label": "reward_value_color"
                        },
                        {
                            "label": "Get code cta text",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "Get Code",
                            "backend_label": "get_code_cta_text"
                        },
                        {
                            "label": "Redeem now cta text",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "Redeem Now",
                            "backend_label": "redeem_now_cta_text"
                        },
                        {
                            "label": "Redeem now link",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "https://www.sample.com/",
                            "backend_label": "redeem_now_link"
                        },
                        {
                            "label": "Scratch Card Image",
                            "options": [
                                {
                                    "id": 1582,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                                },
                                {
                                    "id": 1583,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                                },
                                {
                                    "id": 1584,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                                },
                                {
                                    "id": 1585,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                                },
                                {
                                    "id": 1586,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                                },
                                {
                                    "id": 1587,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                                },
                                {
                                    "id": 1588,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "scratch_card_image"
                        },
                        {
                            "label": "After Play Details",
                            "fields": [
                                {
                                    "label": "Title",
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "placeholder": "e.g : Congratulations!",
                                    "backend_label": "after_play_details_title",
                                    "max_characters": 50
                                },
                                {
                                    "label": "Description",
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "placeholder": "e.g : You have won 100 Neucoins",
                                    "backend_label": "after_play_details_description",
                                    "max_characters": 100
                                },
                                {
                                    "label": "How to Redeem",
                                    "mandatory": true,
                                    "input_type": "text_area",
                                    "placeholder": "e.g : You have won 100 Neucoins",
                                    "backend_label": "how_to_redeem",
                                    "max_characters": 200
                                },
                                {
                                    "label": "Offer T&C",
                                    "mandatory": true,
                                    "input_type": "text_area",
                                    "placeholder": "e.g : You have won 100 Neucoins",
                                    "backend_label": "tnc",
                                    "max_characters": 200
                                }
                            ],
                            "mandatory": true,
                            "input_type": "json",
                            "backend_label": "after_play_details"
                        }
                    ],
                    "before_play": [
                        {
                            "label": "Cover Image",
                            "options": [
                                {
                                    "id": 1582,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                                },
                                {
                                    "id": 1583,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                                },
                                {
                                    "id": 1584,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                                },
                                {
                                    "id": 1585,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                                },
                                {
                                    "id": 1586,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                                },
                                {
                                    "id": 1587,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                                },
                                {
                                    "id": 1588,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "cover_image"
                        }
                    ]
                }
            },
            "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
            "currency_id": 11,
            "reward_type_code": "voucher",
            "created_at": "2024-07-31 07:35:03",
            "last_modified_at": "2025-02-26 10:44:55"
        },
        {
            "id": 10,
            "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f1e32a_neucoin_icon.png",
            "name": "NeuCoins",
            "reward_details": [
                {
                    "label": "Reward",
                    "style": {
                        "width": "23%"
                    },
                    "options": [
                        {
                            "id": "better_luck_next_time",
                            "name": "Better Luck Next Time"
                        },
                        {
                            "id": "voucher",
                            "name": "Voucher"
                        },
                        {
                            "id": "neu_coin",
                            "name": "NeuCoins"
                        },
                        {
                            "id": "digital_gold",
                            "name": "Digital Gold"
                        },
                        {
                            "id": "cashback",
                            "name": "Cashback"
                        },
                        {
                            "id": "promo_neu_coin",
                            "name": "PROMO NEUCOINS"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "dropdown",
                    "backend_label": "reward_type"
                },
                {
                    "type": "number",
                    "label": "Win Probability",
                    "style": {
                        "width": "23%"
                    },
                    "mandatory": true,
                    "input_type": "input_box",
                    "backend_label": "win_probability"
                },
                {
                    "type": "string",
                    "label": "Program Id",
                    "style": {
                        "width": "23%"
                    },
                    "mandatory": true,
                    "input_type": "input_box",
                    "backend_label": "program_id"
                },
                {
                    "label": "Cashback Type",
                    "style": {
                        "width": "23%"
                    },
                    "options": [
                        {
                            "label": "Fixed",
                            "value": "FIXED_CASHBACK",
                            "fields": [
                                {
                                    "type": "number",
                                    "label": "Value",
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "backend_label": "reward_value"
                                }
                            ]
                        },
                        {
                            "label": "Dynamic Cashback",
                            "value": "DYNAMIC_CASHBACK",
                            "fields": [
                                {
                                    "type": "number",
                                    "label": "Min. Value",
                                    "style": {
                                        "width": "22%"
                                    },
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "backend_label": "min"
                                },
                                {
                                    "type": "number",
                                    "label": "Max. Value",
                                    "style": {
                                        "width": "22%"
                                    },
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "backend_label": "max"
                                },
                                {
                                    "type": "number",
                                    "label": "Avg. Value",
                                    "style": {
                                        "width": "22%"
                                    },
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "backend_label": "mean"
                                },
                                {
                                    "type": "number",
                                    "label": "Std. Deviation",
                                    "style": {
                                        "width": "22%"
                                    },
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "backend_label": "standard_deviation"
                                }
                            ]
                        },
                        {
                            "label": "Percentage",
                            "value": "PERCENTAGE_CASHBACK",
                            "fields": [
                                {
                                    "type": "number",
                                    "label": "Percentage",
                                    "style": {
                                        "width": "48%"
                                    },
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "backend_label": "percentage"
                                },
                                {
                                    "type": "number",
                                    "label": "Max. Value",
                                    "style": {
                                        "width": "48%"
                                    },
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "backend_label": "max_value"
                                }
                            ]
                        }
                    ],
                    "mandatory": true,
                    "input_type": "dropdown",
                    "backend_label": "cashback_type"
                },
                {
                    "label": "Reward Value",
                    "mandatory": true,
                    "input_type": "array",
                    "backend_label": "value"
                },
                {
                    "mandatory": false,
                    "input_type": "reward_limit",
                    "limitOptions": {
                        "limitTypeOptions": [
                            {
                                "id": "reward_value",
                                "name": "Value"
                            },
                            {
                                "id": "reward_count",
                                "name": "No. of Redemptions"
                            }
                        ],
                        "limitLevelOptions": [
                            {
                                "id": "product",
                                "name": "Product"
                            },
                            {
                                "id": "user",
                                "name": "User"
                            }
                        ]
                    },
                    "backend_label": "reward_limit"
                }
            ],
            "design_details": {
                "spin_the_wheel": {
                    "after_play": [
                        {
                            "label": "Banner Image",
                            "options": [
                                {
                                    "id": 1579,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                                },
                                {
                                    "id": 1580,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                                },
                                {
                                    "id": 1581,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "banner_image"
                        },
                        {
                            "label": "Currency Logo",
                            "options": [
                                {
                                    "id": 1579,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                                },
                                {
                                    "id": 1580,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                                },
                                {
                                    "id": 1581,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "logo_image"
                        },
                        {
                            "label": "Title Colour",
                            "style": {
                                "width": "48%"
                            },
                            "default": "#000000",
                            "mandatory": true,
                            "input_type": "color_picker",
                            "backend_label": "title_colour"
                        },
                        {
                            "label": "Reward Value Colour",
                            "style": {
                                "width": "48%"
                            },
                            "default": "#000000",
                            "mandatory": true,
                            "input_type": "color_picker",
                            "backend_label": "reward_value_color"
                        },
                        {
                            "label": "Go back cta text",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "Go Back",
                            "backend_label": "go_back_cta_text"
                        },
                        {
                            "label": "Start shopping cta text",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "Start Shopping",
                            "backend_label": "start_shopping_cta_text"
                        },
                        {
                            "label": "Start shopping link",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "https://www.sample.com/",
                            "backend_label": "start_shopping_link"
                        },
                        {
                            "label": "Scratch Card Image",
                            "options": [
                                {
                                    "id": 1582,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                                },
                                {
                                    "id": 1583,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                                },
                                {
                                    "id": 1584,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                                },
                                {
                                    "id": 1585,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                                },
                                {
                                    "id": 1586,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                                },
                                {
                                    "id": 1587,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                                },
                                {
                                    "id": 1588,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "scratch_card_image"
                        },
                        {
                            "label": "After Play Details",
                            "fields": [
                                {
                                    "label": "Title",
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "placeholder": "e.g : Congratulations!",
                                    "backend_label": "title",
                                    "max_characters": 50
                                },
                                {
                                    "label": "Description",
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "placeholder": "e.g : You have won 100 Neucoins",
                                    "backend_label": "description",
                                    "max_characters": 100
                                }
                            ],
                            "mandatory": true,
                            "input_type": "json",
                            "backend_label": "after_play_details"
                        }
                    ],
                    "before_play": [
                        {
                            "label": "Reward",
                            "style": {
                                "width": "48%"
                            },
                            "options": [
                                {
                                    "id": "better_luck_next_time",
                                    "name": "Better Luck Next Time"
                                },
                                {
                                    "id": "voucher",
                                    "name": "Voucher"
                                },
                                {
                                    "id": "neu_coin",
                                    "name": "Neucoins"
                                },
                                {
                                    "id": "digital_gold",
                                    "name": "Digital Gold"
                                },
                                {
                                    "id": "cashback",
                                    "name": "Cashback"
                                },
                                {
                                    "id": "promo_neu_coin",
                                    "name": "PROMO NEUCOINS"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "dropdown",
                            "backend_label": "before_play_reward"
                        },
                        {
                            "label": "Reward Color",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "color_picker",
                            "backend_label": "before_play_reward_color"
                        },
                        {
                            "label": "Title Color",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "color_picker",
                            "backend_label": "before_play_title_color"
                        },
                        {
                            "label": "Logo",
                            "options": [
                                {
                                    "id": 1582,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                                },
                                {
                                    "id": 1583,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                                },
                                {
                                    "id": 1584,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                                },
                                {
                                    "id": 1585,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                                },
                                {
                                    "id": 1586,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                                },
                                {
                                    "id": 1587,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                                },
                                {
                                    "id": 1588,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "before_play_logo"
                        }
                    ]
                },
                "scratch_card": {
                    "after_play": [
                        {
                            "label": "Currency Logo",
                            "options": [
                                {
                                    "id": 1579,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                                },
                                {
                                    "id": 1580,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                                },
                                {
                                    "id": 1581,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "logo_image"
                        },
                        {
                            "label": "Title Colour",
                            "style": {
                                "width": "48%"
                            },
                            "default": "#000000",
                            "mandatory": true,
                            "input_type": "color_picker",
                            "backend_label": "title_colour"
                        },
                        {
                            "label": "Reward Value Colour",
                            "style": {
                                "width": "48%"
                            },
                            "default": "#000000",
                            "mandatory": true,
                            "input_type": "color_picker",
                            "backend_label": "reward_value_color"
                        },
                        {
                            "label": "Go back cta text",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "Go Back",
                            "backend_label": "go_back_cta_text"
                        },
                        {
                            "label": "Start shopping cta text",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "Start Shopping",
                            "backend_label": "start_shopping_cta_text"
                        },
                        {
                            "label": "Start shopping link",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "https://www.sample.com/",
                            "backend_label": "start_shopping_link"
                        },
                        {
                            "label": "Scratch Card Image",
                            "options": [
                                {
                                    "id": 1582,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                                },
                                {
                                    "id": 1583,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                                },
                                {
                                    "id": 1584,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                                },
                                {
                                    "id": 1585,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                                },
                                {
                                    "id": 1586,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                                },
                                {
                                    "id": 1587,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                                },
                                {
                                    "id": 1588,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "scratch_card_image"
                        },
                        {
                            "label": "After Play Details",
                            "fields": [
                                {
                                    "label": "Title",
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "placeholder": "e.g : Congratulations!",
                                    "backend_label": "title",
                                    "max_characters": 50
                                },
                                {
                                    "label": "Description",
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "placeholder": "e.g : You have won 100 Neucoins",
                                    "backend_label": "description",
                                    "max_characters": 100
                                }
                            ],
                            "mandatory": true,
                            "input_type": "json",
                            "backend_label": "after_play_details"
                        }
                    ],
                    "before_play": [
                        {
                            "label": "Cover Image",
                            "options": [
                                {
                                    "id": 1582,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                                },
                                {
                                    "id": 1583,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                                },
                                {
                                    "id": 1584,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                                },
                                {
                                    "id": 1585,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                                },
                                {
                                    "id": 1586,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                                },
                                {
                                    "id": 1587,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                                },
                                {
                                    "id": 1588,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "cover_image"
                        }
                    ]
                }
            },
            "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
            "currency_id": 2,
            "reward_type_code": "neu_coin",
            "created_at": "2024-07-31 07:36:28",
            "last_modified_at": "2025-02-24 11:57:23"
        },
        {
            "id": 11,
            "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/d5603e_digital_gold_icon.png",
            "name": "Digital Gold",
            "reward_details": [
                {
                    "label": "Reward",
                    "style": {
                        "width": "23%"
                    },
                    "options": [
                        {
                            "id": "better_luck_next_time",
                            "name": "Better Luck Next Time"
                        },
                        {
                            "id": "voucher",
                            "name": "Voucher"
                        },
                        {
                            "id": "neu_coin",
                            "name": "NeuCoins"
                        },
                        {
                            "id": "digital_gold",
                            "name": "Digital Gold"
                        },
                        {
                            "id": "cashback",
                            "name": "Cashback"
                        },
                        {
                            "id": "promo_neu_coin",
                            "name": "PROMO NEUCOINS"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "dropdown",
                    "backend_label": "reward_type"
                },
                {
                    "type": "number",
                    "label": "Win Probability",
                    "style": {
                        "width": "23%"
                    },
                    "mandatory": true,
                    "input_type": "input_box",
                    "backend_label": "win_probability"
                },
                {
                    "type": "string",
                    "label": "Program Id",
                    "style": {
                        "width": "23%"
                    },
                    "mandatory": true,
                    "input_type": "input_box",
                    "backend_label": "program_id"
                },
                {
                    "label": "Cashback Type",
                    "style": {
                        "width": "23%"
                    },
                    "options": [
                        {
                            "label": "Fixed",
                            "value": "FIXED_CASHBACK",
                            "fields": [
                                {
                                    "type": "number",
                                    "label": "Value",
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "backend_label": "reward_value"
                                }
                            ]
                        },
                        {
                            "label": "Dynamic Cashback",
                            "value": "DYNAMIC_CASHBACK",
                            "fields": [
                                {
                                    "type": "number",
                                    "label": "Min. Value",
                                    "style": {
                                        "width": "22%"
                                    },
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "backend_label": "min"
                                },
                                {
                                    "type": "number",
                                    "label": "Max. Value",
                                    "style": {
                                        "width": "22%"
                                    },
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "backend_label": "max"
                                },
                                {
                                    "type": "number",
                                    "label": "Avg. Value",
                                    "style": {
                                        "width": "22%"
                                    },
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "backend_label": "mean"
                                },
                                {
                                    "type": "number",
                                    "label": "Std. Deviation",
                                    "style": {
                                        "width": "22%"
                                    },
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "backend_label": "standard_deviation"
                                }
                            ]
                        },
                        {
                            "label": "Percentage",
                            "value": "PERCENTAGE_CASHBACK",
                            "fields": [
                                {
                                    "type": "number",
                                    "label": "Percentage",
                                    "style": {
                                        "width": "48%"
                                    },
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "backend_label": "percentage"
                                },
                                {
                                    "type": "number",
                                    "label": "Max. Value",
                                    "style": {
                                        "width": "48%"
                                    },
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "backend_label": "max_value"
                                }
                            ]
                        }
                    ],
                    "mandatory": true,
                    "input_type": "dropdown",
                    "backend_label": "cashback_type"
                },
                {
                    "label": "Reward Value",
                    "mandatory": true,
                    "input_type": "array",
                    "backend_label": "value"
                },
                {
                    "mandatory": false,
                    "input_type": "reward_limit",
                    "limitOptions": {
                        "limitTypeOptions": [
                            {
                                "id": "reward_value",
                                "name": "Value"
                            },
                            {
                                "id": "reward_count",
                                "name": "No. of Redemptions"
                            }
                        ],
                        "limitLevelOptions": [
                            {
                                "id": "product",
                                "name": "Product"
                            },
                            {
                                "id": "user",
                                "name": "User"
                            }
                        ]
                    },
                    "backend_label": "reward_limit"
                }
            ],
            "design_details": {
                "spin_the_wheel": {
                    "after_play": [
                        {
                            "label": "Banner Image",
                            "options": [
                                {
                                    "id": 1579,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                                },
                                {
                                    "id": 1580,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                                },
                                {
                                    "id": 1581,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "banner_image"
                        },
                        {
                            "label": "Currency Logo",
                            "options": [
                                {
                                    "id": 1579,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                                },
                                {
                                    "id": 1580,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                                },
                                {
                                    "id": 1581,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "logo_image"
                        },
                        {
                            "label": "Title Colour",
                            "style": {
                                "width": "48%"
                            },
                            "default": "#000000",
                            "mandatory": true,
                            "input_type": "color_picker",
                            "backend_label": "title_colour"
                        },
                        {
                            "label": "Reward Value Colour",
                            "style": {
                                "width": "48%"
                            },
                            "default": "#000000",
                            "mandatory": true,
                            "input_type": "color_picker",
                            "backend_label": "reward_value_color"
                        },
                        {
                            "label": "Go back cta text",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "Go Back",
                            "backend_label": "go_back_cta_text"
                        },
                        {
                            "label": "Start investing cta text",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "Start Investing",
                            "backend_label": "start_investing_cta_text"
                        },
                        {
                            "label": "Start investing link",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "https://www.sample.com/",
                            "backend_label": "start_investing_link"
                        },
                        {
                            "label": "Scratch Card Image",
                            "options": [
                                {
                                    "id": 1582,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                                },
                                {
                                    "id": 1583,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                                },
                                {
                                    "id": 1584,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                                },
                                {
                                    "id": 1585,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                                },
                                {
                                    "id": 1586,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                                },
                                {
                                    "id": 1587,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                                },
                                {
                                    "id": 1588,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "scratch_card_image"
                        },
                        {
                            "label": "After Play Details",
                            "fields": [
                                {
                                    "label": "Title",
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "placeholder": "e.g : Congratulations!",
                                    "backend_label": "title",
                                    "max_characters": 50
                                },
                                {
                                    "label": "Description",
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "placeholder": "e.g : You have won 100 Neucoins",
                                    "backend_label": "description",
                                    "max_characters": 100
                                }
                            ],
                            "mandatory": true,
                            "input_type": "json",
                            "backend_label": "after_play_details"
                        }
                    ],
                    "before_play": [
                        {
                            "label": "Reward",
                            "style": {
                                "width": "48%"
                            },
                            "options": [
                                {
                                    "id": "better_luck_next_time",
                                    "name": "Better Luck Next Time"
                                },
                                {
                                    "id": "voucher",
                                    "name": "Voucher"
                                },
                                {
                                    "id": "neu_coin",
                                    "name": "Neucoins"
                                },
                                {
                                    "id": "digital_gold",
                                    "name": "Digital Gold"
                                },
                                {
                                    "id": "cashback",
                                    "name": "Cashback"
                                },
                                {
                                    "id": "promo_neu_coin",
                                    "name": "PROMO NEUCOINS"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "dropdown",
                            "backend_label": "before_play_reward"
                        },
                        {
                            "label": "Reward Color",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "color_picker",
                            "backend_label": "before_play_reward_color"
                        },
                        {
                            "label": "Title Color",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "color_picker",
                            "backend_label": "before_play_title_color"
                        },
                        {
                            "label": "Logo",
                            "options": [
                                {
                                    "id": 1582,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                                },
                                {
                                    "id": 1583,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                                },
                                {
                                    "id": 1584,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                                },
                                {
                                    "id": 1585,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                                },
                                {
                                    "id": 1586,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                                },
                                {
                                    "id": 1587,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                                },
                                {
                                    "id": 1588,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "before_play_logo"
                        }
                    ]
                },
                "scratch_card": {
                    "after_play": [
                        {
                            "label": "Currency Logo",
                            "options": [
                                {
                                    "id": 1579,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                                },
                                {
                                    "id": 1580,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                                },
                                {
                                    "id": 1581,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "logo_image"
                        },
                        {
                            "label": "Title Colour",
                            "style": {
                                "width": "48%"
                            },
                            "default": "#000000",
                            "mandatory": true,
                            "input_type": "color_picker",
                            "backend_label": "title_colour"
                        },
                        {
                            "label": "Reward Value Colour",
                            "style": {
                                "width": "48%"
                            },
                            "default": "#000000",
                            "mandatory": true,
                            "input_type": "color_picker",
                            "backend_label": "reward_value_color"
                        },
                        {
                            "label": "Go back cta text",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "Go Back",
                            "backend_label": "go_back_cta_text"
                        },
                        {
                            "label": "Start investing cta text",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "Start Investing",
                            "backend_label": "start_investing_cta_text"
                        },
                        {
                            "label": "Start investing link",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "https://www.sample.com/",
                            "backend_label": "start_investing_link"
                        },
                        {
                            "label": "Scratch Card Image",
                            "options": [
                                {
                                    "id": 1582,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                                },
                                {
                                    "id": 1583,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                                },
                                {
                                    "id": 1584,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                                },
                                {
                                    "id": 1585,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                                },
                                {
                                    "id": 1586,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                                },
                                {
                                    "id": 1587,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                                },
                                {
                                    "id": 1588,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "scratch_card_image"
                        },
                        {
                            "label": "After Play Details",
                            "fields": [
                                {
                                    "label": "Title",
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "placeholder": "e.g : Congratulations!",
                                    "backend_label": "title",
                                    "max_characters": 50
                                },
                                {
                                    "label": "Description",
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "placeholder": "e.g : You have won 100 Neucoins",
                                    "backend_label": "description",
                                    "max_characters": 100
                                }
                            ],
                            "mandatory": true,
                            "input_type": "json",
                            "backend_label": "after_play_details"
                        }
                    ],
                    "before_play": [
                        {
                            "label": "Cover Image",
                            "options": [
                                {
                                    "id": 1582,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                                },
                                {
                                    "id": 1583,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                                },
                                {
                                    "id": 1584,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                                },
                                {
                                    "id": 1585,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                                },
                                {
                                    "id": 1586,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                                },
                                {
                                    "id": 1587,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                                },
                                {
                                    "id": 1588,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "cover_image"
                        }
                    ]
                }
            },
            "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
            "currency_id": 1,
            "reward_type_code": "digital_gold",
            "created_at": "2024-07-31 07:36:40",
            "last_modified_at": "2025-02-24 11:57:23"
        },
        {
            "id": 12,
            "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/After.png",
            "name": "BETTER LUCK NEXT TIME",
            "reward_details": [
                {
                    "label": "Reward",
                    "style": {
                        "width": "23%"
                    },
                    "options": [
                        {
                            "id": "better_luck_next_time",
                            "name": "Better Luck Next Time"
                        },
                        {
                            "id": "voucher",
                            "name": "Voucher"
                        },
                        {
                            "id": "neu_coin",
                            "name": "NeuCoins"
                        },
                        {
                            "id": "digital_gold",
                            "name": "Digital Gold"
                        },
                        {
                            "id": "cashback",
                            "name": "Cashback"
                        },
                        {
                            "id": "promo_neu_coin",
                            "name": "PROMO NEUCOINS"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "dropdown",
                    "backend_label": "reward_type"
                },
                {
                    "type": "number",
                    "label": "Win Probability",
                    "style": {
                        "width": "23%"
                    },
                    "mandatory": true,
                    "input_type": "input_box",
                    "backend_label": "win_probability"
                }
            ],
            "design_details": {
                "spin_the_wheel": {
                    "after_play": [
                        {
                            "label": "Banner Image",
                            "options": [
                                {
                                    "id": 1582,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                                },
                                {
                                    "id": 1583,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                                },
                                {
                                    "id": 1584,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                                },
                                {
                                    "id": 1585,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                                },
                                {
                                    "id": 1586,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                                },
                                {
                                    "id": 1587,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                                },
                                {
                                    "id": 1588,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "banner_image"
                        },
                        {
                            "label": "Title",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "Better Luck Next Time!",
                            "backend_label": "title"
                        },
                        {
                            "label": "Description",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "Better Luck Next Time!",
                            "backend_label": "description"
                        },
                        {
                            "label": "Go back cta text",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "Go Back",
                            "backend_label": "go_back_cta_text"
                        },
                        {
                            "label": "Scratch Card Image",
                            "options": [
                                {
                                    "id": 1582,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                                },
                                {
                                    "id": 1583,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                                },
                                {
                                    "id": 1584,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                                },
                                {
                                    "id": 1585,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                                },
                                {
                                    "id": 1586,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                                },
                                {
                                    "id": 1587,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                                },
                                {
                                    "id": 1588,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "scratch_card_image"
                        },
                        {
                            "label": "After Play Details",
                            "fields": [
                                {
                                    "label": "Title",
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "placeholder": "e.g : Congratulations!",
                                    "backend_label": "after_play_details_title",
                                    "max_characters": 50
                                }
                            ],
                            "mandatory": true,
                            "input_type": "json",
                            "backend_label": "after_play_details"
                        }
                    ],
                    "before_play": [
                        {
                            "label": "Reward",
                            "style": {
                                "width": "48%"
                            },
                            "options": [
                                {
                                    "id": "better_luck_next_time",
                                    "name": "Better Luck Next Time"
                                },
                                {
                                    "id": "voucher",
                                    "name": "Voucher"
                                },
                                {
                                    "id": "neu_coin",
                                    "name": "Neucoins"
                                },
                                {
                                    "id": "digital_gold",
                                    "name": "Digital Gold"
                                },
                                {
                                    "id": "cashback",
                                    "name": "Cashback"
                                },
                                {
                                    "id": "promo_neu_coin",
                                    "name": "PROMO NEUCOINS"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "dropdown",
                            "backend_label": "before_play_reward"
                        },
                        {
                            "label": "Spoke Title",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "eg : Get 100 Neucoins",
                            "backend_label": "spoke_text"
                        },
                        {
                            "label": "Spoke Title Color",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "color_picker",
                            "backend_label": "before_play_spoke_title_color"
                        },
                        {
                            "label": "Logo",
                            "options": [
                                {
                                    "id": 1582,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                                },
                                {
                                    "id": 1583,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                                },
                                {
                                    "id": 1584,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                                },
                                {
                                    "id": 1585,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                                },
                                {
                                    "id": 1586,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                                },
                                {
                                    "id": 1587,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                                },
                                {
                                    "id": 1588,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "before_play_logo"
                        }
                    ]
                },
                "scratch_card": {
                    "after_play": [
                        {
                            "label": "Banner Image",
                            "options": [
                                {
                                    "id": 1582,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                                },
                                {
                                    "id": 1583,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                                },
                                {
                                    "id": 1584,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                                },
                                {
                                    "id": 1585,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                                },
                                {
                                    "id": 1586,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                                },
                                {
                                    "id": 1587,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                                },
                                {
                                    "id": 1588,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "banner_image"
                        },
                        {
                            "label": "Title",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "Better Luck Next Time!",
                            "backend_label": "title"
                        },
                        {
                            "label": "Description",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "Better Luck Next Time!",
                            "backend_label": "description"
                        },
                        {
                            "label": "Go back cta text",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "Go Back",
                            "backend_label": "go_back_cta_text"
                        },
                        {
                            "label": "Scratch Card Image",
                            "options": [
                                {
                                    "id": 1582,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                                },
                                {
                                    "id": 1583,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                                },
                                {
                                    "id": 1584,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                                },
                                {
                                    "id": 1585,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                                },
                                {
                                    "id": 1586,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                                },
                                {
                                    "id": 1587,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                                },
                                {
                                    "id": 1588,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "scratch_card_image"
                        },
                        {
                            "label": "After Play Details",
                            "fields": [
                                {
                                    "label": "Title",
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "placeholder": "e.g : Congratulations!",
                                    "backend_label": "after_play_details_title",
                                    "max_characters": 50
                                }
                            ],
                            "mandatory": true,
                            "input_type": "json",
                            "backend_label": "after_play_details"
                        }
                    ],
                    "before_play": [
                        {
                            "label": "Cover Image",
                            "options": [
                                {
                                    "id": 1582,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                                },
                                {
                                    "id": 1583,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                                },
                                {
                                    "id": 1584,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                                },
                                {
                                    "id": 1585,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                                },
                                {
                                    "id": 1586,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                                },
                                {
                                    "id": 1587,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                                },
                                {
                                    "id": 1588,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "cover_image"
                        }
                    ]
                }
            },
            "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
            "currency_id": null,
            "reward_type_code": "bl_next_time",
            "created_at": "2024-08-02 04:11:01",
            "last_modified_at": "2025-03-06 08:30:08"
        },
        {
            "id": 13,
            "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/d5603e_digital_gold_icon.png",
            "name": "Promo Neucoins",
            "reward_details": [
                {
                    "label": "Reward",
                    "style": {
                        "width": "23%"
                    },
                    "options": [
                        {
                            "id": "better_luck_next_time",
                            "name": "Better Luck Next Time"
                        },
                        {
                            "id": "voucher",
                            "name": "Voucher"
                        },
                        {
                            "id": "neu_coin",
                            "name": "NeuCoins"
                        },
                        {
                            "id": "digital_gold",
                            "name": "Digital Gold"
                        },
                        {
                            "id": "cashback",
                            "name": "Cashback"
                        },
                        {
                            "id": "promo_neu_coin",
                            "name": "PROMO NEUCOINS"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "dropdown",
                    "backend_label": "reward_type"
                },
                {
                    "type": "number",
                    "label": "Win Probability",
                    "style": {
                        "width": "23%"
                    },
                    "mandatory": true,
                    "input_type": "input_box",
                    "backend_label": "win_probability"
                },
                {
                    "type": "string",
                    "label": "Program Id",
                    "style": {
                        "width": "23%"
                    },
                    "mandatory": true,
                    "input_type": "input_box",
                    "backend_label": "program_id"
                },
                {
                    "type": "string",
                    "label": "Promo Identifier",
                    "style": {
                        "width": "23%"
                    },
                    "mandatory": true,
                    "input_type": "input_box",
                    "backend_label": "promo_id"
                },
                {
                    "mandatory": false,
                    "input_type": "reward_limit",
                    "limitOptions": {
                        "limitTypeOptions": [
                            {
                                "id": "reward_value",
                                "name": "Value"
                            },
                            {
                                "id": "reward_count",
                                "name": "No. of Redemptions"
                            }
                        ],
                        "limitLevelOptions": [
                            {
                                "id": "product",
                                "name": "Product"
                            },
                            {
                                "id": "user",
                                "name": "User"
                            }
                        ]
                    },
                    "backend_label": "reward_limit"
                }
            ],
            "design_details": {
                "spin_the_wheel": {
                    "after_play": [
                        {
                            "label": "Banner Image",
                            "options": [
                                {
                                    "id": 1579,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                                },
                                {
                                    "id": 1580,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                                },
                                {
                                    "id": 1581,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "banner_image"
                        },
                        {
                            "label": "Currency logo",
                            "options": [
                                {
                                    "id": 1579,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                                },
                                {
                                    "id": 1580,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                                },
                                {
                                    "id": 1581,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "logo_image_1"
                        },
                        {
                            "label": "Merchant Logo",
                            "options": [
                                {
                                    "id": 1579,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                                },
                                {
                                    "id": 1580,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                                },
                                {
                                    "id": 1581,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "logo_image_2"
                        },
                        {
                            "label": "Title",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "1000 Neucoins",
                            "backend_label": "title"
                        },
                        {
                            "label": "Title Colour",
                            "style": {
                                "width": "48%"
                            },
                            "default": "#000000",
                            "mandatory": true,
                            "input_type": "color_picker",
                            "backend_label": "title_colour"
                        },
                        {
                            "label": "Subtitle",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "on your next purchase",
                            "backend_label": "subtitle"
                        },
                        {
                            "label": "Subtitle Colour",
                            "style": {
                                "width": "48%"
                            },
                            "default": "#000000",
                            "mandatory": true,
                            "input_type": "color_picker",
                            "backend_label": "reward_value_color"
                        },
                        {
                            "label": "Start shopping link",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "https://www.sample.com/",
                            "backend_label": "start_shopping_link"
                        },
                        {
                            "label": "Start shopping cta text",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "Start Shopping",
                            "backend_label": "start_shopping_cta_text"
                        },
                        {
                            "label": "Scratch Card Image",
                            "options": [
                                {
                                    "id": 1582,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                                },
                                {
                                    "id": 1583,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                                },
                                {
                                    "id": 1584,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                                },
                                {
                                    "id": 1585,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                                },
                                {
                                    "id": 1586,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                                },
                                {
                                    "id": 1587,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                                },
                                {
                                    "id": 1588,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "scratch_card_image"
                        },
                        {
                            "label": "After Play Details",
                            "fields": [
                                {
                                    "label": "Title",
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "placeholder": "e.g : Congratulations!",
                                    "backend_label": "after_play_details_title",
                                    "max_characters": 50
                                },
                                {
                                    "label": "Description",
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "placeholder": "e.g : You have won 100 Neucoins",
                                    "backend_label": "after_play_details_description",
                                    "max_characters": 100
                                },
                                {
                                    "label": "How to Redeem",
                                    "mandatory": true,
                                    "input_type": "text_area",
                                    "placeholder": "e.g : You have won 100 Neucoins",
                                    "backend_label": "how_to_redeem",
                                    "max_characters": 200
                                }
                            ],
                            "mandatory": true,
                            "input_type": "json",
                            "backend_label": "after_play_details"
                        }
                    ],
                    "before_play": [
                        {
                            "label": "Reward",
                            "style": {
                                "width": "48%"
                            },
                            "options": [
                                {
                                    "id": "better_luck_next_time",
                                    "name": "Better Luck Next Time"
                                },
                                {
                                    "id": "voucher",
                                    "name": "Voucher"
                                },
                                {
                                    "id": "neu_coin",
                                    "name": "Neucoins"
                                },
                                {
                                    "id": "digital_gold",
                                    "name": "Digital Gold"
                                },
                                {
                                    "id": "cashback",
                                    "name": "Cashback"
                                },
                                {
                                    "id": "promo_neu_coin",
                                    "name": "PROMO NEUCOINS"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "dropdown",
                            "backend_label": "before_play_reward"
                        },
                        {
                            "label": "Spoke Title",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "eg : Get 100 Neucoins",
                            "backend_label": "spoke_text"
                        },
                        {
                            "label": "Spoke Title Color",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "color_picker",
                            "backend_label": "before_play_spoke_title_color"
                        },
                        {
                            "label": "Merchant Logo",
                            "options": [
                                {
                                    "id": 1582,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                                },
                                {
                                    "id": 1583,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                                },
                                {
                                    "id": 1584,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                                },
                                {
                                    "id": 1585,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                                },
                                {
                                    "id": 1586,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                                },
                                {
                                    "id": 1587,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                                },
                                {
                                    "id": 1588,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "before_play_merchant_logo"
                        }
                    ]
                },
                "scratch_card": {
                    "after_play": [
                        {
                            "label": "Currency logo",
                            "options": [
                                {
                                    "id": 1579,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                                },
                                {
                                    "id": 1580,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                                },
                                {
                                    "id": 1581,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "logo_image_1"
                        },
                        {
                            "label": "Merchant Logo",
                            "options": [
                                {
                                    "id": 1579,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                                },
                                {
                                    "id": 1580,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                                },
                                {
                                    "id": 1581,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "logo_image_2"
                        },
                        {
                            "label": "Title",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "1000 Neucoins",
                            "backend_label": "title"
                        },
                        {
                            "label": "Title Colour",
                            "style": {
                                "width": "48%"
                            },
                            "default": "#000000",
                            "mandatory": true,
                            "input_type": "color_picker",
                            "backend_label": "title_colour"
                        },
                        {
                            "label": "Subtitle",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "on your next purchase",
                            "backend_label": "subtitle"
                        },
                        {
                            "label": "Subtitle Colour",
                            "style": {
                                "width": "48%"
                            },
                            "default": "#000000",
                            "mandatory": true,
                            "input_type": "color_picker",
                            "backend_label": "reward_value_color"
                        },
                        {
                            "label": "Start shopping link",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "https://www.sample.com/",
                            "backend_label": "start_shopping_link"
                        },
                        {
                            "label": "Start shopping cta text",
                            "style": {
                                "width": "48%"
                            },
                            "mandatory": true,
                            "input_type": "input_box",
                            "placeholder": "Start Shopping",
                            "backend_label": "start_shopping_cta_text"
                        },
                        {
                            "label": "Scratch Card Image",
                            "options": [
                                {
                                    "id": 1582,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                                },
                                {
                                    "id": 1583,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                                },
                                {
                                    "id": 1584,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                                },
                                {
                                    "id": 1585,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                                },
                                {
                                    "id": 1586,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                                },
                                {
                                    "id": 1587,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                                },
                                {
                                    "id": 1588,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "scratch_card_image"
                        },
                        {
                            "label": "After Play Details",
                            "fields": [
                                {
                                    "label": "Title",
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "placeholder": "e.g : Congratulations!",
                                    "backend_label": "after_play_details_title",
                                    "max_characters": 50
                                },
                                {
                                    "label": "Description",
                                    "mandatory": true,
                                    "input_type": "input_box",
                                    "placeholder": "e.g : You have won 100 Neucoins",
                                    "backend_label": "after_play_details_description",
                                    "max_characters": 100
                                },
                                {
                                    "label": "How to Redeem",
                                    "mandatory": true,
                                    "input_type": "text_area",
                                    "placeholder": "e.g : You have won 100 Neucoins",
                                    "backend_label": "how_to_redeem",
                                    "max_characters": 200
                                }
                            ],
                            "mandatory": true,
                            "input_type": "json",
                            "backend_label": "after_play_details"
                        }
                    ],
                    "before_play": [
                        {
                            "label": "Cover Image",
                            "options": [
                                {
                                    "id": 1582,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                                },
                                {
                                    "id": 1583,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                                },
                                {
                                    "id": 1584,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                                },
                                {
                                    "id": 1585,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                                },
                                {
                                    "id": 1586,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                                },
                                {
                                    "id": 1587,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                                },
                                {
                                    "id": 1588,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                                }
                            ],
                            "mandatory": true,
                            "input_type": "image_upload",
                            "backend_label": "cover_image"
                        }
                    ]
                }
            },
            "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
            "currency_id": 13,
            "reward_type_code": "promo_neu_coin",
            "created_at": "2024-11-05 11:33:55",
            "last_modified_at": "2025-02-26 10:47:26"
        }
    ]
}

export const game_type = [
    {
        "id": 9,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fe0c85_Scrach_CARD_ICON.png",
        "name": "Scratch Card",
        "game_type_code": "scratch_card",
        "design_fields": {
            "after_play": [
                {
                    "label": "Reward Title",
                    "mandatory": true,
                    "input_type": "input_box",
                    "backend_label": "title"
                },
                {
                    "label": "Title Color",
                    "mandatory": true,
                    "input_type": "color_picker",
                    "backend_label": "title_color"
                },
                {
                    "label": "Reward Title (Only for No Reward)",
                    "mandatory": true,
                    "input_type": "input_box",
                    "backend_label": "no_reward_title"
                },
                {
                    "label": "Title Color (Only for No Reward)",
                    "mandatory": true,
                    "input_type": "color_picker",
                    "backend_label": "no_reward_title_color"
                },
                {
                    "label": "Scratch Card",
                    "options": [
                        {
                            "label": "Color",
                            "value": "color",
                            "mandatory": true,
                            "backend_label": "scratch_card_color"
                        },
                        {
                            "label": "Image",
                            "value": "image",
                            "options": [
                                "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                                "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                                "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww"
                            ],
                            "mandatory": true,
                            "backend_label": "scratch_card_image"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "image_and_color_picker",
                    "backend_label": "scratch_card"
                },
                {
                    "label": "Background",
                    "options": [
                        {
                            "label": "Color",
                            "value": "color",
                            "mandatory": true,
                            "backend_label": "background_color"
                        },
                        {
                            "label": "Image",
                            "value": "image",
                            "options": [
                                "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                                "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                                "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww"
                            ],
                            "mandatory": true,
                            "backend_label": "background_image"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "image_and_color_picker",
                    "backend_label": "reward_background"
                }
            ],
            "before_play": [
                {
                    "label": "Title",
                    "style": {
                        "width": "48%"
                    },
                    "mandatory": true,
                    "input_type": "input_box",
                    "placeholder": "eg : Spin the wheel to win",
                    "backend_label": "title",
                    "max_characters": 50
                },
                {
                    "label": "Title Color",
                    "style": {
                        "width": "48%"
                    },
                    "default": "#000000",
                    "mandatory": true,
                    "input_type": "color_picker",
                    "backend_label": "title_color"
                },
                {
                    "label": "Background Overlay Color",
                    "style": {
                        "width": "48%"
                    },
                    "default": "#000000",
                    "mandatory": true,
                    "input_type": "color_picker",
                    "description": "This color defines the background behind the scratch card",
                    "backend_label": "background_overlay_color"
                },
                {
                    "label": "Show Earned Via In Before & After Scratch",
                    "style": {
                        "width": "48%"
                    },
                    "options": [
                        {
                            "id": true,
                            "name": "Yes"
                        },
                        {
                            "id": false,
                            "name": "No"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "dropdown",
                    "backend_label": "show_earned_via"
                },
                {
                    "label": "Scratch card “Locked State” Image",
                    "options": [
                        {
                            "id": 1582,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                        },
                        {
                            "id": 1583,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                        },
                        {
                            "id": 1584,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                        },
                        {
                            "id": 1585,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                        },
                        {
                            "id": 1586,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                        },
                        {
                            "id": 1587,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                        },
                        {
                            "id": 1588,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "image_upload",
                    "backend_label": "scartch_card_locked_image"
                },
                {
                    "label": "Scratch card “Unlocked State” Image",
                    "options": [
                        {
                            "id": 1582,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                        },
                        {
                            "id": 1583,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                        },
                        {
                            "id": 1584,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                        },
                        {
                            "id": 1585,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                        },
                        {
                            "id": 1586,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                        },
                        {
                            "id": 1587,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                        },
                        {
                            "id": 1588,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "image_upload",
                    "backend_label": "scartch_card_unlocked_image"
                }
            ],
            "minimum_rewards": {
                "label": "Minimum",
                "value": 1,
                "mandatory": true
            }
        },
        "game_fields": {},
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "created_at": "2024-08-01 12:14:02",
        "last_modified_at": "2025-01-28 04:50:47"
    },
    {
        "id": 6,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/ae7e6f_STW thumbnail.png",
        "name": "Spin The Wheel",
        "game_type_code": "spin_the_wheel",
        "design_fields": {
            "after_play": [
                {
                    "label": "Title",
                    "mandatory": true,
                    "input_type": "input_box",
                    "placeholder": "eg : Congratulations!",
                    "backend_label": "title"
                },
                {
                    "label": "Congratulations Image",
                    "options": [
                        {
                            "id": 1582,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                        },
                        {
                            "id": 1583,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                        },
                        {
                            "id": 1584,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                        },
                        {
                            "id": 1585,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                        },
                        {
                            "id": 1586,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                        },
                        {
                            "id": 1587,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                        },
                        {
                            "id": 1588,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "image_upload",
                    "backend_label": "congratulation_image"
                }
            ],
            "before_play": [
                {
                    "label": "No. of Spins",
                    "style": {
                        "width": "48%"
                    },
                    "options": [
                        {
                            "id": "3",
                            "name": "3"
                        },
                        {
                            "id": "4",
                            "name": "4"
                        },
                        {
                            "id": "5",
                            "name": "5"
                        },
                        {
                            "id": "6",
                            "name": "6"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "select_number",
                    "backend_label": "reward_type"
                },
                {
                    "label": "Read Instruction",
                    "style": {
                        "width": "100%"
                    },
                    "mandatory": true,
                    "input_type": "text_area",
                    "backend_label": "read_instruction"
                },
                {
                    "label": "Terms & Conditions",
                    "style": {
                        "width": "100%"
                    },
                    "mandatory": true,
                    "input_type": "text_area",
                    "backend_label": "terms_and_conditions"
                }
            ],
            "minimum_rewards": {
                "label": "Minimum",
                "value": 1,
                "mandatory": true
            }
        },
        "game_fields": {
            "quiz": [
                {
                    "label": "Question Type",
                    "options": [
                        {
                            "id": "single",
                            "name": "Single Choice"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "question",
                    "placeholder": "eg : Holi is celebrated as a festival of? ",
                    "backend_label": "Question"
                },
                {
                    "label": "Question Banner",
                    "options": [
                        {
                            "id": 1582,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                        },
                        {
                            "id": 1583,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                        },
                        {
                            "id": 1584,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                        },
                        {
                            "id": 1585,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                        },
                        {
                            "id": 1586,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                        },
                        {
                            "id": 1587,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                        },
                        {
                            "id": 1588,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "image_upload",
                    "backend_label": "question_banner"
                }
            ]
        },
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "created_at": "2024-07-31 07:28:05",
        "last_modified_at": "2025-02-26 09:48:34"
    },
    {
        "id": 8,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/9fa732_STW_ICON_COL.png",
        "name": "Trivia",
        "game_type_code": "trivia",
        "design_fields": {
            "after_play": [
                {
                    "label": "Reward Title",
                    "mandatory": true,
                    "input_type": "input_box",
                    "backend_label": "title"
                },
                {
                    "label": "Title Color",
                    "mandatory": true,
                    "input_type": "color_picker",
                    "backend_label": "title_color"
                },
                {
                    "label": "Reward Title (Only for No Reward)",
                    "mandatory": true,
                    "input_type": "input_box",
                    "backend_label": "no_reward_title"
                },
                {
                    "label": "Title Color (Only for No Reward)",
                    "mandatory": true,
                    "input_type": "color_picker",
                    "backend_label": "no_reward_title_color"
                },
                {
                    "label": "Scratch Card",
                    "options": [
                        {
                            "label": "Color",
                            "value": "color",
                            "mandatory": true,
                            "backend_label": "scratch_card_color"
                        },
                        {
                            "label": "Image",
                            "value": "image",
                            "options": [
                                "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                                "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                                "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww"
                            ],
                            "mandatory": true,
                            "backend_label": "scratch_card_image"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "image_and_color_picker",
                    "backend_label": "scratch_card"
                },
                {
                    "label": "Background",
                    "options": [
                        {
                            "label": "Color",
                            "value": "color",
                            "mandatory": true,
                            "backend_label": "background_color"
                        },
                        {
                            "label": "Image",
                            "value": "image",
                            "options": [
                                "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                                "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                                "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww"
                            ],
                            "mandatory": true,
                            "backend_label": "background_image"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "image_and_color_picker",
                    "backend_label": "reward_background"
                }
            ],
            "before_play": [
                {
                    "label": "Title",
                    "style": {
                        "width": "48%"
                    },
                    "mandatory": true,
                    "input_type": "input_box",
                    "placeholder": "eg : Spin the wheel to win",
                    "backend_label": "title",
                    "max_characters": 50
                },
                {
                    "label": "Title Color",
                    "style": {
                        "width": "48%"
                    },
                    "default": "#000000",
                    "mandatory": true,
                    "input_type": "color_picker",
                    "backend_label": "title_color"
                },
                {
                    "note": "This color defines the background behind the scratch card",
                    "label": "Background Overlay Color",
                    "style": {
                        "width": "48%"
                    },
                    "default": "#000000",
                    "mandatory": true,
                    "input_type": "color_picker",
                    "backend_label": "background_overlay_color"
                },
                {
                    "label": "Show Earned Via In Before & After Scratch",
                    "style": {
                        "width": "48%"
                    },
                    "options": [
                        {
                            "id": true,
                            "name": "Yes"
                        },
                        {
                            "id": false,
                            "name": "No"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "dropdown",
                    "backend_label": "show_earned_via"
                },
                {
                    "label": "Scratch Card Image",
                    "options": [
                        {
                            "label": "Color",
                            "value": "color",
                            "default": "#000000",
                            "options": [
                                {
                                    "id": 1543,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/b525e2_Gratify v2.0 Rectangle.png"
                                },
                                {
                                    "id": 1544,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fd466b_Gratify v2.0 Rectangle 161123625.png"
                                },
                                {
                                    "id": 1545,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/849da6_Gratify v2.0 Rectangle (1).png"
                                },
                                {
                                    "id": 1546,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/ffb638_Gratify v2.0 Rectangle (2).png"
                                },
                                {
                                    "id": 1547,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/12351e_Gratify v2.0 Rectangle (3).png"
                                },
                                {
                                    "id": 1548,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/7e8764_Gratify v2.0 Rectangle (4).png"
                                },
                                {
                                    "id": 1549,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/492600_Gratify v2.0 Rectangle (5).png"
                                },
                                {
                                    "id": 1550,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/ff2d75_Gratify v2.0 Rectangle (6).png"
                                },
                                {
                                    "id": 1551,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/54216d_Gratify v2.0 Rectangle (7).png"
                                },
                                {
                                    "id": 1552,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/4b3749_Gratify v2.0 Rectangle 161123628.png"
                                },
                                {
                                    "id": 1553,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/2f10a5_Gratify v2.0 Rectangle 161123629.png"
                                },
                                {
                                    "id": 1554,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/71bbb1_Gratify v2.0 Rectangle 161123631.png"
                                },
                                {
                                    "id": 1555,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/84b867_Gratify v2.0 Rectangle 161123632.png"
                                }
                            ],
                            "mandatory": true,
                            "backend_label": "scratch_card_color"
                        },
                        {
                            "label": "Image",
                            "value": "image",
                            "options": [
                                {
                                    "id": 1556,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/25ec3d_responsive-media.png"
                                },
                                {
                                    "id": 1557,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/a40d18_Gratify v2.0 Frame.png"
                                },
                                {
                                    "id": 1558,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47377c_Gratify v2.0 Frame (1).png"
                                },
                                {
                                    "id": 1559,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/13a5cd_Gratify v2.0 Frame (2).png"
                                },
                                {
                                    "id": 1560,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/dc39d9_Gratify v2.0 Frame (3).png"
                                },
                                {
                                    "id": 1561,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f20243_Gratify v2.0 Frame (4).png"
                                },
                                {
                                    "id": 1562,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/5e5966_Gratify v2.0 Frame (5).png"
                                },
                                {
                                    "id": 1563,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/663e74_Gratify v2.0 Frame (6).png"
                                },
                                {
                                    "id": 1564,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/5a0408_Gratify v2.0 Frame (7).png"
                                },
                                {
                                    "id": 1565,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/28df7c_Gratify v2.0 Frame (8).png"
                                },
                                {
                                    "id": 1566,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/2e8f97_Gratify v2.0 Frame (9).png"
                                }
                            ],
                            "mandatory": true,
                            "backend_label": "scratch_card_image"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "image_upload",
                    "backend_label": "scratch_card"
                }
            ],
            "minimum_rewards": {
                "label": "Minimum",
                "value": 1,
                "mandatory": true
            }
        },
        "game_fields": [
            {
                "label": "Download Sample",
                "value": "https://www.example.com/voucher_csv.csv",
                "mandatory": true
            },
            {
                "label": "Upload Voucher CSV",
                "mandatory": false,
                "backend_label": "voucher_csv"
            },
            {
                "label": "Manual Input",
                "fields": [
                    {
                        "label": "Question",
                        "mandatory": true,
                        "backend_label": "question"
                    },
                    {
                        "label": "Add Media",
                        "mandatory": false,
                        "backend_label": "uploaded_media"
                    },
                    {
                        "label": "Answer Options",
                        "fields": [
                            {
                                "label": "option1",
                                "value": "option1",
                                "is_answer": true
                            },
                            {
                                "label": "option2",
                                "value": "option2",
                                "is_answer": false
                            }
                        ],
                        "min_value": 2,
                        "backend_label": "answer_options",
                        "additionalFields": true
                    }
                ],
                "mandatory": false
            }
        ],
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "created_at": "2024-07-31 07:31:41",
        "last_modified_at": "2024-09-24 11:51:15"
    },
    {
        "id": 7,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/4efd27_machine.png",
        "name": "Slot Machine",
        "game_type_code": "slot_machine",
        "design_fields": {
            "after_play": [
                {
                    "label": "Reward Title",
                    "mandatory": true,
                    "input_type": "input_box",
                    "backend_label": "title"
                },
                {
                    "label": "Title Color",
                    "mandatory": true,
                    "input_type": "color_picker",
                    "backend_label": "title_color"
                },
                {
                    "label": "Reward Title (Only for No Reward)",
                    "mandatory": true,
                    "input_type": "input_box",
                    "backend_label": "no_reward_title"
                },
                {
                    "label": "Title Color (Only for No Reward)",
                    "mandatory": true,
                    "input_type": "color_picker",
                    "backend_label": "no_reward_title_color"
                },
                {
                    "label": "Scratch Card",
                    "options": [
                        {
                            "label": "Color",
                            "value": "color",
                            "mandatory": true,
                            "backend_label": "scratch_card_color"
                        },
                        {
                            "label": "Image",
                            "value": "image",
                            "options": [
                                "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                                "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                                "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww"
                            ],
                            "mandatory": true,
                            "backend_label": "scratch_card_image"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "image_and_color_picker",
                    "backend_label": "scratch_card"
                },
                {
                    "label": "Background",
                    "options": [
                        {
                            "label": "Color",
                            "value": "color",
                            "mandatory": true,
                            "backend_label": "background_color"
                        },
                        {
                            "label": "Image",
                            "value": "image",
                            "options": [
                                "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                                "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                                "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww"
                            ],
                            "mandatory": true,
                            "backend_label": "background_image"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "image_and_color_picker",
                    "backend_label": "reward_background"
                }
            ],
            "before_play": [
                {
                    "label": "Title",
                    "style": {
                        "width": "48%"
                    },
                    "mandatory": true,
                    "input_type": "input_box",
                    "placeholder": "eg : Spin the wheel to win",
                    "backend_label": "title",
                    "max_characters": 50
                },
                {
                    "label": "Title Color",
                    "style": {
                        "width": "48%"
                    },
                    "default": "#000000",
                    "mandatory": true,
                    "input_type": "color_picker",
                    "backend_label": "title_color"
                },
                {
                    "note": "This color defines the background behind the scratch card",
                    "label": "Background Overlay Color",
                    "style": {
                        "width": "48%"
                    },
                    "default": "#000000",
                    "mandatory": true,
                    "input_type": "color_picker",
                    "backend_label": "background_overlay_color"
                },
                {
                    "label": "Show Earned Via In Before & After Scratch",
                    "style": {
                        "width": "48%"
                    },
                    "options": [
                        {
                            "id": true,
                            "name": "Yes"
                        },
                        {
                            "id": false,
                            "name": "No"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "dropdown",
                    "backend_label": "show_earned_via"
                },
                {
                    "label": "Scratch Card Image",
                    "options": [
                        {
                            "label": "Color",
                            "value": "color",
                            "default": "#000000",
                            "options": [
                                {
                                    "id": 1543,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/b525e2_Gratify v2.0 Rectangle.png"
                                },
                                {
                                    "id": 1544,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fd466b_Gratify v2.0 Rectangle 161123625.png"
                                },
                                {
                                    "id": 1545,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/849da6_Gratify v2.0 Rectangle (1).png"
                                },
                                {
                                    "id": 1546,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/ffb638_Gratify v2.0 Rectangle (2).png"
                                },
                                {
                                    "id": 1547,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/12351e_Gratify v2.0 Rectangle (3).png"
                                },
                                {
                                    "id": 1548,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/7e8764_Gratify v2.0 Rectangle (4).png"
                                },
                                {
                                    "id": 1549,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/492600_Gratify v2.0 Rectangle (5).png"
                                },
                                {
                                    "id": 1550,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/ff2d75_Gratify v2.0 Rectangle (6).png"
                                },
                                {
                                    "id": 1551,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/54216d_Gratify v2.0 Rectangle (7).png"
                                },
                                {
                                    "id": 1552,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/4b3749_Gratify v2.0 Rectangle 161123628.png"
                                },
                                {
                                    "id": 1553,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/2f10a5_Gratify v2.0 Rectangle 161123629.png"
                                },
                                {
                                    "id": 1554,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/71bbb1_Gratify v2.0 Rectangle 161123631.png"
                                },
                                {
                                    "id": 1555,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/84b867_Gratify v2.0 Rectangle 161123632.png"
                                }
                            ],
                            "mandatory": true,
                            "backend_label": "scratch_card_color"
                        },
                        {
                            "label": "Image",
                            "value": "image",
                            "options": [
                                {
                                    "id": 1556,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/25ec3d_responsive-media.png"
                                },
                                {
                                    "id": 1557,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/a40d18_Gratify v2.0 Frame.png"
                                },
                                {
                                    "id": 1558,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47377c_Gratify v2.0 Frame (1).png"
                                },
                                {
                                    "id": 1559,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/13a5cd_Gratify v2.0 Frame (2).png"
                                },
                                {
                                    "id": 1560,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/dc39d9_Gratify v2.0 Frame (3).png"
                                },
                                {
                                    "id": 1561,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f20243_Gratify v2.0 Frame (4).png"
                                },
                                {
                                    "id": 1562,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/5e5966_Gratify v2.0 Frame (5).png"
                                },
                                {
                                    "id": 1563,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/663e74_Gratify v2.0 Frame (6).png"
                                },
                                {
                                    "id": 1564,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/5a0408_Gratify v2.0 Frame (7).png"
                                },
                                {
                                    "id": 1565,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/28df7c_Gratify v2.0 Frame (8).png"
                                },
                                {
                                    "id": 1566,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/2e8f97_Gratify v2.0 Frame (9).png"
                                }
                            ],
                            "mandatory": true,
                            "backend_label": "scratch_card_image"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "image_upload",
                    "backend_label": "scratch_card"
                }
            ],
            "minimum_rewards": {
                "label": "Minimum",
                "value": 1,
                "mandatory": true
            }
        },
        "game_fields": {},
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "created_at": "2024-07-31 07:30:13",
        "last_modified_at": "2024-09-24 11:51:14"
    }
]