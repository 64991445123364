import React, { useCallback, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Typography } from "@mui/material";
import UploadIcon from "../assets/UploadIcon.svg";
import { toast } from "react-toastify";

function FileUpload({ value, onChange, errorMessage }) {
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef(null);

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles?.length !== 0) {
        onChange(acceptedFiles[0]);
        setFileName(acceptedFiles[0].name);
      }
      if (rejectedFiles?.length !== 0) {
        toast.error("Please select a valid CSV file");
      }
    },
    [onChange]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.ms-excel': ['.csv'],
      'application/csv': ['.csv']
    },
    maxFiles: 1,
    multiple: false,
    noClick: true  // Disable the default click behavior
  });

  const handleSelectFileClick = () => {
    fileInputRef.current?.click();
  };

  const handleDeleteFile = (e) => {
    e.stopPropagation();
    onChange(null);
    setFileName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.name.toLowerCase().endsWith('.csv')) {
        onChange(file);
        setFileName(file.name);
      } else {
        toast.error("Please select a CSV file");
      }
    }
  };

  return (
    <>
      <Box sx={{ width: "100%", mt: "0px" }}>
        <input
          {...getInputProps()}
          type="file"
          accept=".csv,text/csv,application/vnd.ms-excel,application/csv"
          onChange={handleFileChange}
          ref={fileInputRef}
          style={{ display: "none" }}
        />

        {value ? (
          <Box
            sx={{
              display: "flex",
              height: "80px",
              backgroundColor: "#F9F9FC",
              borderRadius: "6px",
              gap: "20px",
              alignItems: "center"
            }}
          >
            <Box
              sx={{
                borderRadius: "3px",
                display: "flex",
                alignItems: "center",
                width: "65px",
                height: "65px",
                backgroundColor: "#E0E0E0",
                justifyContent: "center"
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Lato",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "#7E8794"
                }}
              >
                CSV
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "15px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  gap: 1
                }}
              >
                <Typography
                  onClick={handleSelectFileClick}
                  sx={{
                    cursor: "pointer",
                    fontSize: "12px",
                    fontWeight: 600,
                    fontFamily: "Lato",
                    lineHeight: "14.4px",
                    color: "#29CC6A",
                    textDecoration: "underline"
                  }}
                >
                  Change
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "10px",
                    fontWeight: 400,
                    lineHeight: "12px",
                    color: "#7E8794",
                    textAlign: "left"
                  }}
                >
                  {fileName || "CSV File Uploaded"}
                </Typography>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            {...getRootProps()}
            onClick={handleSelectFileClick}
            sx={{
              borderRadius: "8px",
              cursor: "pointer",
              padding: "0px 15px",
              height: "80px",
              backgroundColor: "#F9F9FC",
              display: "flex",
              alignItems: "center",
              gap: "15px"
            }}
          >
            <img
              style={{ width: "12px", height: "12px" }}
              src={UploadIcon}
              alt=""
            />

            <div>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "8px"
                }}
              >
                <Typography
                  sx={{
                    textAlign: "start",
                    fontFamily: "Lato",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "14.4px",
                    color: "#003D86"
                  }}
                >
                  Upload
                </Typography>
                <Typography
                  sx={{
                    textAlign: "start",
                    color: "#7E8794",
                    fontFamily: "Lato",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "12px"
                  }}
                >
                  Upload .csv files. Drag and drop or click to select.
                </Typography>
              </Box>
            </div>
          </Box>
        )}
      </Box>
      {errorMessage && (
        <Box
          sx={{
            mt: 1,
            fontSize: "14px",
            color: "#d74848",
            textAlign: "left",
            ml: "20px"
          }}
        >
          {errorMessage}
        </Box>
      )}
    </>
  );
}

export default FileUpload;