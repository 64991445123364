import React, { useEffect, useRef, useState } from "react";
import { ArrowDropDownOutlined } from "@mui/icons-material";
import FlexBetween from "./FlexBetween";
import {
  AppBar,
  Button,
  Box,
  Typography,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
} from "@mui/material";
import profile from "../assets/profile.jpg";
import Header from "./Header";
import { useLocation, useNavigate } from "react-router-dom";
import BellIcon from "../assets/BellIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { getLimitOptions, userLogout } from "../Redux/AuthReducer/actionType";
import { getTypeOptionsFunction, getWhereSectionSelectOne } from "../Redux/CreateRuleReducer/action";
import { getAllGameTypes, getCategoryFunction, getMerchantFunction, getAllRewardFunction } from "../Redux/OfferReducer/action";
import userProfile from "../assets/userImage.png";
import { handleGetSelectOption } from "../Redux/AuthReducer/action";
import Notification from "../UIComponents/Notification";
import { clearCreateOfferData, saveChangedOfferData } from "../Redux/OfferReducer/actionType";
import { useMsal } from "@azure/msal-react";

const TopNavbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { instance } = useMsal();

  const data = localStorage.getItem("Auth")
  const AuthDetails = data ? JSON.parse(data) : {};
  const dispatch = useDispatch()
  const topRef = useRef(null);
  const handleLogOut = async () => {
    try {
      // Parse isMicrosoftLogin as boolean properly
      const isMicrosoftLoginValue = localStorage.getItem("isMicrosoftLogin");
      const isMicrosoftUser = isMicrosoftLoginValue === "true";
      
      // Only call Microsoft logout if it's a Microsoft login
      if (isMicrosoftUser) {
        await instance.logoutPopup();
        // await instance.logoutRedirect();
      }
      
      // Clear local storage
      localStorage.setItem("Auth", JSON.stringify(""));
      localStorage.setItem("allowedRouters", JSON.stringify({}));
      localStorage.setItem("allowedPermissions", JSON.stringify({}));
      localStorage.setItem("userId", JSON.stringify(""));
      localStorage.setItem("makerCheckerPermission", JSON.stringify({}));
      localStorage.setItem("isMicrosoftLogin", JSON.stringify("false"));
      
      // Dispatch logout action
      dispatch({type: userLogout});
      
      // Navigate to login page
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
      // Still clear local storage and redirect even if Microsoft logout fails
      localStorage.setItem("Auth", "");
      localStorage.setItem("userId", "");
      dispatch({type: userLogout});
      navigate("/login");
    }
  }
  const userName = useSelector((store) => store.AuthReducer.userName);
  
  const token = useSelector((store) => store.AuthReducer.token);
  const scrollFlag = useSelector((store) => store.AuthReducer.scrollFlag);

  useEffect(() => {
    if (token) {
      dispatch(getTypeOptionsFunction(token));
      dispatch(getCategoryFunction(token))
      dispatch(getMerchantFunction(token));
      dispatch(getAllRewardFunction())
      dispatch(getAllGameTypes(1,5))
    }
  }, [token]);

  useEffect(() => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
  }, [scrollFlag]);

  useEffect(() => {
    if (pathname.startsWith('/offers')) {
      navigate('/offers/create-offers/offer-details');
    }
  }, []);

  useEffect(() => {
    if (!pathname.startsWith('/offers')) {
      dispatch({ type: clearCreateOfferData });
      console.log(pathname,"pathname")
    }

    if(pathname !== "/offers/approve-offers/preview"){
      dispatch({type:saveChangedOfferData,payload:{}})
    }
  }, [pathname]);

  return (
    <AppBar
      sx={{
        // position: "fixed", // Changed from "static" to "fixed"
        // top: 0, // Added to ensure it stays at the top
        // left: 0, // Added to ensure proper alignment
        // right: 0, // Added to ensure proper alignment
        // zIndex: 1100, 
        position:"static",
        background: "none",
        boxShadow: "none",
        backgroundColor: "white",
        height: "48px",
        display: "flex",
        justifyContent: "center",
      }}
      ref={topRef}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <FlexBetween>
          <Header title={pathname.substring(1)} />
        </FlexBetween>

        <FlexBetween gap="1.5rem">
          <Notification totalNumber={3}/>

          <FlexBetween>
            <div
              style={{
                border: "1px solid var(--text-30, rgba(0, 0, 0, 0.30))",
                borderRadius: "100px",
                height: "32px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                onClick={handleClick}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textTransform: "none",
                  gap: "0.5rem",
                  height: "100%",
                  padding: "0 8px",
                }}
              >
                <Box
                  component="img"
                  alt="profile"
                  src={userProfile}
                  height="22px"
                  width="22px"
                  borderRadius="50%"
                  sx={{ objectFit: "cover" }}
                />
                <Typography
                  sx={{
                    color: "#0F1828", 
                    fontFamily: "Mulish",
                    fontWeight: 600,
                    fontSize: "12px",
                    lineHeight: "15px",
                  }}
                >
                  {userName}
                </Typography>
                <ArrowDropDownOutlined
                  sx={{ color: "black", fontSize: "25px" }}
                />
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={isOpen}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                <MenuItem sx={{
                  fontFamily: "Mulish",
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "18px",
                  color: "#0F1828",
                  // padding: "10px 16px",
                  "&:hover": {
                    backgroundColor: "#F2F2F2",
                  },
                }} onClick={handleLogOut}>
                  Log Out
                  </MenuItem>
              </Menu>
            </div>
          </FlexBetween>
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
};

export default TopNavbar;